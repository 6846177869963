<ng-template #customLoadingTemplate>
    <div class="custom-class">
        <h3>Loading.......................</h3>
    </div>
</ng-template>

<div class="create-dialog-box">
    <ngx-loading
        [show]="isLoading"
        [config]="{ backdropBorderRadius: '4px' }"
        [template]="customLoadingTemplate"
    ></ngx-loading>
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Customer</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addCustomerFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Customer Name</label>
                    <input type="text" formControlName="name" class="input-control" placeholder="Customer Name *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">E-Mail</label>
                    <input type="text" formControlName="email" class="input-control" placeholder="E-Mail *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['email'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['email'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Phone</label>
                    <input type="text" formControlName="phone" class="input-control" placeholder="Phone Number *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['phone'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['phone'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Address</label>
                    <input type="email" formControlName="address" class="input-control" placeholder="Address *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['address'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['address'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Postcode</label>
                    <input type="text" formControlName="postcode" class="input-control" placeholder="Postcode *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['postcode'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['postcode'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">City</label>
                    <input type="text" formControlName="city" class="input-control" placeholder="City *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['city'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['city'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Country</label>
                    <input type="text" formControlName="country" class="input-control" placeholder="Country *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['country'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['country'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Website</label>
                    <input type="text" formControlName="website" class="input-control" placeholder="Website *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['website'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['website'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">VAT Number</label>
                    <input type="text" formControlName="vat_number" class="input-control" placeholder="VAT Number *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['vat_number'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['vat_number'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Bank Account Nr</label>
                    <input type="text" formControlName="bank_account" class="input-control" placeholder="Bank Account *"
                           [ngClass]="{ 'is-invalid': addCustomerSubmitted && addCustomerFormGroupControls['bank_account'].errors}"
                           [ngStyle]="{'border-color': addCustomerSubmitted && addCustomerFormGroupControls['bank_account'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <mat-card class="mb-25 phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Location Logo</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <ngx-dropzone (change)="onSelect($event)" class="centered-dropzone">
                            <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of logo" [file]="f">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                        </ngx-dropzone>
                    </mat-card-content>
                    <div class="align-items-center" *ngIf="addCustomerSubmitted && addCustomerFormGroupControls['logo'].errors" style="color:red;font-size:small">
                        <div *ngIf="addCustomerFormGroupControls['logo'].errors?.['required']">Please add the customer logo</div>
                    </div>
                </mat-card>
            </div>

        </div>

        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="saveCustomer()">Save</button>
        </div>
    </form>
</div>
