import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Agency, Product_Category, Product_Subcategory} from "../../../../common/model/model";
import {MatSelectChange} from "@angular/material/select";
import {ProductService} from "../../../../services/product.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'add-product-categories-dialog',
    templateUrl: './add-product-categories-dialog.component.html',
    styleUrls: ['./add-product-categories-dialog.component.scss']
})
export class AddProductCategoriesDialogComponent implements OnInit {

    public addProductCategoryFormGroup: FormGroup;
    public addProductSubCategoryFormGroup: FormGroup;

    public addProductCategorySubmitted = false;
    public addProductSubCategorySubmitted = false;

    public productCategories: Product_Category[] = [];
    public productCategory: Product_Category;
    public selectedProductCategory: Product_Category;

    constructor(public dialogRef: MatDialogRef<AddProductCategoriesDialogComponent>,
                public themeService: CustomizerSettingsService,
                public productService: ProductService,
                public notificationService: NotificationService,
                public addProductCategoryFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public agency: Agency) {
    }

    ngOnInit(): void {
        this.enableAddProductCategoryDialogForm();
        this.enableAddProductSubCategoryDialogForm();
        this.getAllProductCategories(this.agency);
    }

    get addProductCategoryFormGroupControls() {
        return this.addProductCategoryFormGroup.controls;
    }

    get addProductSubCategoryFormGroupControls() {
        return this.addProductSubCategoryFormGroup.controls;
    }

    public enableAddProductCategoryDialogForm() {
        this.addProductCategoryFormGroup = this.addProductCategoryFormBuilder.group({
            category_name: ['', Validators.required],
            category_description: ['',Validators.required],
        });
    }

    public enableAddProductSubCategoryDialogForm() {
        this.addProductSubCategoryFormGroup = this.addProductCategoryFormBuilder.group({
            category: ['', Validators.required],
            subcategory_name: ['',Validators.required],
            subcategory_description: ['',Validators.required],
        });
    }

    public onCategorySelected(event: MatSelectChange) {
        this.selectedProductCategory = event.value;
    }

    public close() {
        this.dialogRef.close(true);
    }

    public getAllProductCategories(agency: Agency) {
        this.productService.getAllProductCategories(agency).then(categories => {
            this.productCategories = categories;
        });
    }

    public addProductCategory() {
        this.addProductCategorySubmitted = true;

        if (this.addProductCategoryFormGroup.invalid) {
        } else {

            const productCategory: Product_Category = {
                agency_id: this.agency.id ?? '',
                name: this.addProductCategoryFormGroup.controls['category_name'].value,
                description: this.addProductCategoryFormGroup.controls['category_description'].value
            }

            this.productService.addProductCategory(productCategory).then(retrievedProductCategory => {
                this.notificationService.openSuccessfulNotification('Product category ' + retrievedProductCategory[0].name +  ' added successfully');
                this.dialogRef.close(retrievedProductCategory[0]);
            }).catch(error => {
                this.notificationService.openFailureNotitication(error.message);
            });
        }
    }

    public addProductSubCategory() {
        this.addProductSubCategorySubmitted = true;

        if (this.addProductSubCategoryFormGroup.invalid) {
        } else {
            let productSubCategory: Product_Subcategory = {
                agency_id: this.agency.id ?? '',
                name: this.addProductSubCategoryFormGroup.controls['subcategory_name'].value,
                description: this.addProductSubCategoryFormGroup.controls['subcategory_description'].value,
                product_category_id: this.selectedProductCategory.id ?? ''
            }

            this.productService.addProductSubCategory(productSubCategory).then(retrievedProductSubCategory => {
                this.notificationService.openSuccessfulNotification('Product Sub-Category ' + retrievedProductSubCategory[0].name +  ' added successfully');

                const dialogResult = {
                    newSubCategory: retrievedProductSubCategory[0],
                    selectedCategory: this.selectedProductCategory
                };

                this.dialogRef.close(dialogResult);
            }).catch(error => {
                this.notificationService.openFailureNotitication(error.message);
            });
        }
    }
}
