import {Component, OnInit, ViewChild} from "@angular/core";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";
import {MatDialog} from "@angular/material/dialog";
import {AddCustomerDialogComponent} from "./add-customer/add-customer-dialog.component";
import {Agency, Customer, Performer} from "../../common/model/model";
import {MatTableDataSource} from "@angular/material/table";
import {AgencyService} from "../../services/agency.service";
import {CustomerService} from "../../services/customer.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: 'customers-component',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

    public customers: Customer[] = [];
    public dataSource = new MatTableDataSource<Customer>(this.customers);
    public displayedColumns: string[] = ['name', 'email', 'phone', 'address', 'city', 'website', 'actions'];
    public selectedAgency: Agency;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public themeService: CustomizerSettingsService,
                private matDialog: MatDialog,
                private agencyService: AgencyService,
                private customerService: CustomerService) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.getCustomers(agency);
                this.selectedAgency = agency;
            }
        });
    }

    public getCustomers(agency: Agency) {
        this.customerService.getAllCustomers(agency).then(customers => {
            this.customers = customers;
            this.dataSource = new MatTableDataSource<Customer>(this.customers);
            this.dataSource.data = this.customers;
        });
    }

    public openAddCustomerDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        let agencyId: string = '';

        if(this.selectedAgency) {
            agencyId = this.selectedAgency.id ?? '';
        }

        const customerDialogRef = this.matDialog.open(AddCustomerDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: agencyId
        });

        customerDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                this.customers.push(result);
                this.dataSource.data = [...this.customers];
                this.dataSource.paginator = this.paginator;
            }
        });
    }
}
