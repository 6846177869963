import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";
import {Invitation, InvitationStatus, InvitationType, UserRole} from "../common/model/model";

@Injectable()
export class InvitationService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async createInvitation(email: string, role: UserRole, createdBy: string,
                                  invitationStatus: InvitationStatus, invitationType: InvitationType): Promise<Invitation> {
        const token = crypto.randomUUID();
        const expiresAt = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000); // 7 days from now

        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.INVITATIONS)
            .insert({
                email: email,
                role: role.toString(),
                created_by: createdBy,
                token: token,
                expires_at: expiresAt,
                status: invitationStatus.toString(),
                type: invitationType.toString()
            }).select().single();

        if (error) {
            throw new Error("Failed to create invitation, because: " + error.message);
        }

        return data;
    }

    public async checkUserAvailability(email: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('check_user_exists', {
                email_to_check: email
            })

        if (error) {
            throw new Error("Failed to check user availability, because: " + error.message);
        }

        return data;
    }

    public async checkUserAvailabilityAndRegister(email: string) {
        const { data, error } = await this.supabaseService.supabaseClient.functions.invoke('invite-function', {
            body: {
                action: 'register',
                email: email
            }
        });

        if (error) {
            throw new Error("Failed to check user availability and register, because: " + error.message);
        }

        return data;
    }

    public async verifyInvitation(token: string) {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.INVITATIONS)
            .select('*')
            .eq('token', token)
            .gte('expires_at', new Date().toISOString())
            .single();

        if (error) {
            throw new Error("Failed to verify invitation, because: " + error.message);
        }

        return data;
    }
}
