<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Edit Booking Performer</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="editBookingPerformerFormGroup">
        <div class="row" *ngIf="bookingPerformer">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Performer Name</label>
                    <input type="text" formControlName="performer" class="input-control" readonly
                           [ngClass]="{ 'is-invalid': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['performer'].errors}"
                           [ngStyle]="{'border-color': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['performer'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Start Time</label>
                    <input type="time" formControlName="starttime" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['starttime'].errors}"
                           [ngStyle]="{'border-color': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['starttime'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">End Time</label>
                    <input type="time" formControlName="endtime" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['endtime'].errors}"
                           [ngStyle]="{'border-color': editBookingPerformerSubmitted && editBookingPerformerFormGroupControls['endtime'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="editBookingPerformer()">Save</button>
        </div>
    </form>
</div>
