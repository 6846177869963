<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add Product Categories</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>

    <mat-card class="mb-25 create-project-card enrolled-created-box phobos-card" [class.component-dark-theme]="themeService.isDark()">
        <mat-card-content>
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
                <mat-tab label="Category">
                    <form [formGroup]="addProductCategoryFormGroup">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="d-block fw-semibold">Name</label>
                                <mat-card-content>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': addProductCategoryFormGroupControls['category_name'].errors && addProductCategorySubmitted}">
                                        <i class="ri-sticky-note-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (addProductCategoryFormGroup.get('category_name')?.hasError('required') && addProductCategorySubmitted) ? '#d73a49' : 'inherit'}">
                                            Category Name
                                        </mat-label>
                                        <input matInput formControlName="category_name">
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="d-block fw-semibold">Description</label>
                                <mat-card-content>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': addProductCategoryFormGroupControls['category_description'].errors && addProductCategorySubmitted}">
                                        <i class="ri-sticky-note-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (addProductCategoryFormGroup.get('category_description')?.hasError('required') && addProductCategorySubmitted) ? '#d73a49' : 'inherit'}">
                                            Category Description
                                        </mat-label>
                                        <input matInput formControlName="category_description">
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>
                        <br>
                        <div class="text-end">
                            <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                            <button mat-flat-button class="phobos" (click)="addProductCategory()">Add Category</button>
                        </div>
                    </form>
                </mat-tab>
                <mat-tab label="Sub-Category">
                    <form [formGroup]="addProductSubCategoryFormGroup">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="d-block fw-semibold">Category</label>
                                <mat-card-content>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': addProductSubCategoryFormGroupControls['category'].errors && addProductSubCategorySubmitted}">
                                        <i class="ri-account-circle-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (addProductSubCategoryFormGroup.get('category')?.hasError('required') && addProductSubCategorySubmitted) ? '#d73a49' : 'inherit'}">
                                            Select Category
                                        </mat-label>
                                        <mat-select [(value)]="selectedProductCategory" formControlName="category" (selectionChange)="onCategorySelected($event)">
                                            <mat-option *ngFor="let productCategory of productCategories" [value]="productCategory">{{productCategory.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="d-block fw-semibold">Name</label>
                                <mat-card-content>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': addProductSubCategoryFormGroupControls['subcategory_name'].errors && addProductSubCategorySubmitted}">
                                        <i class="ri-sticky-note-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (addProductSubCategoryFormGroup.get('subcategory_name')?.hasError('required') && addProductSubCategorySubmitted) ? '#d73a49' : 'inherit'}">
                                            Name
                                        </mat-label>
                                        <input matInput formControlName="subcategory_name">
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="d-block fw-semibold">Description</label>
                                <mat-card-content>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': addProductSubCategoryFormGroupControls['subcategory_description'].errors && addProductSubCategorySubmitted}">
                                        <i class="ri-sticky-note-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (addProductSubCategoryFormGroup.get('subcategory_description')?.hasError('required') && addProductSubCategorySubmitted) ? '#d73a49' : 'inherit'}">
                                            Description
                                        </mat-label>
                                        <input matInput formControlName="subcategory_description">
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>

                        <div class="text-end">
                            <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                            <button mat-flat-button class="phobos" (click)="addProductSubCategory()">Add Sub-Category</button>
                        </div>
                    </form>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
    </mat-card>

</div>

