export const TABLE = {
    AGENCIES: 'agencies',
    AGENCIES_EXTERNALS: 'agencies_external',
    AGENCY_USERS: 'agency_users',
    BOOKINGS: 'bookings',
    PERFORMERS: 'performers',
    CUSTOMERS: 'customers',
    CATEGORIES: 'categories',
    LOCATIONS: 'locations',
    PRODUCTS: 'products',
    INVOICES: 'invoices',
    BOOKING_PERFORMERS: 'booking_performers',
    BOOKING_CONTACTS: 'booking_contacts',
    BOOKING_PRODUCTS: 'booking_products',
    BOOKING_GUESTS: 'booking_guests',
    BOOKING_ATTACHMENTS: 'booking_attachments',
    BOOKING_PERFORMERS_STATUSES: 'booking_performer_statuses',
    BOOKING_PERFORMER_FLIGHTS: 'booking_performer_flights',
    BOOKING_PERFORMER_HOTELS: 'booking_performer_hotels',
    PERFORMER_PRODUCTS: 'performer_products',
    PERFORMER_CATEGORIES: 'performer_categories',
    PRODUCT_CATEGORIES: 'product_categories',
    PRODUCT_SUBCATEGORIES: 'product_subcategories',
    INVOICE_STATUSES: 'invoice_statuses',
    SPECIFIC_ROLES: 'specific_roles',
    INVITATIONS: 'invitations'
}
