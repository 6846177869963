<ng-template #customLoadingTemplate>
    <div class="custom-class">
    </div>
</ng-template>

<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <ngx-loading
            [show]="isLoading"
            [config]="{ backdropBorderRadius: '4px' }"
            [template]="customLoadingTemplate"
    ></ngx-loading>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="phobos-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <img src="assets/img/sightgig2.png" alt="logo-icon" style="margin-right: 10px;">
                        <span class="sightgig-logo">SIGHTGIG</span>
                    </div>
                    <p>Already have an account? <a routerLink="/" class="main-color fw-medium">Login now!</a></p>
                </div>
                <form [formGroup]="registerUserFormGroup">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Name <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter name</mat-label>
                                <input matInput formControlName="name">
                            </mat-form-field>
                        </div>
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput formControlName="email">
                                <mat-error *ngIf="registerUserFormGroupControls['email'].hasError('email') ||
                                registerUserFormGroupControls['email'].hasError('pattern')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Confirm Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmpassword">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <button mat-flat-button class="phobos d-block fw-semibold" (click)="registerUser()">Register</button>
                </form>
            </div>
        </div>
    </div>
</div>
