<div
    [ngClass]="{'blank-page': router.url === '/error-500' || router.url === '/authentication/reset-password' || router.url === '/authentication/forgot-password'
    || router.url === '/authentication/login' || router.url === '/authentication/register' || router.url === '/authentication/signin-signup'
    || router.url === '/authentication/logout' || router.url === '/authentication/confirm-mail' || router.url === '/authentication/lock-screen'
    || router.url === '/coming-soon' || router.url =='/' || router.url =='/login' || router.url =='/register' || router.url =='/forgot-password' || router.url =='/confirm-email'}"
    [class.dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
>
    <!-- <app-sidebar *ngIf="!['/error-500', '/', '/authentication/reset-password', '/authentication/forgot-password', '/authentication/login', '/authentication/register', '/authentication/signin-signup', '/authentication/logout', '/authentication/confirm-mail', '/authentication/lock-screen', '/coming-soon'].includes(router.url)"></app-sidebar>
    -->
    <app-sidebar *ngIf="showSidebarHeader()"></app-sidebar>
    <div
        class="main-content d-flex flex-column"
        [ngClass]="{'active': isToggled}"
        [class.right-sidebar]="themeService.isRightSidebar()"
        [class.hide-sidebar]="themeService.isHideSidebar()"
    >
        <!--
        <app-header *ngIf="!['/error-500', '/', '/authentication/reset-password', '/authentication/forgot-password', '/authentication/login', '/authentication/register', '/authentication/signin-signup', '/authentication/logout', '/authentication/confirm-mail', '/authentication/lock-screen', '/coming-soon'].includes(router.url)"></app-header>
        -->
        <app-header *ngIf="showSidebarHeader()"></app-header>
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <app-footer *ngIf="!['/error-500', '/', '/login' , '/authentication/reset-password', '/authentication/forgot-password', '/authentication/login', '/authentication/register', '/authentication/signin-signup', '/authentication/logout', '/authentication/confirm-mail', '/authentication/lock-screen', '/coming-soon'].includes(router.url)"></app-footer>
    </div>
    <app-customizer-settings *ngIf="!['/error-500', '/', '/login', '/authentication/reset-password', '/authentication/forgot-password', '/authentication/login', '/authentication/register', '/authentication/signin-signup', '/authentication/logout', '/authentication/confirm-mail', '/authentication/lock-screen', '/coming-soon'].includes(router.url)"></app-customizer-settings>
</div>
