<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0" *ngIf="performer">Performer Details: {{performer.name}}</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/performers">
                Performers
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Performer Details</li>
    </ol>
</div>

<mat-card class="mb-25 enrolled-created-box phobos-card">
    <mat-card-content>
        <mat-tab-group (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
            <mat-tab label="General Info"></mat-tab>
            <mat-tab label="Products & Packages"></mat-tab>
            <mat-tab label="Attachments"></mat-tab>
            <mat-tab label="Bookings"></mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
<div *ngIf="selectedTabIndex === 0">
    <performerinfo-component [performer]="performer"
                             [performerProducts]="performerProducts"
                             [categories]="categories"
                             [members]="members"
                             [performer_categories]="performer_categories"
                             [selectedCurrency]="selectedCurrency"
                             [allCategories]="allCategories"
    >
    </performerinfo-component>
</div>
<div *ngIf="selectedTabIndex === 1">
    <performerproductsinfo-component [performer]="performer"
                                     [performerProducts]="performerProducts">
    </performerproductsinfo-component>
</div>

<div *ngIf="selectedTabIndex === 2">
    <performerattachments-info-component [performer]="performer">
    </performerattachments-info-component>
</div>

<div *ngIf="selectedTabIndex === 3">
    <performerbookings-info-component [performer]="performer">
    </performerbookings-info-component>
</div>

<div *ngIf="!performer">
    Loading performer details...
</div>

