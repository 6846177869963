<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add Attachment</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addAttachmentFormGroup">
            <mat-card-content>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <label class="d-block mb-12 fw-semibold">Attached Files</label>
                        <div class="custom-file-input">
                            <input formControlName="files" type="file" id="file" multiple (change)="onFileSelected($event)" style="display: none;">
                            <label for="file">{{selectedFiles.length > 0 ? selectedFiles.length + ' file(s) selected' : 'No files chosen'}}</label>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <br>
                <mat-label *ngIf="selectedFiles.length < 1">No files added yet</mat-label>
                <div class="recent-files-table table-responsive">
                    <table class="table" *ngIf="selectedFiles.length >= 1">
                        <thead >
                        <tr>
                            <th class="fw-semibold main-color">File Name</th>
                            <th class="fw-semibold main-color">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let file of selectedFiles; index as i">
                            <td>
                                <div class="name-info d-flex align-items-center">
                                    <img src="assets/img/icon/folder.png" alt="folder">
                                    <h5 class="fw-semibold mb-0">{{file.name}}</h5>
                                </div>
                            </td>
                            <td>
                                <button mat-flat-button class="gray me-15" (click)="removeFile(i)">Remove</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <br>
                <div class="text-end">
                    <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                    <button mat-flat-button class="phobos" (click)="addAttachment()">
                        Add Attachment(s)
                    </button>
                </div>
            </mat-card-content>
        </form>
    </mat-card>
</div>
