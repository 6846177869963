import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Performer} from "../../../../common/model/model";

@Component({
    selector: 'edit-booking-performer-dialog',
    templateUrl: 'edit-booking-performer-dialog.component.html',
    styleUrls: ['edit-booking-performer-dialog.component.scss']
})
export class EditBookingPerformerDialogComponent implements OnInit {

    public editBookingPerformerFormGroup: FormGroup;
    public editBookingPerformerSubmitted = false;
    public bookingPerformer: Booking_Performer;

    constructor(public dialogRef: MatDialogRef<EditBookingPerformerDialogComponent>,
                private editBookingPerformerFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public retrievedBookingPerformer: Booking_Performer) {
        this.bookingPerformer = retrievedBookingPerformer;
    }

    ngOnInit() {
        this.enableEditBookingPerformerDialogForm();
    }

    get editBookingPerformerFormGroupControls() {
        return this.editBookingPerformerFormGroup.controls;
    }

    public enableEditBookingPerformerDialogForm() {
        this.editBookingPerformerFormGroup = this.editBookingPerformerFormBuilder.group({
            performer: [this.bookingPerformer.name, Validators.required],
            starttime: [this.bookingPerformer.start_time, Validators.required],
            endtime: [this.bookingPerformer.end_time, Validators.required],
        });
    }

    public editBookingPerformer() {
        this.editBookingPerformerSubmitted = true;

        if (this.editBookingPerformerFormGroup.invalid) {
        } else {
            let starttime = this.editBookingPerformerFormGroup.controls['starttime'].value;
            let endtime = this.editBookingPerformerFormGroup.controls['endtime'].value;

            this.bookingPerformer.start_time = starttime;
            this.bookingPerformer.end_time = endtime;

            this.dialogRef.close(this.bookingPerformer);
        }
    }

    public close(){
        this.dialogRef.close(true);
    }
}
