<div
    class="h-100vh pt-50 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="phobos-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <img src="assets/img/sightgig2.png" alt="logo-icon" style="margin-right: 10px;">

                        <span class="sightgig-logo">SIGHTGIG</span>

                    </div>
                    <p>Don’t have an account? <a routerLink="/register" class="main-color fw-medium">Register now!</a></p>
                </div>
                <form [formGroup]="loginFormGroup">
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input matInput formControlName="email" [ngClass]="{ 'is-invalid': loginSubmitted && loginFormGroupControls['email'].errors}">
                            </mat-form-field>
                        </div>
                        <div class="phobos-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input matInput [type]="hide ? 'password' : 'text'"
                                       formControlName="password" [ngClass]="{ 'is-invalid': loginSubmitted && loginFormGroupControls['password'].errors}">
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex info align-items-center justify-content-space-between">
                        <mat-checkbox class="gray-color">Remember me</mat-checkbox>
                        <a routerLink="/forgot-password" class="d-inline-block main-color fw-medium">Forgot your password?</a>
                    </div>
                    <button mat-flat-button class="phobos d-block fw-semibold" (click)="login()">Log In</button>
                </form>
            </div>
        </div>
    </div>
</div>
