<mat-card
        class="mb-25 phobos-card"
        [class.component-dark-theme]="themeService.isDark()"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>

    <mat-card-header class="align-items-center border-bottom">
        <h5 class="mb-0">Invoices</h5>
        <a mat-stroked-button class="default-btn phobos" (click)="openAddInvoiceDialog('300ms', '100ms')">
            <i class="ri-add-fill"></i>Generate Invoice
        </a>
    </mat-card-header>

    <mat-card-content>
        <mat-label *ngIf="bookingDetails.invoices.length < 1">No invoices generated yet</mat-label>
        <div class="recent-files-table table-responsive">
            <table class="table" *ngIf="bookingDetails.invoices.length >= 1">
                <thead >
                <tr>
                    <th class="fw-semibold main-color">Invoice Nr</th>
                    <th class="fw-semibold main-color">Status</th>
                    <th class="fw-semibold main-color">Description</th>
                    <th class="fw-semibold main-color">Amount</th>
                    <th class="fw-semibold main-color">Date</th>
                    <th class="fw-semibold main-color">Due Date</th>
                    <th class="fw-semibold main-color">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let invoice of bookingDetails.invoices">
                    <td>
                        <div class="name-info d-flex align-items-center">
                            <img src="assets/img/icon/invoice.png" alt="folder">
                            <h5 class="fw-semibold mb-0">{{invoice.invoice_nr}}</h5>
                        </div>
                    </td>
                    <td>{{invoice.invoice_status}}</td>
                    <td>{{invoice.invoice_description}}</td>
                    <td>{{bookingDetails.booking.currency_symbol}} {{invoice.invoice_amount}} {{bookingDetails.booking.currency_code}}</td>
                    <td>{{invoice.invoice_date}}</td>
                    <td>{{invoice.invoice_due_date}}</td>
                    <td>
                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                             (click)="trigger.openMenu()"
                             #trigger="matMenuTrigger"
                             [matMenuTriggerFor]="invoiceActionMenu">
                            <i class="ri-more-fill"></i>
                        </div>
                        <mat-menu #invoiceActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editInvoice(invoice)">
                                <i class="ri-edit-2-line"></i> Edit
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="deleteInvoice(invoice)">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </tr>

                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>

<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header class="align-items-center border-bottom">
        <h5 class="mb-0">Financial Details</h5>
    </mat-card-header>

    <mat-card-content>
        <div class="d-flex justify-content-space-between">
            <div>
                <h5 class="mb-15">Billed To :</h5>
                <span class="fw-semibold gray-color d-block mb-8">{{bookingDetails.customer.name}}</span>
                <span class="gray-color d-block mb-8">{{bookingDetails.customer.address}}</span>
                <span class="gray-color d-block mb-8">{{bookingDetails.customer.postcode}} {{bookingDetails.customer.city}} </span>
                <span class="gray-color d-block mb-8">{{bookingDetails.customer.country}}</span>
                <h5 class="mb-15 mt-25">Payment :</h5>
                <span class="gray-color d-block mb-8">
                    Total Due: {{ bookingDetails.booking.currency_symbol}} {{ financialsTotalWithVAT }} {{ bookingDetails.booking.currency_code}}
                </span>
                <span class="gray-color d-block mb-8">Country: {{ selectedAgency.country }}</span>
                <span class="gray-color d-block mb-8">IBAN: {{ selectedAgency.bank_number }}</span>
                <span class="gray-color d-block">BIC Code: {{ selectedAgency.bic_code }}</span>
            </div>
            <div class="text-end">
                <br>
                <br>
                <br>
                <br>
                <br>
                <h5 class="mb-15 mt-25">Booking Details :</h5>
                <span class="gray-color d-block">Name: {{ bookingDetails.booking.event_name }}</span>
                <span class="gray-color d-block">Date: {{ bookingDetails.booking.event_date }}</span>
                <span class="gray-color d-block">Location: {{ bookingDetails.location.name }}</span>
                <span class="gray-color d-block">Statuys: {{ bookingDetails.booking.status }}</span>
            </div>
        </div>
        <h4 class="mb-20 mt-25">Finance Summary</h4>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="fw-semibold main-color fw-semibold text-start">Performer</th>
                    <th class="fw-semibold main-color fw-semibold text-start">Start Time</th>
                    <th class="fw-semibold main-color fw-semibold text-start">End Time</th>
                    <th class="fw-semibold main-color fw-semibold text-start">Status</th>
                    <th class="fw-semibold main-color fw-semibold text-end">Cost</th>
                    <th class="fw-semibold main-color fw-semibold text-end">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let performer of combinedPerformers">
                    <td class="fw-semibold text-start">
                        {{performer.name}}
                    </td>
                    <td class="fw-semibold text-start">{{performer.start_time}}</td>
                    <td class="fw-semibold text-start">{{performer.end_time}}</td>
                    <td class="fw-semibold text-start">{{performer.status}}</td>
                    <td class="fw-semibold text-end">{{performer.currency_symbol}} {{performer.cost}} {{performer.currency_symbol}}</td>
                    <td class="fw-semibold text-end">{{performer.currency_symbol}} {{performer.price}} {{performer.currency_symbol}}</td>
                </tr>
                <tr>
                    <td colspan="6">
                        <mat-divider></mat-divider>
                    </td>
                </tr>
                </tbody>
                <thead>
                <tr>
                    <th class="fw-semibold main-color fw-semibold text-start">Product</th>
                    <th class="fw-semibold main-color fw-semibold text-start">Description</th>
                    <th class="fw-semibold main-color fw-semibold text-start">Category</th>
                    <th class="fw-semibold main-color fw-semibold text-start">Sub-Category</th>
                    <th class="fw-semibold main-color fw-semibold text-end">Cost</th>
                    <th class="fw-semibold main-color fw-semibold text-end">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colspan="6">
                        <mat-label *ngIf="bookingDetails.products.length < 1">No products have been added to this booking</mat-label>
                    </td>
                </tr>
                <tr *ngFor="let bumaFee of bumaFees">
                    <td class="fw-semibold text-start">{{bumaFee.performerName}}</td>
                    <td class="fw-semibold text-start">-----</td>
                    <td class="fw-semibold text-start">-----</td>
                    <td class="fw-semibold text-start">-----</td>
                    <td class="fw-semibold text-end">-----</td>
                    <td class="fw-semibold text-end">{{bumaFee.currencySymbol}} {{bumaFee.bumaFee}} {{bumaFee.currencyCode}}</td>
                </tr>


                <tr *ngFor="let product of bookingDetails.products">
                    <td class="fw-semibold text-start">
                        {{product.name }}
                    </td>
                    <td class="fw-semibold text-start">{{product.description}}</td>
                    <td class="fw-semibold text-start">{{product.category}}</td>
                    <td class="fw-semibold text-start">{{product.subcategory}}</td>
                    <td class="fw-semibold text-end">{{product.currency_symbol}} {{product.cost}} {{product.currency_code}}</td>
                    <td class="fw-semibold text-end">{{product.currency_symbol}} {{product.price}} {{product.currency_code}}</td>
                </tr>
                <tr>
                    <td colspan="6">
                        <mat-divider></mat-divider>
                    </td>
                </tr>

                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="fw-semibold text-end">Subtotal:</td>
                    <td class="fw-semibold text-end">
                        {{ bookingDetails.booking.currency_symbol}} {{ financialsTotal }} {{ bookingDetails.booking.currency_code}}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="fw-semibold text-end">VAT Rate ({{financialsVATRate}}%):</td>
                    <td class="fw-semibold text-end">
                        {{ bookingDetails.booking.currency_symbol}} {{ actualVATAmount }} {{ bookingDetails.booking.currency_code}}
                    </td>
                </tr>
                <tr class="total">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="fw-semibold text-end">Total:</td>
                    <td class="fw-semibold text-end">
                        {{ bookingDetails.booking.currency_symbol}} {{ financialsTotalWithVAT }} {{ bookingDetails.booking.currency_code}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
