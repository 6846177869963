<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Member</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addPerformerMemberFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Name</label>
                    <input type="text" formControlName="name" class="input-control" placeholder="Member Name *"
                           [ngClass]="{ 'is-invalid': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Role</label>
                    <input type="text" formControlName="role" class="input-control" placeholder="Role *"
                           [ngClass]="{ 'is-invalid': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['role'].errors}"
                           [ngStyle]="{'border-color': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['role'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">E-Mail</label>
                    <input type="text" formControlName="email" class="input-control" placeholder="E-Mail Address *"
                           [ngClass]="{ 'is-invalid': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['email'].errors}"
                           [ngStyle]="{'border-color': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['email'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Phone</label>
                    <input type="text" formControlName="phone" class="input-control" placeholder="Phone *"
                           [ngClass]="{ 'is-invalid': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['phone'].errors}"
                           [ngStyle]="{'border-color': addPerformerMemberSubmitted && addPerformerMemberFormGroupControls['phone'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="addPerformerMember()">Save</button>
        </div>
    </form>
</div>
