import {Injectable} from "@angular/core";
import {Booking_Product, BookingDetails} from "../common/model/model";
import {BehaviorSubject, Subject} from "rxjs";
import {BookingService} from "./booking.service";

@Injectable()
export class BookingCommunicationService {
    private refreshProductsSource = new Subject<string>();
    refreshProducts$ = this.refreshProductsSource.asObservable();

    private bookingProductsSource = new BehaviorSubject<Booking_Product[]>([]);
    bookingProducts$ = this.bookingProductsSource.asObservable();

    constructor(private bookingService: BookingService) {
        this.refreshProducts$.subscribe(bookingId => {
            this.getBookingProducts(bookingId);
        });
    }

    refreshProducts(bookingId: string) {
        this.refreshProductsSource.next(bookingId);
    }

    private getBookingProducts(bookingId: string) {
        this.bookingService.getBookingProducts(bookingId).then((bookingProducts: Booking_Product[]) => {
            this.bookingProductsSource.next(bookingProducts);
        });
    }
}
