import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {
    Agency,
    Booking_Product,
    BookingDetails, Currency,
    Performer_Product_With_Performer,
    Product_Category, Product_Subcategory
} from "../../../../common/model/model";
import {PerformerService} from "../../../../services/performer.service";
import {ProductService} from "../../../../services/product.service";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
    selector: 'add-product-dialog',
    templateUrl: './add-booking-product-dialog.component.html',
    styleUrls: ['./add-booking-product-dialog.component.scss']
})
export class AddBookingProductDialogComponent implements OnInit, AfterViewInit {

    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

    public selectedIndex = 0;
    public isPerformerProductTabDisabled = false;

    public addBookingPerformerProductFormGroup: FormGroup;
    public addBookingCustomProductFormGroup: FormGroup;

    public addBookingProductSubmitted = false;
    public addBookingCustomProductSubmitted = false;

    public performerProductsWithPerformer: Performer_Product_With_Performer[] = [];
    private selectedProducts: Set<Performer_Product_With_Performer> = new Set();

    public productCategories: Product_Category[] = [];
    public productSubCategories: Product_Subcategory[] = [];

    public selectedProductCategory: Product_Category;
    public selectedProductSubCategory: Product_Subcategory;

    public selectedCurrency: Currency;

    constructor(public dialogRef: MatDialogRef<AddBookingProductDialogComponent>,
                private addBookingProductFormBuilder: FormBuilder,
                public themeService: CustomizerSettingsService,
                private performerService: PerformerService,
                private productService: ProductService,
                private changeDetectorRef: ChangeDetectorRef,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                    agency: Agency,
                    currency: Currency,
                    bookingProduct?: Booking_Product
                }) {
        this.selectedCurrency = this.data.currency;
    }

    ngOnInit(): void {
        this.enableAddBookingCustomProductDialogForm();

        this.getPerformerProducts();
        this.getAllProductCategories();

        if(this.data.bookingProduct) {
            this.populateForm(this.data.bookingProduct);
            this.selectedIndex = 1;
            this.isPerformerProductTabDisabled = true;
        }
    }

    ngAfterViewInit() {
        if (this.data.bookingProduct) {
            setTimeout(() => {
                this.tabGroup.selectedIndex = this.selectedIndex;
            });
        }
    }

    get addBookingCustomProductFormGroupControls() {
        return this.addBookingCustomProductFormGroup.controls;
    }

    public enableAddBookingCustomProductDialogForm() {
        this.addBookingCustomProductFormGroup = this.addBookingProductFormBuilder.group({
            product_name: ['', Validators.required],
            product_description: ['', Validators.required],
            product_category: ['', Validators.required],
            product_subcategory: ['', Validators.required],
            product_cost: ['',Validators.required],
            product_price: ['', Validators.required],
        });
    }

    public populateForm(bookingProduct: Booking_Product) {
        this.addBookingCustomProductFormGroup.patchValue({
            product_name: bookingProduct.name,
            product_description: bookingProduct.description,
            product_cost: bookingProduct.cost,
            product_price: bookingProduct.price
        });

        // Pre-select category
        const category = this.productCategories.find(cat => cat.name === bookingProduct.category);
        if (category) {
            this.selectedProductCategory = category;
            this.addBookingCustomProductFormGroup.get('product_category')?.setValue(category);
            this.onCategoryChange();
        }
    }

    public getPerformerProducts() {
        this.data.bookingDetails.performers.forEach(bookingPerformer => {
            if (!bookingPerformer || !bookingPerformer.performer_id) {
                throw new Error('Performer or its ID is undefined, thus unable to get performer products');
            }

            this.performerService.getPerformerProducts(bookingPerformer.performer_id).then(performerProducts => {
                performerProducts.forEach(performerProduct => {
                    let performerProductWithPerformer: Performer_Product_With_Performer = {
                        performer_product: performerProduct,
                        performer: bookingPerformer
                    }

                    this.performerProductsWithPerformer.push(performerProductWithPerformer);
                });
            });
        });
    }

    public onSelectionChange(product: Performer_Product_With_Performer, isChecked: boolean) {
        if (isChecked) {
            this.selectedProducts.add(product);
        } else {
            this.selectedProducts.delete(product);
        }
    }

    public isSelected(product: Performer_Product_With_Performer): boolean {
        return this.selectedProducts.has(product);
    }

    public close() {
        this.dialogRef.close(true);
    }

    public addPerformerProducts() {
        const selectedProductsArray = Array.from(this.selectedProducts);
        this.dialogRef.close(selectedProductsArray);
    }

    public addCustomProduct() {
        this.addBookingCustomProductSubmitted = true;
        if(this.addBookingCustomProductFormGroup.invalid) {

        } else {
            let bookingProduct: Booking_Product = {
                id: this.data.bookingProduct?.id,
                booking_id: this.data.bookingDetails.booking.id,
                name: this.addBookingCustomProductFormGroup.controls['product_name'].value,
                description: this.addBookingCustomProductFormGroup.controls['product_description'].value,
                price: this.addBookingCustomProductFormGroup.controls['product_price'].value,
                cost: this.addBookingCustomProductFormGroup.controls['product_cost'].value,
                category: this.addBookingCustomProductFormGroup.controls['product_category'].value.name,
                subcategory: this.addBookingCustomProductFormGroup.controls['product_subcategory'].value.name,
                currency_code: this.data.currency.currency_code,
                currency_symbol: this.data.currency.symbol
            };
            this.dialogRef.close(bookingProduct);
        }
    }

    public onCategoryChange() {
        const category = this.addBookingCustomProductFormGroup.get('product_category')?.value;
        this.addBookingCustomProductFormGroup.get('product_subcategory')?.setValue(null);
        if (category) {
            this.loadSubcategoriesForCategory(category);
            this.addBookingCustomProductFormGroup.get('product_subcategory')?.enable();
        } else {
            this.productSubCategories = [];
            this.addBookingCustomProductFormGroup.get('product_subcategory')?.disable();
        }

        this.addBookingCustomProductFormGroup.controls['product_subcategory'].setValue('');
    }

    private loadSubcategoriesForCategory(category: Product_Category, newlyAdded: boolean = false) {
        this.getAllProductSubCategories(this.data.agency, category, newlyAdded).then(() => {
            if (this.data.bookingProduct) {
                // Pre-select subcategory after subcategories are loaded
                const subcategory = this.productSubCategories.find(subcat => subcat.name === this.data.bookingProduct?.subcategory);
                if (subcategory) {
                    this.selectedProductSubCategory = subcategory;
                    this.addBookingCustomProductFormGroup.get('product_subcategory')?.setValue(subcategory);
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    public getAllProductCategories() {
        this.productService.getAllProductCategories(this.data.agency).then(categories => {
            this.productCategories = categories;
            if (this.data.bookingProduct) {
                this.populateForm(this.data.bookingProduct);
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    public getAllProductSubCategories(agency: Agency, category: Product_Category, newlyAdded: boolean) {
        return this.productService.getAllProductSubCategories(agency, category).then(subcategories => {
            const selectedSubCategoryId = this.selectedProductSubCategory?.id;

            // Merge new subcategories with existing ones, preserving the selected subcategory
            if(newlyAdded) {
                this.productSubCategories = [
                    ...subcategories,
                    ...(this.selectedProductSubCategory && !subcategories.some(sub => sub.id === selectedSubCategoryId)
                        ? [this.selectedProductSubCategory]
                        : [])
                ];
            } else {
                this.productSubCategories = subcategories;
            }

            // Ensure the selected subcategory is set after updating the list
            if (selectedSubCategoryId) {
                const subcategoryToSelect = this.productSubCategories.find(sub => sub.id === selectedSubCategoryId);
                if (subcategoryToSelect) {
                    if(newlyAdded) {
                        this.addBookingCustomProductFormGroup.get('product_subcategory')?.setValue(subcategoryToSelect, { emitEvent: false });
                    }
                }
            }
            this.changeDetectorRef.detectChanges();
        });
    }

}
