import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfirmMailComponent} from './components/authentication/confirm-mail/confirm-mail.component';
import {ForgotPasswordComponent} from './components/authentication/forgot-password/forgot-password.component';
import {LoginComponent} from './components/authentication/login/login.component';
import {RegisterComponent} from './components/authentication/register/register.component';
import {InternalErrorComponent} from './components/common/internal-error/internal-error.component';
import {NotFoundComponent} from './components/common/not-found/not-found.component';
import {AnalyticsComponent} from './components/dashboard/analytics/analytics.component';
import {EcommerceComponent} from './components/dashboard/ecommerce/ecommerce.component';
import {LmsCoursesComponent} from './components/dashboard/lms-courses/lms-courses.component';
import {ProjectManagementComponent} from './components/dashboard/project-management/project-management.component';
import {AccountComponent} from './components/pages/account/account.component';
import {ConnectionsComponent} from './components/pages/connections/connections.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {SecurityComponent} from './components/pages/security/security.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {CryptoComponent} from './components/dashboard/crypto/crypto.component';
import {HelpDeskComponent} from './components/dashboard/help-desk/help-desk.component';
import {SaasAppComponent} from './components/dashboard/saas-app/saas-app.component';
import {AuthGuard} from "./components/guards/auth.guard";
import {BookingsComponent} from "./components/pages/bookings/bookings-overview/bookings.component";
import {AddBookingComponent} from "./components/pages/bookings/add-booking/add-booking.component";
import {LocationsComponent} from "./components/pages/locations/locations.component";
import {PerformersComponent} from "./components/pages/performers/performers.component";
import {AddPerformerComponent} from "./components/pages/performers/add-performer/add-performer.component";
import {BookingDetailsComponent} from "./components/pages/bookings/booking-details/booking-details.component";
import {PerformerDetailsComponent} from "./components/pages/performers/performer-details/performer-details.component";
import {CustomersComponent} from "./components/pages/customers/customers.component";
import {AgencyGuard} from "./components/guards/agency.guard";
import {InvoicesComponent} from "./components/pages/invoices/invoices-overview/invoices.component";
import {RoleGuard} from "./components/guards/role.guard";

const routes: Routes = [
    //{path: '', component: EcommerceComponent},
    {path: '', component: LoginComponent, canActivate: [AuthGuard]},
    {path: 'register', component: RegisterComponent, canActivate: [AuthGuard]},
    {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard]},
    {path: 'confirm-email', component: ConfirmMailComponent, canActivate: [AuthGuard]},
    {path: 'bookings', component: BookingsComponent, canActivate: [AuthGuard, RoleGuard], data: {expectedRole: 'Agency' || null}},
    {path: 'performerbookings', component: BookingsComponent, canActivate: [AuthGuard, RoleGuard], data: {expectedRole: 'Performer'}},
    {path: 'performers', component: PerformersComponent, canActivate: [AuthGuard]},
    {path: 'locations', component: LocationsComponent, canActivate: [AuthGuard]},
    {path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard]},
    {path: 'addbooking', component: AddBookingComponent, canActivate: [AuthGuard, AgencyGuard]},
    {path: 'addperformer', component: AddPerformerComponent, canActivate: [AuthGuard, AgencyGuard]},
    {path: 'booking-details/:bookingId', component: BookingDetailsComponent, canActivate: [AuthGuard]},
    {path: 'performer-details/:performerId', component: PerformerDetailsComponent, canActivate: [AuthGuard]},
    {path: 'customers', component: CustomersComponent, canActivate: [AuthGuard]},
    {path: 'ecommerce', component: EcommerceComponent, canActivate: [AuthGuard]},
    {path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard]},
    {path: 'project-management', component: ProjectManagementComponent},
    {path: 'lms-courses', component: LmsCoursesComponent},
    {path: 'crypto', component: CryptoComponent},
    {path: 'help-desk', component: HelpDeskComponent},
    {path: 'saas-app', component: SaasAppComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'account', component: AccountComponent},
    {path: 'security', component: SecurityComponent},
    {path: 'connections', component: ConnectionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'error-500', component: InternalErrorComponent},
    // {path: 'authentication/forgot-password', component: ForgotPasswordComponent},
    // {path: 'authentication/reset-password', component: ResetPasswordComponent},
    // {path: 'authentication/login', component: LoginComponent},
    // {path: 'authentication/register', component: RegisterComponent},
    // {path: 'authentication/signin-signup', component: SigninSignupComponent},
    // {path: 'authentication/logout', component: LogoutComponent},
    // {path: 'authentication/confirm-mail', component: ConfirmMailComponent},
    // {path: 'authentication/lock-screen', component: LockScreenComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
