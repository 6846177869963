<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Guests</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddGuestDialog('300ms','100ms')">
                <i class="ri-add-fill"></i>Add Guest
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.booking_guests.length < 1">No guests added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.contacts.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Name</th>
                        <th class="fw-semibold main-color">E-Mail</th>
                        <th class="fw-semibold main-color">Description</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let guest of bookingDetails.booking_guests">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/guest2.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{guest.name}}</h5>
                            </div>
                        </td>
                        <td class="gray-color">{{guest.email}}</td>
                        <td class="gray-color">{{guest.description}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="guestActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #guestActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="openEditGuestDialog('300ms','100ms', guest)">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeGuest(guest)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

</div>
