<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0" *ngIf="bookingDetails">Booking Details: {{bookingDetails.booking.event_name}}</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/bookings">
                Bookings
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Booking Details</li>
    </ol>
</div>

<mat-card class="mb-25 enrolled-created-box phobos-card">
    <mat-card-content>
        <mat-tab-group (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
            <mat-tab label="General Info"></mat-tab>
            <mat-tab label="Contacts"></mat-tab>
            <mat-tab label="Products"></mat-tab>
            <mat-tab label="Financials"></mat-tab>
            <mat-tab label="Attachments"></mat-tab>
            <mat-tab label="Travel"></mat-tab>
            <mat-tab label="Guests"></mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<div *ngIf="selectedTabIndex === 0">
    <bookinginfo-component *ngIf="locations && customers && bookingDetails"
                           [bookingDetails]="bookingDetails"
                           [locations]="locations"
                           [customers]="customers"
    ></bookinginfo-component>
</div>
<div *ngIf="selectedTabIndex === 1">
    <contactsinfo-component
        [bookingDetails]="bookingDetails"
    ></contactsinfo-component>
</div>
<div *ngIf="selectedTabIndex === 2">
    <products-info
        [bookingDetails]="bookingDetails"
        [agency]="selectedAgency"
    ></products-info>
</div>
<div *ngIf="selectedTabIndex === 3">
    <financialinfo-component
            [bookingDetails]="bookingDetails"
    ></financialinfo-component>
</div>
<div *ngIf="selectedTabIndex === 4">
    <attachments-info
        [bookingDetails]="bookingDetails"
    ></attachments-info>
</div>
<div *ngIf="selectedTabIndex === 5">
    <travel-info-component
        [bookingDetails]="bookingDetails"
    ></travel-info-component>
</div>
<div *ngIf="selectedTabIndex === 6">
    <guest-info
            [bookingDetails]="bookingDetails"
    ></guest-info>
</div>

<div *ngIf="!bookingDetails">
    Loading booking details...
</div>
