<mat-card class="mb-25 phobos-card">
    <mat-card-header>
        <h5 class="mb-0">Products & Packages Summary</h5>
    </mat-card-header>
    <mat-divider class="mb-5"></mat-divider>
    <mat-label *ngIf="groupedProducts.length < 1">
        No products added yet
    </mat-label>
    <mat-card-content>
        <form>
            <ul class="list ps-0 mb-0 mt-0 list-product-summary">
                <ng-container *ngFor="let group of groupedProducts">
                    <li class="fw-bold">{{ group.category }}</li>
                    <ng-container *ngFor="let subcategory of group.subcategories">
                        <li class="fw-bold">{{ subcategory.subcategory }}</li>
                        <ng-container *ngFor="let product of subcategory.products">
                            <li class="d-flex align-items-center justify-content-space-between">
                                {{ product.name }} :
                                <span class="fw-semibold">{{selectedCurrency.symbol}}{{product.price}}</span>
                            </li>
                        </ng-container>
                    </ng-container>
                    <br>
                    <mat-divider class="mb-5"></mat-divider>
                    <br>
                    <li class="d-flex fw-bold align-items-center justify-content-space-between">
                        Total Cost {{selectedCurrency.currency_code}}:
                        <span class="fw-semibold">{{selectedCurrency.symbol}}{{bookingTotalCost}}</span>
                    </li>
                    <li class="d-flex fw-bold align-items-center justify-content-space-between">
                        Total Price {{selectedCurrency.currency_code}}:
                        <span class="fw-semibold">{{selectedCurrency.symbol}}{{bookingTotalPrice}}</span>
                    </li>
                </ng-container>
            </ul>
        </form>
        <br>
        <br>
        <button mat-stroked-button (click)="onModifyProducts()">Modify Products</button>
    </mat-card-content>
</mat-card>
