import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Currency} from "../../../../common/model/model";

@Component({
    selector: 'product-summary-component',
    templateUrl: './product-summary.component.html',
    styleUrls: ['./product-summary.component.scss']
})
export class ProductSummaryComponent {

    @Input() groupedProducts: any[];
    @Input() selectedCurrency: Currency;
    @Input() bookingTotalCost: number;
    @Input() bookingTotalPrice: number;

    @Output() modifyProducts = new EventEmitter<void>();

    constructor() {
    }

    onModifyProducts() {
        this.modifyProducts.emit();
    }
}
