<mat-card class="mb-25 personal-info-card phobos-card" [class.component-dark-theme]="themeService.isDark()">
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Personal Information</h5>
    </mat-card-header>
    <mat-card-content>
        <span class="d-block fw-semibold mb-5">About Me:</span>
        <p>Hi I'm Andrew Burns, has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a industry.</p>
        <ul class="list ps-0 mb-0">
            <li>
                <span class="fw-semibold d-inline-block">Full Name:</span>
                <span class="gray-color">Andrew Burns</span>
            </li>
            <li>
                <span class="fw-semibold d-inline-block">Mobile:</span>
                <span class="gray-color">(123) 123 1234</span>
            </li>
            <li>
                <span class="fw-semibold d-inline-block">Email:</span>
                <span class="gray-color">andrewburns&#64;phobos.com</span>
            </li>
            <li>
                <span class="fw-semibold d-inline-block">Location:</span>
                <span class="gray-color">New York, USA</span>
            </li>
            <li>
                <span class="fw-semibold d-inline-block">Designation:</span>
                <span class="gray-color">Backend Developer</span>
            </li>
            <li>
                <span class="fw-semibold d-inline-block">Experience:</span>
                <span class="gray-color">5 Years</span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>
