import {Component, OnInit} from '@angular/core';
import {CustomizerSettingsService} from '../../customizer-settings/customizer-settings.service';
import {SupabaseAuthService} from "../../services/supabase.auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    hide = true;
    public email: string = '';
    public password: string = '';
    public errorMessage: string = ''

    public loginSubmitted = false;

    public loginFormGroup: FormGroup;

    constructor(public themeService: CustomizerSettingsService,
                private loginFormBuilder: FormBuilder,
                private supabaseAuthService: SupabaseAuthService,
                private router: Router,
                private notificationService: NotificationService) {

    }

    ngOnInit(): void {
        this.enableLoginForm();
        // this.supabaseAuthService.getCurrentUser().subscribe(user => {
        // });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    get loginFormGroupControls() {
        return this.loginFormGroup.controls;
    }

    public enableLoginForm() {
        this.loginFormGroup = this.loginFormBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    public login(){
        this.loginSubmitted = true;
        if(this.loginFormGroup.invalid) {
        } {
            let email = this.loginFormGroup.controls['email'].value;
            let password = this.loginFormGroup.controls['password'].value;

            let userPromise = this.supabaseAuthService.login(email, password);

            userPromise.then(user => {
                this.router.navigate((['/analytics']))
            }).catch(error => {
                this.errorMessage = error.message;
                this.notificationService.openFailureNotitication(error.message);
            })
        }
    }
}
