<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">{{data.bookingProduct ? 'Update Booking Product' : 'Add Booking Product'}}</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>

    <mat-card class="mb-25 create-project-card enrolled-created-box phobos-card" [class.component-dark-theme]="themeService.isDark()">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
            <mat-tab label="Performer Product" [disabled]="isPerformerProductTabDisabled">
                <form>
                    <div class="members-list-table table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="fw-semibold">Product</th>
                                <th class="fw-semibold">Performer</th>
                                <th class="fw-semibold">Product Price</th>
                                <th class="fw-semibold">Product Cost</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let performerProductWithPerformer of performerProductsWithPerformer">
                                <td>
                                    <div class="user-info d-flex align-items-center">
                                        <mat-checkbox class="fm-checkbox"
                                                      (change)="onSelectionChange(performerProductWithPerformer, $event.checked)"
                                                      [checked]="isSelected(performerProductWithPerformer)">

                                        </mat-checkbox>
                                        <img src="{{performerProductWithPerformer.performer_product.picture}}" class="rounded-circle" alt="user">
                                        <div class="performer_product_title">
                                            <h6 class="fw-semibold mb-5">{{ performerProductWithPerformer.performer_product.name }}</h6>
                                        </div>
                                    </div>
                                </td>
                                <td class="fw-semibold">
                                    {{ performerProductWithPerformer.performer.name }}
                                </td>
                                <td class="fw-semibold">
                                    {{ performerProductWithPerformer.performer_product.price }} {{performerProductWithPerformer.performer_product.currency_code}}
                                </td>
                                <td class="fw-semibold">
                                    {{ performerProductWithPerformer.performer_product.cost }} {{performerProductWithPerformer.performer_product.currency_code}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-end">
                        <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                        <button mat-flat-button class="phobos" (click)="addPerformerProducts()">Add Performer Product(s)</button>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Custom Product">
                <form [formGroup]="addBookingCustomProductFormGroup">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Name</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_name'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-sticky-note-line"></i>
                                    <mat-label
                                            [ngStyle]="{'color': (addBookingCustomProductFormGroup.get('product_name')?.hasError('required') && addBookingCustomProductSubmitted) ? '#d73a49' : 'inherit'}">
                                        Product Name
                                    </mat-label>
                                    <input matInput formControlName="product_name">
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Description</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_description'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-sticky-note-line"></i>
                                    <mat-label
                                            [ngStyle]="{'color': (addBookingCustomProductFormGroup.get('product_description')?.hasError('required') && addBookingCustomProductSubmitted) ? '#d73a49' : 'inherit'}">
                                        Product Name
                                    </mat-label>
                                    <input matInput formControlName="product_description">
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Cost ({{selectedCurrency.currency_code}})</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_cost'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-sticky-note-line"></i>
                                    <mat-label
                                            [ngStyle]="{'color': (addBookingCustomProductFormGroup.get('product_cost')?.hasError('required') && addBookingCustomProductSubmitted) ? '#d73a49' : 'inherit'}">
                                        Product Cost
                                    </mat-label>
                                    <input matInput type="number" formControlName="product_cost">
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Price ({{selectedCurrency.currency_code}})</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_price'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-sticky-note-line"></i>
                                    <mat-label
                                            [ngStyle]="{'color': (addBookingCustomProductFormGroup.get('product_price')?.hasError('required') && addBookingCustomProductSubmitted) ? '#d73a49' : 'inherit'}">
                                        Product Price
                                    </mat-label>
                                    <input matInput type="number" formControlName="product_price">
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Product Category</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_category'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-menu-search-line"></i>
                                    <mat-label>Select Status</mat-label>
                                    <mat-select [(value)]="selectedProductCategory" formControlName="product_category" (selectionChange)="onCategoryChange()">
                                        <mat-option *ngFor="let productCategory of productCategories" [value]="productCategory">{{productCategory.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label class="d-block fw-semibold">Product Sub-Category</label>
                            <mat-card-content>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingCustomProductFormGroupControls['product_subcategory'].errors && addBookingCustomProductSubmitted}">
                                    <i class="ri-menu-search-line"></i>
                                    <mat-label>Select Status</mat-label>
                                    <mat-select [(value)]="selectedProductSubCategory" formControlName="product_subcategory">
                                        <mat-option *ngFor="let productSubcategory of productSubCategories" [value]="productSubcategory">{{productSubcategory.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </div>
                    <div class="text-end">
                        <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                        <button mat-flat-button class="phobos" (click)="addCustomProduct()">{{data.bookingProduct ? 'Update Custom Product' : 'Add Custom Product'}}</button>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>
