import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Performer, BookingDetails, Performer} from "../../../../common/model/model";
import {MatSelectChange} from "@angular/material/select";
import {PerformerService} from "../../../../services/performer.service";
import {BookingService} from "../../../../services/booking.service";

@Component({
    selector: 'add-performer-dialog-component',
    templateUrl: './add-performer-dialog.component.html',
    styleUrls: ['./add-performer-dialog.component.scss']
})
export class AddPerformerDialogComponent implements OnInit {

    public addPerformerFormGroup: FormGroup;
    public addPerformerSubmitted = false;

    public performers: Performer[] = [];
    public selectedPerformer: Performer | null = null;

    public bookingPerformerStatuses: string[] = [];
    public selectedBookingPerformerStatus: string;

    public disablePerformerSelect = false;

    constructor(public dialogRef: MatDialogRef<AddPerformerDialogComponent>,
                private addPerformerFormBuilder: FormBuilder,
                private bookingService: BookingService,
                private performerService: PerformerService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                    bookingPerformer?: Booking_Performer,
                }) {
    }

    ngOnInit(): void {
        this.enableAddPerformerDialogForm();
        this.getAllPerformers();
        this.getBookingPerformerStatus();
    }

    get addPerformerFormGroupControls() {
        return this.addPerformerFormGroup.controls;
    }

    public enableAddPerformerDialogForm() {
        this.addPerformerFormGroup = this.addPerformerFormBuilder.group({
            performer: [{value: '', disabled: false}, Validators.required],
            start_time: ['', Validators.required],
            end_time: ['', Validators.required],
            status: ['', Validators.required],
            buma_fee: ['']
        });
    }

    public populateForm(performer: Booking_Performer) {
        this.selectedPerformer = this.performers.find(p => p.id === performer.performer_id) || null;

        this.addPerformerFormGroup.patchValue({
            performer: this.selectedPerformer,
            start_time: performer.start_time,
            end_time: performer.end_time,
            status: performer.status
        });

        this.addPerformerFormGroup.get('performer')?.disable();
        this.disablePerformerSelect = true;
    }

    public onPerformerSelected(event: MatSelectChange) {
        this.selectedPerformer = event.value;
    }

    public getAllPerformers(): void {
        this.performerService.getAllPerformers(this.data.bookingDetails.booking.agency_id).then((performers: Performer[]) => {
            this.performers = performers;
            if (this.data.bookingPerformer) {
                this.populateForm(this.data.bookingPerformer);
            }
        });
    }

    public getBookingPerformerStatus() {
        this.bookingService.getPerformerBookingStatuses().then((statuses: string[]) => {
            this.bookingPerformerStatuses = statuses;
        });
    }

    public transformPlaytimes(): string {
        if (!this.selectedPerformer || !this.selectedPerformer.playtimes || this.selectedPerformer.playtimes.length === 0) {
            return '';
        }
        // Join the array with comma, space before and after each comma
        return this.selectedPerformer.playtimes.join(' , ');
    }

    public addPerformer() {
        this.addPerformerSubmitted = true
        if(this.addPerformerFormGroup.invalid) {

        } else {
            let newBookingPerformer: Booking_Performer = {
                performer_id: this.selectedPerformer?.id,
                booking_id: this.data.bookingDetails.booking.id,
                name: this.addPerformerFormGroup.controls['performer'].value.name,
                start_time: this.addPerformerFormGroup.controls['start_time'].value,
                end_time: this.addPerformerFormGroup.controls['end_time'].value,
                status: this.addPerformerFormGroup.controls['status'].value,
            }

            if(this.isBumaFeeSelected()) {
                newBookingPerformer.buma_fee = 7;
            }

            this.dialogRef.close(newBookingPerformer);
        }
    }

    public isBumaFeeSelected(): boolean {
        return this.addPerformerFormGroup.controls['buma_fee'].value;
    }

    public close() {
        this.dialogRef.close(true);
    }
}
