import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Performer, Performer_Product} from "../../../../common/model/model";
import {AddProductDialogComponent} from "../../../product/add-product/add-product-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PerformerService} from "../../../../services/performer.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'performerproductsinfo-component',
    templateUrl: './performerproducts-info.component.html',
    styleUrls: ['./performerproducts-info.component.scss']
})
export class PerformerProductsInfoComponent implements OnInit, OnChanges {

    @Input() performer: Performer;
    @Input() performerProducts: Performer_Product[] = [];

    constructor(private matDialog: MatDialog,
                private performerService: PerformerService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['performer'] && changes['performer'].currentValue) {
            //DO NOTHING FOR NOW
        }
        if (changes['performerProducts'] && changes['performerProducts'].currentValue) {
            //console.log('performer products: ' + JSON.stringify(this.performerProducts));
        }
    }

    public removePerformerProduct(index: number) {
        let performerProduct = this.performerProducts[index];

        this.performerService.deletePerformerProduct(performerProduct).then(() => {
            this.notificationService.openSuccessfulNotification("Performer product " + performerProduct.name + " removed successfully");
            this.performerProducts.splice(index, 1);
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    public editPerformerProduct(index: number) {
        const productDialogRef = this.matDialog.open(AddProductDialogComponent, {
            width: '600px',
            data: this.performerProducts[index] as Performer_Product
        });

        productDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                this.performerProducts[index] = result;

                let performerProduct = result as Performer_Product;
                performerProduct.id = this.performerProducts[index].id;
                performerProduct.currency_code = this.performer.currency_code;
                performerProduct.performer_id = this.performer.id;

                this.performerService.updatePerformerProduct(performerProduct).then(performerProduct => {
                    this.notificationService.openSuccessfulNotification("Performer product " + performerProduct[0].name + " updated successfully");
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            };
        });
    }

    public openAddProductDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
        const productDialogRef = this.matDialog.open(AddProductDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: this.performer as Performer
        });

        productDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                this.performerProducts.push(result);

                let performerProduct = result as Performer_Product;
                performerProduct.currency_code = this.performer.currency_code;
                performerProduct.performer_id = this.performer.id;

                this.performerService.addPerformerProduct(performerProduct).then(performerProduct => {
                    this.notificationService.openSuccessfulNotification("Performer product " + performerProduct[0].name + " added successfully");
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            };
        });
    }
}
