<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New External Agency</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addAgencyExternalFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Name</label>
                    <input formControlName="name" type="text" class="input-control" placeholder="Agency Name *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Address</label>
                    <input formControlName="address" type="text" class="input-control" placeholder="Address *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['address'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['address'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Postcode</label>
                    <input formControlName="postcode" type="text" class="input-control" placeholder="Postcode *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['postcode'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['postcode'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">City</label>
                    <input formControlName="city" type="email" class="input-control" placeholder="City *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['city'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['city'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Country</label>
                    <input formControlName="country" type="text" class="input-control" placeholder="Country *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['country'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['country'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Phone number</label>
                    <input formControlName="phone" type="text" class="input-control" placeholder="Phone number *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['phone'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['phone'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">E-Mail</label>
                    <input formControlName="email" type="text" class="input-control" placeholder="Email *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['email'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['email'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Website</label>
                    <input formControlName="website" type="text" class="input-control" placeholder="Website *"
                           [ngClass]="{ 'is-invalid': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['website'].errors}"
                           [ngStyle]="{'border-color': addAgencyExternalSubmitted && addAgencyExternalFormGroupControls['website'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="saveAgencyExternal()">Save</button>
        </div>
    </form>
</div>
