import {inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {SupabaseAuthService} from "../services/supabase.auth.service";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RoleGuardService {
    constructor(private router: Router,
                private supabaseAuthService: SupabaseAuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const expectedRole = next.data['expectedRole'] as string;

        return this.supabaseAuthService.currentUser$.pipe(
            map(currentUser => {
                if (!currentUser) {
                    throw new Error('User not logged in');
                }

                if (currentUser.user_metadata && currentUser.user_metadata['role']) {
                    if (currentUser.user_metadata['role'] !== expectedRole) {
                        this.router.navigate(['/']);
                        return false;
                    }
                    return true;
                }
                return true;
            }),
            catchError((error) => {
                console.error('Error in RoleGuard:', error);
                this.router.navigate(['/']);
                return of(false);
            })
        );
    }

    // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //     const expectedRole = next.data['expectedRole'] as string;
    //     let currentUserInfo = this.supabaseAuthService.getCurrentUserInfo();
    //     console.log('currentuser info: ' + JSON.stringify(currentUserInfo));
    //
    //     if(currentUserInfo.user_metadata['role']) {
    //         if (!currentUserInfo || !currentUserInfo.user_metadata || currentUserInfo.user_metadata['role'] !== expectedRole) {
    //             this.router.navigate(['/']);
    //             return of(false);
    //         } else {
    //             return of(true);
    //         }
    //     } else {
    //         return of(true);
    //     }
    //
    // }
}


export const RoleGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(RoleGuardService).canActivate(next,state);
}
