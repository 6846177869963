import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    public searchSubject = new BehaviorSubject<string>('');
    public searchObservable = this.searchSubject.asObservable();

    constructor() {
    }

    public updateSearchTerm(searchTerm: string) {
        this.searchSubject.next(searchTerm);
    }
}
