<mat-card class="mb-25 phobos-card">
    <mat-card-header>
        <h5 class="mb-0">Performer Products</h5>
        <a mat-stroked-button class="default-btn phobos" (click)="openAddProductDialog('300ms','100ms')">
            <i class="ri-add-fill"></i>Add Product
        </a>
    </mat-card-header>
    <mat-card-content>
        <mat-label *ngIf="performerProducts && performerProducts[0] == null">No Products added yet</mat-label>
        <div class="recent-files-table table-responsive"
             *ngIf="performerProducts && performerProducts.length >0 && performerProducts[0] !== null">
            <table class="table" *ngIf="performerProducts.length >= 1">
                <thead >
                <tr>
                    <th class="fw-semibold main-color">Name</th>
                    <th class="fw-semibold main-color">Price</th>
                    <th class="fw-semibold main-color">Cost</th>
                    <th class="fw-semibold main-color">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let performerProduct of performerProducts; let p = index">
                    <td>
                        <div class="name-info d-flex align-items-center">
                            <img src="assets/img/icon/product.png" alt="folder">
                            <h5 class="fw-semibold mb-0">{{performerProduct.name}}</h5>
                        </div>
                    </td>
                    <td>{{performerProduct.price}}</td>
                    <td>{{performerProduct.cost}}</td>
                    <td>
                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                             (click)="trigger.openMenu()"
                             #trigger="matMenuTrigger"
                             [matMenuTriggerFor]="performerProductActionMenu">
                            <i class="ri-more-fill"></i>
                        </div>
                        <mat-menu #performerProductActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editPerformerProduct(p)">
                                <i class="ri-edit-2-line"></i> Edit
                            </button>
                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removePerformerProduct(p)">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
