<mat-card
    class="mb-25 phobos-card impressions-box"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <h6>Impressions</h6>
        <h3>$12,875 <span><i class="ri-arrow-up-s-fill"></i> 10%</span></h3>
        <p>Compared to $21,490 last year</p>
    </mat-card-content>
</mat-card>
