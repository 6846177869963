<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Agency</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addAgencyFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Name</label>
                    <input formControlName="name" type="text" class="input-control" placeholder="Agency Name *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Address</label>
                    <input formControlName="address" type="text" class="input-control" placeholder="Address *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['address'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['address'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Postcode</label>
                    <input formControlName="postcode" type="text" class="input-control" placeholder="Postcode *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['postcode'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['postcode'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">City</label>
                    <input formControlName="city" type="email" class="input-control" placeholder="City *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['city'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['city'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Country</label>
                    <input formControlName="country" type="text" class="input-control" placeholder="Country *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['country'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['country'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Phone number</label>
                    <input formControlName="phone" type="text" class="input-control" placeholder="Phone number *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['phone'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['phone'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">E-Mail</label>
                    <input formControlName="email" type="text" class="input-control" placeholder="Email *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['email'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['email'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Website</label>
                    <input formControlName="website" type="text" class="input-control" placeholder="Website *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['website'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['website'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Bank number</label>
                    <input formControlName="bank_number" type="text" class="input-control" placeholder="Bank number *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['bank_number'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['bank_number'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">BIC Code</label>
                    <input formControlName="bic_code" type="text" class="input-control" placeholder="BIC Code *"
                           [ngClass]="{ 'is-invalid': addAgencySubmitted && addAgencyFormGroupControls['bic_code'].errors}"
                           [ngStyle]="{'border-color': addAgencySubmitted && addAgencyFormGroupControls['bic_code'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <mat-card class="mb-25 phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Agency Logo</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <ngx-dropzone (change)="onSelect($event)">
                            <ngx-dropzone-label class="fw-semibold">Drop files here or click to upload.</ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of logo" [file]="f">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-image-preview>
                        </ngx-dropzone>
                    </mat-card-content>
                    <div class="align-items-center" *ngIf="addAgencySubmitted && addAgencyFormGroupControls['logo'].errors" style="color:red;font-size:small">
                        <div *ngIf="addAgencyFormGroupControls['logo'].errors?.['required']">Please add your agency logo</div>
                    </div>
                </mat-card>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="saveAgency()">Save</button>
        </div>
    </form>
</div>
