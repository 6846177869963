<mat-card
    class="mb-25 phobos-card tasks-performance-box"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tasks Performance</h5>
        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            <i class="ri-more-fill"></i>
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">Today</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">Last Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="chart tasksPerformanceChart">
            <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [plotOptions]="chartOptions.plotOptions!"
                [labels]="chartOptions.labels!"
                [legend]="chartOptions.legend!"
                [colors]="chartOptions.colors!"
            ></apx-chart>
        </div>
        <ul class="list d-flex align-items-center justify-content-space-between text-center mb-0">
            <li class="muted-color fw-medium down">
                <span class="d-block">Target</span>
                <span class="gray-color d-inline-block position-relative"><i class="flaticon-right-arrow"></i> 99k</span>
            </li>
            <li class="muted-color fw-medium">
                <span class="d-block">Last Week</span>
                <span class="gray-color d-inline-block position-relative"><i class="flaticon-right-arrow"></i> 18.99k</span>
            </li>
            <li class="muted-color fw-medium">
                <span class="d-block">Last Month</span>
                <span class="gray-color d-inline-block position-relative"><i class="flaticon-right-arrow"></i> 79k</span>
            </li>
        </ul>
    </mat-card-content>
</mat-card>
