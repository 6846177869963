import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {Agency, Booking_Performer, BookingDetails, BumaFee, Invoice, Performer} from "../../../../common/model/model";
import {AgencyService} from "../../../../services/agency.service";
import {MatDialog} from "@angular/material/dialog";
import {AddInvoiceDialogComponent} from "../add-invoice/add-invoice-dialog.component";
import {InvoiceService} from "../../../../services/invoice.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'financialinfo-component',
    templateUrl: './financial-info.component.html',
    styleUrls: ['./financial-info.component.scss']
})
export class FinancialInfoComponent implements OnInit, OnChanges {

    @Input() bookingDetails: BookingDetails;
    public financialsTotal: number = 0;
    public financialsTotalWithVAT: number = 0;
    public actualVATAmount = 0;
    public financialsVATRate: number = 21;
    public selectedAgency: Agency;
    public combinedPerformers: (Booking_Performer & Partial<Performer>)[] = [];
    public bumaFees: BumaFee[] = [];

    constructor(public themeService: CustomizerSettingsService,
                private agencyService: AgencyService,
                private invoiceService: InvoiceService,
                private notificationService: NotificationService,
                private matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['bookingDetails'] && changes['bookingDetails'].currentValue) {
            this.agencyService.selectedAgency$.subscribe(agency => {
                this.selectedAgency = agency;
            });

            this.combinedPerformers = this.combinePerformers();
            this.calculateProductFinancialsTotal();
        }
    }

    public calculateProductFinancialsTotal() {
        if(this.bookingDetails && this.bookingDetails.products) {

            //Calculate the total price of the products
            this.bookingDetails.products.forEach(product => {
                this.financialsTotal += product.price;
            });

            this.combinedPerformers.forEach(performer => {
                if(performer.price) {
                    if(performer.buma_fee) {
                        //calculate the buma fee
                        let bumaFee = performer.price * performer.buma_fee / 100;

                        if(performer.currency_code && performer.currency_symbol) {
                            let bumaFeeObject: BumaFee = {
                                bumaFee: bumaFee,
                                performerName: performer.name + ' - Buma Fee (7%)',
                                currencySymbol: performer.currency_symbol,
                                currencyCode: performer.currency_code
                            };
                            this.bumaFees.push(bumaFeeObject);
                        }

                        this.financialsTotal += bumaFee;
                    }
                    this.financialsTotal += performer.price;
                }
            });

            this.financialsTotalWithVAT = this.financialsTotal + (this.financialsTotal * this.financialsVATRate / 100);
            this.actualVATAmount = this.financialsTotal * this.financialsVATRate / 100;
        }
    }

    public combinePerformers(): (Booking_Performer & Partial<Performer>)[] {
        if (!this.bookingDetails || !this.bookingDetails.performers || !this.bookingDetails.performer_details) {
            return [];
        }

        return this.bookingDetails.performers.map(performer => {
            const details = this.bookingDetails.performer_details.find(detail => detail.id === performer.performer_id);
            return {
                ...performer,
                ...details
            };
        });
    }

    public openAddInvoiceDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const dialogData = {
            bookingDetails: this.bookingDetails,
            financialsTotalWithVAT: this.financialsTotalWithVAT
        }

        const addInvoiceDialog = this.matDialog.open(AddInvoiceDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: dialogData
        });

        addInvoiceDialog.afterClosed().subscribe((result: Invoice) => {
            if(result != null && typeof result === 'object') {
                this.bookingDetails.invoices.push(result);
            }
        });
    }

    public editInvoice(invoice: Invoice) {
        const dialogData = {
            bookingDetails: this.bookingDetails,
            financialsTotalWithVAT: this.financialsTotalWithVAT,
            invoice
        }

        const addInvoiceDialog = this.matDialog.open(AddInvoiceDialogComponent, {
            width: '600px',
            data: dialogData
        });

        addInvoiceDialog.afterClosed().subscribe((result: Invoice) => {
            if(result != null && typeof result === 'object') {
                this.bookingDetails.invoices = this.bookingDetails.invoices.map(i => i.id === result.id ? result : i);
            }
        });
    }

    public deleteInvoice(invoice: Invoice) {
        this.invoiceService.deleteInvoice(invoice).then(() => {
            this.bookingDetails.invoices = this.bookingDetails.invoices.filter(i => i.id !== invoice.id);
            this.notificationService.openSuccessfulNotification('Successfully deleted invoice with nr: ' + invoice.invoice_nr);
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }
}
