<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add Hotel</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addPerformerHotelFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Performer</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerHotelFormGroupControls['performer'].errors && addPerformerHotelSubmitted}">
                                <i class="ri-account-circle-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerHotelFormGroup.get('performer')?.hasError('required') && addPerformerHotelSubmitted) ? '#d73a49' : 'inherit'}">
                                    Select Performer
                                </mat-label>
                                <mat-select formControlName="performer" (selectionChange)="onPerformerSelected($event)" [compareWith]="comparePerformers">
                                <mat-option *ngFor="let bookingPerformer of bookingPerformers" [value]="bookingPerformer">{{bookingPerformer.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Hotel Name</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerHotelFormGroupControls['hotel_name'].errors && addPerformerHotelSubmitted}">
                                <i class="ri-hotel-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerHotelFormGroup.get('hotel_name')?.hasError('required') && addPerformerHotelSubmitted) ? '#d73a49' : 'inherit'}">
                                    Hotel name
                                </mat-label>
                                <input matInput formControlName="hotel_name">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Hotel Address</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerHotelFormGroupControls['hotel_address'].errors && addPerformerHotelSubmitted}">
                                <i class="ri-corner-right-up-fill"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerHotelFormGroup.get('hotel_address')?.hasError('required') && addPerformerHotelSubmitted) ? '#d73a49' : 'inherit'}">
                                    Hotel address
                                </mat-label>
                                <input matInput formControlName="hotel_address">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Check-In Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerHotelFormGroupControls['check_in_date'].errors && addPerformerHotelSubmitted}">
                                <i class="ri-calendar-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerHotelFormGroup.get('check_in_date')?.hasError('required') && addPerformerHotelSubmitted) ? '#d73a49' : 'inherit'}">
                                    Check-in date
                                </mat-label>
                                <input matInput formControlName="check_in_date" [matDatepicker]="flightDatePicker">
                                <mat-datepicker-toggle matIconSuffix [for]="flightDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #flightDatePicker></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Check-In Time</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerHotelFormGroupControls['check_in_time'].errors && addPerformerHotelSubmitted}">
                                <i class="ri-time-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerHotelFormGroup.get('check_in_time')?.hasError('required') && addPerformerHotelSubmitted) ? '#d73a49' : 'inherit'}">
                                    Check-in time
                                </mat-label>
                                <input matInput type="time" formControlName="check_in_time">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button mat-flat-button class="gray" (click)="close()">Cancel</button>
                <button mat-flat-button class="phobos" (click)="addPerformerHotel()">Save</button>
            </div>
        </form>
    </mat-card>

</div>
