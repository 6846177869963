import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Contact, BookingDetails} from "../../../../common/model/model";

@Component({
    selector: 'add-contact-dialog-component',
    templateUrl: './add-contact-dialog.component.html',
    styleUrls: ['./add-contact-dialog.component.scss']

})
export class AddContactDialogComponent implements OnInit {

    public addContactFormGroup: FormGroup;
    public addContactSubmitted = false;

    constructor(public dialogRef: MatDialogRef<AddContactDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                    bookingContact?: Booking_Contact
                },
                private addContactFormBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.enableAddContactDialogForm();

        if(this.data.bookingContact) {
            this.populateForm(this.data.bookingContact);
        }
    }

    get addContactFormGroupControls() {
        return this.addContactFormGroup.controls;
    }

    public enableAddContactDialogForm() {
        this.addContactFormGroup = this.addContactFormBuilder.group({
            contact_name: ['', Validators.required],
            contact_role: ['', Validators.required],
            contact_phone: ['', Validators.required],
        });
    }

    private populateForm(contact: Booking_Contact) {
        this.addContactFormGroup.patchValue({
            contact_name: contact.contact_name,
            contact_role: contact.contact_role,
            contact_phone: contact.contact_phone
        });
    }

    public addContact() {
        this.addContactSubmitted = true
        if(this.addContactFormGroup.invalid) {

        } else {
            let newBookingContact: Booking_Contact = {
                id: this.data.bookingContact?.id,
                booking_id: this.data.bookingDetails.booking.id,
                contact_name: this.addContactFormGroup.controls['contact_name'].value,
                contact_role: this.addContactFormGroup.controls['contact_role'].value,
                contact_phone: this.addContactFormGroup.controls['contact_phone'].value,
            };

            this.dialogRef.close(newBookingContact);
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
