//The interfaces here are used to define the structure of the data that is being used in the application.
import { Time } from "@angular/common";

export interface Agency {
    id?: string;
    name: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
    phone: string;
    website: string;
    email: string;
    logo: string;
    bank_number: string;
    bic_code: string;
}

export interface Agency_External {
    id?: string;
    name: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
    phone: string;
    website: string;
    email: string;
    agency_id?: string;
}

export interface Booking {
    id?: string;
    event_name: string;
    event_website: string;
    event_budget: number;
    event_date: Date;
    opening_time: Time;
    begin_time: Time;
    end_time: Time;
    booking_description: string;
    line_up_event: string;
    internal_notes: string;
    construction_start_time: Time;
    sound_check_time: Time;
    podium_ready_time: Time;
    performance_floor: string;
    distance_to_stage: string;
    elevator_availability: string;
    visitor_expectation: string;
    available_tickets: string;
    call_sheet_memo: string;
    status: string;
    agency_id: string;
    team_lead_id: string;
    location_id: string;
    customer_id: string;
    currency_code: string;
    currency_symbol: string;
}

export interface Booking_Contact {
    id?: string;
    booking_id?: string;
    contact_name: string;
    contact_role: string;
    contact_phone: string;
}

export interface Booking_Attachment {
    id?: string;
    booking_id?: string;
    file_key: string;
    file_name: string;
    mime_type: string;
    uploaded_date?: Date;
}

export interface Booking_Performer {
    id?: string;
    name: string;
    booking_id?: string;
    performer_id?: string;
    start_time: string;
    end_time: string;
    status: string;
    buma_fee?: number;
    playtimes?: string[];
}

export interface Booking_Product {
    id?: string;
    booking_id?: string;
    name: string;
    description: string;
    subcategory: string;
    category: string;
    cost: number;
    price: number;
    currency_code: string;
    currency_symbol: string;
    performer_id?: string;
}

export interface Booking_Performer_Flight {
    id?: string;
    performer_id: string;
    booking_id?: string;
    description: string;
    flight_number: string;
    from_departure: string;
    to_destination: string;
    flight_date: Date;
    flight_time: Time;
}

export interface Booking_Performer_Hotel {
    id?: string;
    performer_id: string;
    booking_id?: string;
    hotel_name: string;
    hotel_address: string;
    check_in_date: Date;
    check_in_time: Time;
}

export interface Booking_Guest {
    id?: string;
    booking_id?: string;
    name: string;
    email: string;
    description: string;
}

export interface Performer {
    id?: string;
    name: string;
    email?: string;
    description: string;
    price: number;
    cost: number;
    currency_code: string;
    performerrider?: string;
    presskit?: string;
    var?: string;
    playtimes: string[];
    agency_id: string;
    exclusivity: boolean;
    agency_external_id?: string;
    currency_symbol: string;
    picture: string;
}

export interface Member {
    id?: string;
    name: string;
    email: string;
    phone: string;
    role: string;
}

export interface Category {
    id?: string;
    name: string;
    agency_id: string;
}

export interface Performer_Categories {
    performer_id?: string;
    category_id?: string;
}

export interface Performer_Product {
    id?: number;
    name: string;
    description: string;
    price: number;
    cost: number;
    currency_code?: string;
    picture: string;
    performer_id?: string;
}

export interface Performer_Product_With_Performer {
    performer_product: Performer_Product;
    performer: Booking_Performer;
}

export interface Role {
    id?: number;
    name: string;
}

export interface Location {
    id?: string;
    name: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
    phone: string;
    occupation: string;
    website: string;
    logo: string;
    agency_id: string;
}

export interface Customer {
    id?: string;
    name: string;
    email: string;
    phone: string;
    address: string;
    postcode: string;
    city: string;
    country: string;
    website: string;
    vat_number: string;
    bank_account: string;
    logo: string;
    agency_id: string;
}

export interface Product_Category {
    id?: string;
    name: string;
    description: string;
    agency_id: string;
}

export interface Product_Subcategory {
    id?: string;
    name: string;
    description: string;
    product_category_id: string;
    agency_id: string;
}

export interface Invoice {
    id?: string;
    booking_id?: string;
    agency_id?: string;
    customer_id?: string;
    invoice_nr?: string;
    invoice_date: Date;
    invoice_due_date: Date
    invoice_status: string;
    invoice_description: string;
    invoice_amount: number;
}

export interface Agency_User {
    agency: Agency;
    user: User;
}

export interface User {
    id?: number;
    email: string;
}

export interface Currency {
    id?: string;
    currency_code: string;
    symbol: string;
    description: string;
    image: string;
}

export interface PerformerDetails {
    performer: Performer;
    categories: Category[];
    products: Performer_Product[];
    performer_categories: Performer_Categories[];
}

export interface BookingDetails {
    booking: Booking;
    contacts: Booking_Contact[];
    attachments: Booking_Attachment[];
    performers: Booking_Performer[];
    products: Booking_Product[];
    performer_details: Performer[];
    location: Location;
    customer: Customer;
    booking_performer_flights: Booking_Performer_Flight[];
    booking_performer_hotels: Booking_Performer_Hotel[];
    booking_guests: Booking_Guest[];
    invoices: Invoice[];
}

export interface File {
    name: string;
    type: string;
    size: number;
    lastModified: Date;
}

export interface BookingSummary {
    booking_id: string;
    status: string;
    event_name: string;
    event_date: Date;
    event_website: string;
    event_budget: string;
    opening_time: Time;
    begin_time: Time;
    end_time: Time;
    customer_name: string;
    location_name: string;
}

export interface Invitation {
    id?: string;
    email: string;
    role: string;
    created_by: string;
    token: string;
    expires_at: Date;
    status: string;
}

export enum InvitationStatus {
    NEW = "NEW",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED"
}

export enum InvitationType {
    PERFORMER = "PERFORMER",
    AGENCY = "AGENCY",
    TECHNICAL = "TECHNICAL"
}

export enum FileTypes {
    performer_rider = "performer_rider",
    performer_presskit = "performer_presskit",
    performer_var = "performer_var",
}

export enum BookingStatus {
    DRAFT = "DRAFT",
    OPTIONAL = "OPTIONAL",
    PENDING = "PENDING",
    CONFIRMED = "CONFIRMED",
    ADVANCING = "ADVANCING",
    CANCELED = "CANCELED",
}

export enum UserRole {
    AGENCY = "Agency",
    PERFORMER = "Performer",
    TECHNICAL = "Technical Support"
}

export enum PerformerStatus {
    OPTION = "Option",
    CONFIRMED = "Confirmed",
}

export interface CombinedPerformers {
    performer: Booking_Performer;
    performerProducts: Performer_Product;
}

export interface BumaFee {
    bumaFee: number;
    performerName: string;
    currencySymbol: string;
    currencyCode: string;
}

export interface PerformerFile extends File {
    performerFileType: string;
}
