import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {
    Agency,
    Agency_External,
    Booking_Performer,
    Category,
    Currency,
    Member,
    Performer,
    Performer_Categories,
    Performer_Product
} from "../../../../common/model/model";
import {AddCategoryDialogComponent} from "../../../categories/add-category/add-category-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {AddPerformerMemberDialogComponent} from "../../add-performer-member/add-performer-member-dialog.component";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {AgencyExternalService} from "../../../../services/agencyexternal.service";
import {AgencyService} from "../../../../services/agency.service";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {
    AddAgencyExternalDialogComponent
} from "../../../agency/add-agency-external/add-agency-external-dialog.component";
import {PerformerService} from "../../../../services/performer.service";
import {NotificationService} from "../../../../services/notification.service";
import {externalAgencyRequiredValidator} from "../../../../validation/externalagency.validator";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
    selector: 'performerinfo-component',
    templateUrl: './performer-info.component.html',
    styleUrls: ['./performer-info.component.scss']
})
export class PerformerInfoComponent implements OnInit, OnChanges, AfterViewInit {

    public performerInfoFormGroup: FormGroup;

    predefinedDate: Date = new Date(2024, 6, 15);
    public bookingPerformers: Booking_Performer[] = [];
    public selectedCategoryIds: string[];
    public categoriesFormControl: FormControl;
    public externalAgencies: Agency_External[] = [];
    public selectedExternalAgency: Agency_External;
    public filteredExternalAgencies: Observable<Agency_External[]>;

    public externalAgencyFormControl = new FormControl('');
    public playtimesFormControl: FormControl;

    @Input() performer: Performer;
    @Input() performerProducts: Performer_Product[];
    @Input() members?: Member[] = [];
    @Input() categories: Category[];
    @Input() performer_categories: Performer_Categories[];
    @Input() selectedCurrency: Currency;
    @Input() allCategories: Category[] = [];

    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addOnBlur = true;
    public performerExclusivity = true;
    public updateDisabled: boolean = true;

    constructor(public themeService: CustomizerSettingsService,
                private matDialog: MatDialog,
                private performerInfoFormBuilder: FormBuilder,
                private agencyExternalService: AgencyExternalService,
                private agencyService: AgencyService,
                private performerService: PerformerService,
                private notificationService: NotificationService,
                private focusMonitor: FocusMonitor) {
    }

    ngOnInit() {
        this.categoriesFormControl = new FormControl([]);

        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.getAllExternalAgencies(agency);
            }
        });
    }

    ngAfterViewInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['members'] && changes['members'].currentValue) {
            if (this.members !== null && this.members !== undefined && this.members[0] !== null) {
                if (this.members.length > 0) {
                }
            } else {
                console.log('Members is null or undefined');
            }
        }

        if (changes['categories'] && changes['categories'].currentValue) {
            this.selectedCategoryIds = this.categories
                .filter(c => c && c.id !== undefined)  // Filter out null values
                .map(c => c.id!);
        }

        // Initialize categoriesFormControl if not already initialized
        if (!this.categoriesFormControl) {
            this.categoriesFormControl = new FormControl([]);
        }

        if (changes['performer'] && changes['performer'].currentValue) {
            // If performer is not null, enable the performerInfoForm.
            // Do it here, to make sure that the form is enabled after the performer has been loaded.
            this.enablePerformerInfoForm();
            this.listenToExclusivityChange();
            this.enableFilterExternalAgencies();

            this.performerInfoFormGroup.valueChanges.subscribe(() => {
                this.updateDisabled = this.performerInfoFormGroup.pristine;
            });

            this.playtimesFormControl = new FormControl(
                [this.performer.playtimes],
                [this.minPlayTimesValidator(1)],
            );
        }
    }

    public enablePerformerInfoForm() {
        this.performerInfoFormGroup = this.performerInfoFormBuilder.group({
            name: [this.performer.name, Validators.required],
            description: [this.performer.description, Validators.required],
            price: [this.performer.price, Validators.required],
            cost: [this.performer.cost, Validators.required],
            playtimes: this.playtimesFormControl,
            externalAgency: [""],
            exclusiveArtist: [this.performer.exclusivity, Validators.required], // Always required
        }, { validators: [externalAgencyRequiredValidator] });
    }

    get performerInfoFormControls() {
        return this.performerInfoFormGroup.controls;
    }

    public addPlayTime(event: MatChipInputEvent) {
        const value = (event.value || '').trim();

        if (value) {
            this.performer.playtimes.push(value);
        }

        event.chipInput!.clear();
    }

    public removePlayTime(playtime: string) {
        const index = this.performer.playtimes.indexOf(playtime);

        if (index >= 0) {
            this.performer.playtimes.splice(index, 1);
        }
    }

    public editPlaytime(playtime: string, event: MatChipEditedEvent) {
        const value = event.value.trim();

        if (!value) {
            this.removePlayTime(playtime);
            return;
        }

        const index = this.performer.playtimes.indexOf(playtime);
        if (index >= 0) {
            this.performer.playtimes[index] = value;
        }
    }

    public openAddCategoryDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
        const categoryDialogRef = this.matDialog.open(AddCategoryDialogComponent, {
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration
        });

        // Retrieve the result from the dialog and add it to the categories list and selection
        categoryDialogRef.afterClosed().subscribe(result => {
            if (result != null) {
                this.categories.push(result);
                const updatedValue = [...(this.categoriesFormControl.value || []), result];
                this.categoriesFormControl.setValue(updatedValue);
                this.selectedCategoryIds.push(result.id);  // Update selectedCategoryIds
            }
        });
    }

    public openAddPerformerMemberDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addPerformerMemberDialogRef = this.matDialog.open(AddPerformerMemberDialogComponent, {
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration
        });

        addPerformerMemberDialogRef.afterClosed().subscribe(result => {
            if(result != null) {
                if(this.members) {
                    this.members.push(result);
                }
                //console.log('Members: ' + JSON.stringify(this.members));
            };
        });
    }

    public enableFilterExternalAgencies() {
        this.filteredExternalAgencies = this.externalAgencyFormControl.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filteredExternalAgencies(state) : this.externalAgencies.slice())),
        );
    }

    private _filteredExternalAgencies(value: any): Agency_External[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && typeof value === 'object' && value.name) {
            filterValue = value.name.toLowerCase();
        }
        return this.externalAgencies.filter(externalAgency => externalAgency.name.toLowerCase().includes(filterValue));
    }

    displayFn(location: any): string {
        return location && location.name ? location.name : '';
    }

    public openAddExternalAgencyDialog(enterAnimationDuration: string,
                                       exitAnimationDuration: string,
    ): void {
        const externalAgencyDialogRef = this.matDialog.open(
            AddAgencyExternalDialogComponent,
            {
                width: "600px",
                enterAnimationDuration,
                exitAnimationDuration,
            },
        );

        externalAgencyDialogRef.afterClosed().subscribe((result) => {
            if (result != null && typeof result === "object") {
                this.externalAgencies.push(result);
                this.externalAgencyFormControl.setValue(result);
            }
        });
    }

    public getAllExternalAgencies(agency: Agency) {
        this.agencyExternalService.getAllAgenciesExternal(agency).then(externalAgencies => {
            this.externalAgencies = externalAgencies;
        });
    }

    public onExternalAgencySelected(event: any) {
        this.selectedExternalAgency = event.option.value;
    }

    public listenToExclusivityChange() {
        this.performerInfoFormGroup.get('exclusiveArtist')?.valueChanges.subscribe(value => {
            this.performerExclusivity = value;
        });
    }

    public minPlayTimesValidator(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const playtimes = control.value;
            return Array.isArray(playtimes) && playtimes.length >= min ? null : { minPlayTimes: { requiredMin: min } };
        };
    }

    public removeMember(index: number) {
        //remove member from the list
        if(this.members) {
            this.members.splice(index, 1);
        }
    }

    public editMember(index: number,enterAnimationDuration: string, exitAnimationDuration: string) {
        //edit member from the list
        if(this.members) {
            const member = this.members[index];
            const addPerformerMemberDialogRef = this.matDialog.open(AddPerformerMemberDialogComponent, {
                width: '500px',
                enterAnimationDuration,
                exitAnimationDuration,
                data: member
            });

            addPerformerMemberDialogRef.afterClosed().subscribe(result => {
                if(result != null && this.members) {
                    this.members[index] = result;
                }
            });
        }
    }

    public editMemberTest() {
        //edit member from the list
        const addPerformerMemberDialogRef = this.matDialog.open(AddPerformerMemberDialogComponent, {
            width: '500px',
        });
    }

    public updatePerformerInfo() {
        if(this.performerInfoFormGroup.invalid) {
            console.log('invalid');
        } else {
            console.log('valid');
            let performerName = this.performerInfoFormGroup.controls['name'].value;
            let performerDescription = this.performerInfoFormGroup.controls['description'].value;
            let performerPrice = this.performerInfoFormGroup.controls['price'].value;
            let performerCost = this.performerInfoFormGroup.controls['cost'].value;
            let performerExclusivity = this.performerInfoFormGroup.controls['exclusiveArtist'].value;
            let performerExternalAgency = this.performerInfoFormGroup.controls['externalAgency'].value;
            let performerPlayTimes = this.performerInfoFormGroup.controls['playtimes'].value;

            this.performer.name = performerName;
            this.performer.description = performerDescription;
            this.performer.price = performerPrice;
            this.performer.cost = performerCost;
            this.performer.exclusivity = performerExclusivity;
            this.performer.playtimes = performerPlayTimes;
            this.performer.agency_external_id = performerExternalAgency.id;

            console.log('Test: ' + JSON.stringify(this.performer));

            this.performerService.updatePerformer(this.performer).then(updatedPerformer => {
                if(updatedPerformer) {
                    this.notificationService.openSuccessfulNotification('Performer updated successfully');
                }
            });
        }
    }
}
