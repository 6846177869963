import {Injectable} from "@angular/core";
import {Agency} from "../common/model/model";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";
import {BehaviorSubject, Observable, take} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AgencyService {

    private selectedAgencySubject = new BehaviorSubject<any>(null);
    selectedAgency$ = this.selectedAgencySubject.asObservable();

    constructor(private supabaseService: SupabaseService) {
        const savedAgency = localStorage.getItem('selectedAgency');
        if (savedAgency != null && savedAgency !== 'undefined') {
            this.selectedAgencySubject.next(JSON.parse(savedAgency));
        }
    }

    public setSelectedAgency(agency: Agency) {
        localStorage.setItem('selectedAgency', JSON.stringify(agency));
        this.selectedAgencySubject.next(agency);
    }

    public getSelectedAgency() {
        return this.selectedAgencySubject.getValue();
    }

    public getSelectedAgencyTest(): Observable<Agency | null> {
        return this.selectedAgency$.pipe(take(1)); // Emit the current value immediately
    }

    public fetchSelectedAgency(): Observable<Agency | null> {
        return this.selectedAgency$.pipe(map(agency => agency || null));
    }

    public async getAllAgencies() {

        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.AGENCIES)
            .select('*');

        if(error) {
            throw new Error("Failed to retrieve agencies, because: " + error.message);
        }
        return data as Agency[];
    }

    public async addAgencyOLD(agency: Agency): Promise<Agency[]> {
        const {data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.AGENCIES)
            .insert(agency)
            .select();

        if(error) {
            throw new Error("Failed to add agency, because: " + JSON.stringify(error));
        }
        return data as Agency[];
    }

    public async addAgency(agency: Agency): Promise<Agency[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .rpc('add_agency', {
                p_name: agency.name,
                p_address: agency.address,
                p_postcode: agency.postcode,
                p_city: agency.city,
                p_country: agency.country,
                p_phone: agency.phone,
                p_website: agency.website,
                p_email: agency.email,
                p_logo: agency.logo
            });

        if (error) {
            throw new Error("Failed to add agency, because: " + JSON.stringify(error));
        }

        return data as Agency[];
    }


    public async editAgency() {
        return {};
    }
}
