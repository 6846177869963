import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BookingSummary, Performer} from "../../../../common/model/model";
import {BookingService} from "../../../../services/booking.service";
import {Router} from "@angular/router";

@Component({
    selector: 'performerbookings-info-component',
    templateUrl: './performerbookings-info.component.html',
    styleUrls: ['./performerbookings-info.component.scss']
})
export class PerformerBookingsInfoComponent implements OnInit, OnChanges {

    @Input() performer: Performer;
    public bookingSummaries: BookingSummary[] = [];
    public isLoading = false;

    constructor(private bookingService: BookingService,
                private router: Router) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['performer'] && changes['performer'].currentValue) {
            this.getPerformerBookings();
        }
    }

    public getPerformerBookings() {
        this.bookingService.getPerformerBookings(this.performer.id).then(bookings => {
            this.bookingSummaries = bookings;
        });
    }

    public openBookingDetails(bookingSummary: BookingSummary) {
        this.router.navigate(['booking-details', bookingSummary.booking_id]);
    }
}
