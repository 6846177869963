import {createClient, SupabaseClient} from "@supabase/supabase-js";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class SupabaseService {
    private supabaseURL = 'https://isfleysyeezpairthere.supabase.co';
    private supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlzZmxleXN5ZWV6cGFpcnRoZXJlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTE2NTQ5ODksImV4cCI6MjAyNzIzMDk4OX0.H_V3QXjEUj8zNQPnkxjCBw8Lr7zXjpYgfRFEPXKxoyw';
    public supabaseClient: SupabaseClient;

    constructor() {
        this.supabaseClient = createClient(this.supabaseURL, this.supabaseKey);
    }
}
