<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add Performer Products</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form>
        <div class="members-list-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="fw-semibold">Product</th>
                    <th class="fw-semibold">Performer</th>
                    <th class="fw-semibold">Product Price</th>
                    <th class="fw-semibold">Product Cost</th>

                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let performerProductWithPerformer of performerProductsWithPerformer">
                    <td>
                        <div class="user-info d-flex align-items-center">
                            <mat-checkbox class="fm-checkbox"
                                          (change)="onSelectionChange(performerProductWithPerformer, $event.checked)"
                                          [checked]="isSelected(performerProductWithPerformer)">

                            </mat-checkbox>
                            <img src="{{performerProductWithPerformer.performer_product.picture}}" class="rounded-circle" alt="user">
                            <div class="performer_product_title">
                                <h6 class="fw-semibold mb-5">{{ performerProductWithPerformer.performer_product.name }}</h6>
                            </div>
                        </div>
                    </td>
                    <td class="fw-semibold">
                        {{ performerProductWithPerformer.performer.name }}
                    </td>
                    <td class="fw-semibold">
                        {{ performerProductWithPerformer.performer_product.price }} {{performerProductWithPerformer.performer_product.currency_code}}
                    </td>
                    <td class="fw-semibold">
                        {{ performerProductWithPerformer.performer_product.cost }} {{performerProductWithPerformer.performer_product.currency_code}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <br>
        <br>
        <div class="text-end">
            <button mat-flat-button class="gray" (click)="close()">Cancel</button>

            <button mat-flat-button class="phobos" (click)="addPerformerProducts()">Add Performer Product(s)</button>
        </div>
    </form>
</div>
