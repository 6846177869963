import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Guest, BookingDetails} from "../../../../common/model/model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'add-guest-dialog-component',
    templateUrl: './add-guest-dialog.component.html',
    styleUrls: ['./add-guest-dialog.component.scss']
})
export class AddGuestDialogComponent implements OnInit {
    public addGuestFormGroup: FormGroup;
    public addGuestSubmitted = false;

    constructor(public dialogRef: MatDialogRef<AddGuestDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                    bookingGuest?: Booking_Guest
                },
                private addGuestFormBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.enableAddGuestDialogForm();

        if(this.data.bookingGuest) {
            this.populateForm(this.data.bookingGuest);
        }
    }

    get addGuestFormGroupControls() {
        return this.addGuestFormGroup.controls;
    }

    public enableAddGuestDialogForm() {
        this.addGuestFormGroup = this.addGuestFormBuilder.group({
            guest_name: ['', Validators.required],
            guest_email: ['', Validators.required],
            guest_description: ['', Validators.required],
        });
    }

    private populateForm(guest: Booking_Guest) {
        this.addGuestFormGroup.patchValue({
            guest_name: guest.name,
            guest_email: guest.email,
            guest_description: guest.description
        });
    }

    public addGuest() {
        this.addGuestSubmitted = true
        if(this.addGuestFormGroup.invalid) {

        } else {
            let newBookingGuest: Booking_Guest = {
                id: this.data.bookingGuest?.id,
                booking_id: this.data.bookingDetails.booking.id,
                name: this.addGuestFormGroup.controls['guest_name'].value,
                email: this.addGuestFormGroup.controls['guest_email'].value,
                description: this.addGuestFormGroup.controls['guest_description'].value
            }

            this.dialogRef.close(newBookingGuest);
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
