import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {
    Agency,
    Category,
    Currency,
    Member,
    Performer,
    Performer_Categories,
    Performer_Product
} from "../../../common/model/model";
import {PerformerService} from "../../../services/performer.service";
import {CurrencyService} from "../../../services/currency.service";
import {CategoryService} from "../../../services/category.service";
import {NotificationService} from "../../../services/notification.service";
import {AgencyService} from "../../../services/agency.service";

@Component({
    selector: 'performerdetails-component',
    templateUrl: './performer-details.component.html',
    styleUrls: ['./performer-details.component.scss']
})
export class PerformerDetailsComponent implements OnInit {

    public performerId: string;
    public selectedTabIndex = 0;

    public performer: Performer;
    public performerProducts: Performer_Product[] = [];
    public members: Member[];
    public categories: Category[];
    public performer_categories: Performer_Categories[];
    public selectedCurrency: Currency;
    public allCategories: Category[] = [];
    public selectedAgency: Agency;

    constructor(private activatedRoute: ActivatedRoute,
                private performerService: PerformerService,
                private currencyService: CurrencyService,
                private categoryService: CategoryService,
                private notificationService: NotificationService,
                private agencyService: AgencyService,
                private router: Router) {
    }

    ngOnInit() {
        this.performerId = this.activatedRoute.snapshot.paramMap.get('performerId') || '';
        this.getPerformerDetails();
        this.listenToSelectedCurrency();
        this.getAllCategories();
        this.listenToSelectedAgency();
    }

    public onTabChange(index: number) {
        this.selectedTabIndex = index;
    }

    public listenToSelectedCurrency() {
        this.currencyService.selectedCurrency$.subscribe(currency => {
            if (currency) {
                this.selectedCurrency = currency;
            }
        });
    }

    public getAllCategories() {
        this.categoryService.getAllCategories().then((categories) => {
            this.allCategories = categories;
        }).catch((error) => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    public getPerformerDetails() {
        this.performerService.getPerformerDetails(this.performerId).then(result => {
            if (result) {
                this.performer = result.performer;
                this.performerProducts = result.products;
                this.members = result.members;
                this.categories = result.categories;
                this.performer_categories = result.performer_categories;
            }
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    private checkAgencyAndNavigate() {
        if (this.performer && this.selectedAgency) {
            if (this.performer.agency_id !== this.selectedAgency.id) {
                this.router.navigate(['/performers']);
            }
        }
    }

    private listenToSelectedAgency() {
        this.agencyService.selectedAgency$.subscribe(agency => {
            this.selectedAgency = agency;
            this.checkAgencyAndNavigate();
        });
    }
}
