import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {LocationService} from "../../../services/location.service";
import {AgencyService} from "../../../services/agency.service";
import {Location} from "../../../common/model/model";

@Component({
    selector: 'add-location',
    templateUrl: './add-location-dialog.component.html',
    styleUrls: ['./add-location-dialog.component.scss']
})
export class AddLocationDialogComponent implements OnInit {

    public addLocationFormGroup: FormGroup;
    public addLocationSubmitted = false;
    public logo: File[] = [];

    constructor(public dialogRef: MatDialogRef<AddLocationDialogComponent>,
                private addLocationFormBuilder: FormBuilder,
                private locationService: LocationService,
                private agencyService: AgencyService) {
    }

    ngOnInit(): void {
        this.enableAddLocationDialogForm();
    }

    get addLocationFormGroupControls() {
        return this.addLocationFormGroup.controls;
    }

    public enableAddLocationDialogForm() {
        this.addLocationFormGroup = this.addLocationFormBuilder.group({
            name: ['', Validators.required],
            address: ['',Validators.required],
            postcode: ['',Validators.required],
            city: ['',Validators.required],
            country: ['',Validators.required],
            phone: ['',Validators.required],
            occupation: ['',Validators.required],
            website: ['',Validators.required],
            logo: ['',Validators.required]
        });
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];

        if (event.addedFiles.length > 0) {
            this.logo.push(event.addedFiles[0]);
        }

        this.addLocationFormGroup.controls['logo'].setValue(this.logo[0]);
    }

    public saveLocation() {
        this.addLocationSubmitted = true;
        if(this.addLocationFormGroup.invalid) {
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(this.logo[0]);
            reader.onload = () => {
                if(reader.result) {
                    let location: Location = {
                        name: this.addLocationFormGroup.controls['name'].value,
                        address: this.addLocationFormGroup.controls['address'].value,
                        postcode: this.addLocationFormGroup.controls['postcode'].value,
                        city: this.addLocationFormGroup.controls['city'].value,
                        country: this.addLocationFormGroup.controls['country'].value,
                        phone: this.addLocationFormGroup.controls['phone'].value,
                        occupation: this.addLocationFormGroup.controls['occupation'].value,
                        website: this.addLocationFormGroup.controls['website'].value,
                        logo: reader.result.toString(),
                        agency_id: this.agencyService.getSelectedAgency().id
                    }

                    this.locationService.addLocation(location).then(location => {
                        this.dialogRef.close(location[0]);
                    }).catch(error => {
                        //TODO handle error if it doesn't go well
                        console.error('Failed to add location:', error.message);
                    });
                }
            }
        }
    }
}

