import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {BookingDetails, Performer} from "../../../../common/model/model";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {CalendarOptions} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth'
import {FullCalendarComponent} from "@fullcalendar/angular";
import tippy from 'tippy.js';
import {SearchService} from "../../../../services/search.service";
import {Router} from "@angular/router";


@Component({
    selector: 'bookings-calendar-component',
    templateUrl: 'bookings-calendar.component.html',
    styleUrls: ['bookings-calendar.component.scss']
})
export class BookingsCalendarComponent implements OnInit, OnDestroy {

    @Input() bookingsDetails: Observable<BookingDetails[]>;
    private bookingsDetailsSubscription: Subscription;

    @ViewChild('bookingCalendar', {static: false}) calendarComponent: FullCalendarComponent;

    public calendarOptions: CalendarOptions = {
        initialView: 'dayGridMonth',
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        events: [],
        plugins: [dayGridPlugin,interactionPlugin, timeGridPlugin, listPlugin, multiMonthPlugin],
        eventClick: this.handleEventClick.bind(this),
        eventDidMount: this.handleEventDidMount.bind(this)
    };

    constructor(public themeService: CustomizerSettingsService,
                private cdr: ChangeDetectorRef,
                private searchService: SearchService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscribeToEvents();
        //this.subscribeToSearchNew();
    }

    ngOnDestroy(): void {
        if (this.bookingsDetailsSubscription) {
            this.bookingsDetailsSubscription.unsubscribe();
        }
    }

    public subscribeToEvents() {
        this.bookingsDetailsSubscription = this.bookingsDetails
            .subscribe((bookings: BookingDetails[]) => {
                const events = bookings.map((detail: BookingDetails) => ({
                    id: detail.booking.id,
                    title: detail.booking.event_name,
                    name: detail.booking.event_name,
                    date: detail.booking.event_date,
                    extendedProps: {
                        booking: detail.booking,
                        contacts: detail.contacts,
                        attachments: detail.attachments,
                        performers: detail.performers,
                        products: detail.products,
                        performer_details: detail.performer_details,
                        location: detail.location,
                        customer: detail.customer
                    }
                    //textColor: 'black',
                }));
                //this.updateCalendarEvents(events);
                this.calendarOptions.events = events;
                this.calendarOptions = { ...this.calendarOptions, events };
        });
    }

    public handleEventDidMount(info: any) {
        tippy(info.el, {
            content: `<strong>Event: ${info.event.title}</strong>` +
                `<br>Location: ${info.event.extendedProps.location.name}` +
                `<br>Customer: ${info.event.extendedProps.customer.name}` +
                `<br>Performers: ${info.event.extendedProps.performer_details?.map((p: Performer) => p.name).join(', ') || 'No performers'}`,
            allowHTML: true
        });
    }

    handleEventClick(arg: any) {
        let bookingDetails: BookingDetails = arg.event.extendedProps as BookingDetails;
        this.router.navigate(['booking-details', bookingDetails.booking.id]);
    }
}
