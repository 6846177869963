import {Injectable} from "@angular/core";
import {Agency, Location} from "../common/model/model";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllLocations(agency: Agency): Promise<Location[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.LOCATIONS)
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to retrieve locations, because: " + error.message);
        }

        return data as Location[];
    }

    public async addLocation(location: Location): Promise<Location[]> {
        const { data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.LOCATIONS)
            .insert(location)
            .select();

        if(error) {
            throw new Error("Failed to add location, because: " + error.message);
        }

        return data as Location[];
    }

    public async editLocation(location: Location): Promise<Location> {
        return location;
    }

    public retrieveLocationFromGoogleMaps() {

    }
}
