import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Agency, Agency_External} from "../common/model/model";
import {TABLE} from "../common/model/tables";

@Injectable({
    providedIn: 'root'
})
export class AgencyExternalService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllAgenciesExternal(agency: Agency) {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.AGENCIES_EXTERNALS)
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to retrieve external agencies, because: " + error.message);
        }
        return data as Agency_External[];
    }

    public async addAgencyExternal(agencyExternal: Agency_External): Promise<Agency_External> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.AGENCIES_EXTERNALS)
            .insert(agencyExternal)
            .select();

        if(error) {
            throw new Error("Failed to add external agency, because: " + error.message);
        }

        return data[0] as Agency_External;
    }
}
