import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export const externalAgencyRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const exclusiveArtist = formGroup.get('exclusiveArtist')?.value;
    const externalAgency = formGroup.get('externalAgency')?.value;

    // If exclusiveArtist is false, externalAgency must be selected
    if (exclusiveArtist === false && !externalAgency) {
        return { externalAgencyRequired: true };
    }
    return null;
};
