import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Agency, Booking, Booking_Attachment} from "../common/model/model";

@Injectable({
    providedIn: 'root'
})
export class AttachmentService {

    public bookingAttachments: Booking_Attachment[] = [];

    constructor(private supabaseService: SupabaseService) {
    }

    public async uploadBookingAttachments(files: File[], booking: Booking, agency: Agency): Promise<Booking_Attachment[]> {
        const bookingAttachments = await Promise.all(files.map(async file => {

            const filePath = `${agency.id}/${booking.id}/${file.name}`;

            const { data, error } = await this.supabaseService.supabaseClient
                .storage
                .from('booking_attachments')
                .upload(filePath, file, {upsert: true});
            if (error) {
                throw new Error("Failed to upload file, because: " + error.message);
            }

            // Create a Booking_Attachments object with the URL of the uploaded file
            const bookingAttachment: Booking_Attachment = {
                file_key: data.path,
                file_name: file.name,
                mime_type: file.type,
                uploaded_date: new Date()
            };
            return bookingAttachment;
        }));

        this.bookingAttachments.push(...bookingAttachments);
        return this.bookingAttachments;
    }

    public async deleteFileFromBookingAttachments() {

    }
}
