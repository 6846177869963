<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Products</h5>
            <a mat-stroked-button class="default-btn phobos"(click)="openAddBookingProductDialog('300ms', '100ms')">
                <i class="ri-add-fill"></i>Add Product
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.products.length < 1">No products added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.products.length >= 1">
                    <thead>
                    <tr>
                        <th class="fw-semibold main-color">Name</th>
                        <th class="fw-semibold main-color">Description</th>
                        <th class="fw-semibold main-color">Category</th>
                        <th class="fw-semibold main-color">Sub-Category</th>
                        <th class="fw-semibold main-color">Price</th>
                        <th class="fw-semibold main-color">Cost</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let product of bookingDetails.products">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/product.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{product.name}}</h5>
                            </div>
                        </td>
                        <td>{{product.description}}</td>
                        <td>{{product.category}}</td>
                        <td>{{product.subcategory}}</td>
                        <td>{{selectedCurrency.symbol}} {{product.price}} {{selectedCurrency.currency_code}}</td>
                        <td>{{selectedCurrency.symbol}} {{product.cost}} {{selectedCurrency.currency_code}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="productsActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #productsActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editBookingProduct(product, '300ms', '100ms')">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="deleteBookingProduct(product)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

</div>
