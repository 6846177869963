<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <full-calendar #bookingCalendar id="bookingCalendar" [options]="calendarOptions" class="cursor-pointer">
        </full-calendar>
    </mat-card-content>
</mat-card>
