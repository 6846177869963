import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {BookingDetails, BookingStatus} from "../../../../common/model/model";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {MatPaginator} from "@angular/material/paginator";
import {Observable, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
    selector: 'bookings-list-component',
    templateUrl: 'bookings-list.component.html',
    styleUrls: ['bookings-list.component.scss']
})
export class BookingsListComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() bookingsDetails: Observable<BookingDetails[]>;
    private bookingsDetailsSubscription: Subscription;

    dataSource: MatTableDataSource<BookingDetails>;
    displayedColumns: string[] = ['name', 'date', 'performers', 'customer', 'location', 'status', 'actions'];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public themeService: CustomizerSettingsService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<BookingDetails>([]);
        this.bookingsDetailsSubscription = this.bookingsDetails.subscribe(data => {
            this.dataSource.data = data;
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.bookingsDetailsSubscription.unsubscribe();
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public openBookingDetails(allBookingDetails: BookingDetails) {
        this.router.navigate(['booking-details', allBookingDetails.booking.id]);
    }

    protected readonly BookingStatus = BookingStatus;
}
