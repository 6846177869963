import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Agency, Invoice} from "../common/model/model";
import {TABLE} from "../common/model/tables";

@Injectable()
export class InvoiceService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllInvoices(agency: Agency): Promise<Invoice[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from('invoices')
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to get invoices, because: " + error.message);
        }
        return data as Invoice[];
    }

    public async addInvoice(invoice: Invoice): Promise<Invoice[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from('invoices')
            .insert(invoice)
            .select('*');

        if(error) {
            throw new Error("Failed to add invoice, because: " + error.message);
        }
        return data as Invoice[];
    }

    public async deleteInvoice(invoice: Invoice): Promise<Invoice[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.INVOICES)
            .delete()
            .eq('id', invoice.id)
            .select('*');

        if(error) {
            throw new Error("Failed to delete invoice, because: " + error.message);
        }
        return data as Invoice[];
    }

    public async updateInvoice(invoice: Invoice): Promise<Invoice> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.INVOICES)
            .update(invoice)
            .eq('id', invoice.id)
            .select()
            .single();

        if (error) {
            throw new Error("Failed to update invoice, because: " + error.message);
        }
        return data as Invoice;
    }

    public async getInvoiceStatuses() {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.INVOICE_STATUSES)
            .select('*');

        if(error) {
            throw new Error("Failed to get invoice statuses, because: " + error.message);
        }
        return data.map((row: { status: string }) => row.status);
    }


}
