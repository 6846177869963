import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Customer} from "../../../common/model/model";
import {CustomerService} from "../../../services/customer.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'add-customer',
    templateUrl: 'add-customer-dialog.component.html',
    styleUrls: ['add-customer-dialog.component.scss']
})
export class AddCustomerDialogComponent implements OnInit {

    public addCustomerFormGroup: FormGroup;
    public addCustomerSubmitted = false;
    public logo: File[] = [];
    public isLoading = false;

    constructor(public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
                private addCustomerFormBuilder: FormBuilder,
                private customerService: CustomerService,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.enableAddCustomerDialogForm();
    }

    get addCustomerFormGroupControls() {
        return this.addCustomerFormGroup.controls;
    }

    public enableAddCustomerDialogForm() {
        this.addCustomerFormGroup = this.addCustomerFormBuilder.group({
            name: ['', Validators.required],
            email: ['',Validators.required],
            phone: ['', Validators.required],
            address: ['',Validators.required],
            postcode: ['',Validators.required],
            city: ['',Validators.required],
            country: ['',Validators.required],
            website: ['',Validators.required],
            vat_number: ['',Validators.required],
            bank_account: ['',Validators.required],
            logo: ['',Validators.required]
        });
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];

        if (event.addedFiles.length > 0) {
            this.logo.push(event.addedFiles[0]);
        }

        this.addCustomerFormGroup.controls['logo'].setValue(this.logo[0]);
    }

    public saveCustomer() {
        this.addCustomerSubmitted = true;
        if(this.addCustomerFormGroup.invalid) {
        } else {
            this.isLoading = true;
            const reader = new FileReader();
            reader.readAsDataURL(this.logo[0]);
            reader.onload = () => {
                if(reader.result) {
                    let customer: Customer = {
                        name: this.addCustomerFormGroup.controls['name'].value,
                        email: this.addCustomerFormGroup.controls['email'].value,
                        phone: this.addCustomerFormGroup.controls['phone'].value,
                        address: this.addCustomerFormGroup.controls['address'].value,
                        postcode: this.addCustomerFormGroup.controls['postcode'].value,
                        city: this.addCustomerFormGroup.controls['city'].value,
                        country: this.addCustomerFormGroup.controls['country'].value,
                        website: this.addCustomerFormGroup.controls['website'].value,
                        vat_number: this.addCustomerFormGroup.controls['vat_number'].value,
                        bank_account: this.addCustomerFormGroup.controls['bank_account'].value,
                        logo: reader.result.toString(),
                        agency_id: this.data
                    }

                    this.customerService.addCustomer(customer).then(customer => {
                        this.notificationService.openSuccessfulNotification("Successfully added Customer: " + customer[0].name);
                        this.dialogRef.close(customer[0]);
                    }).catch(error => {
                        this.notificationService.openFailureNotitication("Failed to add customer, because: " + error.message);
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            }
        }
    }
}
