import {Component, Input, OnInit} from "@angular/core";
import {Booking_Guest, BookingDetails} from "../../../../common/model/model";
import {MatDialog} from "@angular/material/dialog";
import {AddGuestDialogComponent} from "../add-guest/add-guest-dialog.component";
import {BookingService} from "../../../../services/booking.service";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'guest-info',
    templateUrl: './guest-info.components.html',
    styleUrls: ['./guest-info.components.scss']
})
export class GuestInfoComponents implements OnInit {

    @Input() bookingDetails: BookingDetails;

    constructor(private matDialog: MatDialog,
                private bookingService: BookingService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
    }
    
    public openAddGuestDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addGuestDialogRef = this.matDialog.open(AddGuestDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails
            }
        });

        addGuestDialogRef.afterClosed().subscribe((result: Booking_Guest) => {
            if(result != null && typeof result === 'object') {
                this.bookingService.addBookingGuest(result).then(bookingGuests => {
                    this.notificationService.openSuccessfulNotification('Guest ' + bookingGuests[0].name + ' added successfully');
                    this.bookingDetails.booking_guests.push(bookingGuests[0]);
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }

    public openEditGuestDialog(enterAnimationDuration: string, exitAnimationDuration: string, guest: Booking_Guest) {
        const editGuestDialogRef = this.matDialog.open(AddGuestDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails,
                bookingGuest: guest
            }
        });

        editGuestDialogRef.afterClosed().subscribe((result: Booking_Guest) => {
            if(result != null && typeof result === 'object') {
                this.bookingService.updateBookingGuest(result).then(bookingGuests => {
                    this.notificationService.openSuccessfulNotification('Guest ' + bookingGuests[0].name + ' updated successfully');
                    this.bookingDetails.booking_guests = this.bookingDetails.booking_guests.map(bookingGuest => {
                        if(bookingGuest.id === bookingGuests[0].id) {
                            return bookingGuests[0];
                        } else {
                            return bookingGuest;
                        }
                    });
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }



    public removeGuest(guest: Booking_Guest) {
        this.bookingService.deleteBookingGuest(guest).then(() => {
            this.bookingDetails.booking_guests = this.bookingDetails.booking_guests.filter(bookingGuest => bookingGuest.id !== guest.id);
            this.notificationService.openSuccessfulNotification('Guest ' + guest.name + ' removed successfully');
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }
}
