import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BookingDetails, Invoice} from "../../../../common/model/model";
import {InvoiceService} from "../../../../services/invoice.service";

@Component({
    selector: 'add-invoice-dialod-component',
    templateUrl: './add-invoice-dialog.component.html',
    styleUrls: ['./add-invoice-dialog.component.scss']
})
export class AddInvoiceDialogComponent implements OnInit{

    public addInvoiceFormGroup: FormGroup;
    public addInvoiceSubmitted = false;

    public invoiceStatuses: string[] = [];
    public selectedInvoiceStatus: string;

    constructor(public dialogRef: MatDialogRef<AddInvoiceDialogComponent>,
                private addInvoiceFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                    financialsTotalWithVAT: number
                    invoice?: Invoice
                },
                private invoiceService: InvoiceService) {
    }

    ngOnInit() {
        this.enableAddInvoiceDialogForm();
        this.getInvoiceStatus();

        if(this.data.invoice) {
            this.populateForm(this.data.invoice);
        }
    }

    get addInvoiceFormGroupControls() {
        return this.addInvoiceFormGroup.controls;
    }

    private populateForm(invoice: Invoice) {
        this.addInvoiceFormGroup.patchValue({
            invoice_date: invoice.invoice_date,
            invoice_due_date: invoice.invoice_due_date,
            invoice_description: invoice.invoice_description,
            invoice_status: invoice.invoice_status
        });
    }

    public enableAddInvoiceDialogForm() {
        this.addInvoiceFormGroup = this.addInvoiceFormBuilder.group({
            invoice_date: ['', Validators.required],
            invoice_due_date: ['', Validators.required],
            invoice_description: ['', Validators.required],
            invoice_status: ['', Validators.required],
        });
    }

    public getInvoiceStatus() {
        this.invoiceService.getInvoiceStatuses().then(statuses => {
            this.invoiceStatuses = statuses;
        });
    }

    public generateInvoice() {
        this.addInvoiceSubmitted = true;
        if (this.addInvoiceFormGroup.invalid) {
        } else {
            let invoice: Invoice = {
                booking_id: this.data.bookingDetails.booking.id,
                agency_id: this.data.bookingDetails.booking.agency_id,
                customer_id: this.data.bookingDetails.booking.customer_id,
                invoice_date: this.addInvoiceFormGroup.controls['invoice_date'].value,
                invoice_due_date: this.addInvoiceFormGroup.controls['invoice_due_date'].value,
                invoice_description: this.addInvoiceFormGroup.controls['invoice_description'].value,
                invoice_status: this.addInvoiceFormGroup.controls['invoice_status'].value,
                invoice_amount: this.data.financialsTotalWithVAT
            };

            if (this.data.invoice) {
                // If we're editing, include the id and use updateInvoice
                invoice.id = this.data.invoice.id;
                invoice.invoice_nr = this.data.invoice.invoice_nr;
                this.invoiceService.updateInvoice(invoice).then(updatedInvoice => {
                    this.dialogRef.close(updatedInvoice);
                });
            } else {
                this.invoiceService.addInvoice(invoice).then(addedInvoice => {
                    this.dialogRef.close(addedInvoice[0]);
                });
            }
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
