import {Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CategoryService} from "../../../services/category.service";
import {Agency, Category} from "../../../common/model/model";
import {AgencyService} from "../../../services/agency.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'add-category',
    templateUrl: './add-category-dialog.component.html',
    styleUrls: ['./add-category-dialog.component.scss']
})
export class AddCategoryDialogComponent implements OnInit {

    public addCategoryFormGroup: FormGroup;
    public addCategorySubmitted = false;
    public selectedAgency: Agency;

    constructor(public dialogRef: MatDialogRef<AddCategoryDialogComponent>,
                private addCategoryFormBuilder: FormBuilder,
                private categoryService: CategoryService,
                private agencyService: AgencyService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.enableAddCategoryDialogForm();
        this.agencyService.selectedAgency$.subscribe(agency => {
           this.selectedAgency = agency;
        });
    }

    get addCategoryFormGroupControls() {
        return this.addCategoryFormGroup.controls;
    }

    public enableAddCategoryDialogForm() {
        this.addCategoryFormGroup = this.addCategoryFormBuilder.group({
            name: ['',Validators.required]
        })
    }

    public close(){
        this.dialogRef.close(true);
    }

    public saveCategory() {
        this.addCategorySubmitted = true;
        if(this.addCategoryFormGroup.invalid) {
        } else {

            if(this.selectedAgency && this.selectedAgency.id) {
                let category: Category = {
                    name: this.addCategoryFormGroup.controls['name'].value,
                    agency_id: this.selectedAgency.id
                }

                this.categoryService.addCategory(category).then(category => {
                    this.dialogRef.close(category[0]);
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        }
    }
}
