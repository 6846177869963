import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Agency, Customer} from "../common/model/model";
import {TABLE} from "../common/model/tables";

@Injectable()
export class CustomerService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllCustomers(agency: Agency): Promise<Customer[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.CUSTOMERS)
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to retrieve customers, because: " + error.message);
        }

        return data as Customer[];
    }

    public async addCustomer(customer: Customer): Promise<Customer[]> {
        const { data, error } = await this.supabaseService.supabaseClient
            .from(TABLE.CUSTOMERS)
            .insert(customer)
            .select();

        if(error) {
            throw new Error("Failed to add customer, because: " + error.message);
        }

        return data as Customer[];
    }
}
