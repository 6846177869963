<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Bookings</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Bookings</li>
    </ol>
</div>

<mat-card
    class="mb-25 enrolled-created-box phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <div class="d-flex justify-content-between align-items-center">
            <mat-tab-group (selectedIndexChange)="onTabChange($event)" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="ps-0 mt-0">
                <mat-tab label="List View"></mat-tab>
                <mat-tab label="Calendar View"></mat-tab>
            </mat-tab-group>
            <a mat-flat-button class="default-btn phobos" (click)="addBooking()" style="position: relative" *ngIf="selectedAgency">
                <i class="ri-add-fill"></i> + Add Booking
            </a>
<!--            <a mat-flat-button class="default-btn phobos" (click)="testFunctions()" style="position: relative" *ngIf="selectedAgency">-->
<!--                <i class="ri-add-fill"></i> + Test Email-->
<!--            </a>-->
<!--            <a mat-flat-button class="default-btn phobos" (click)="testGreeting()" style="position: relative" *ngIf="selectedAgency">-->
<!--                <i class="ri-add-fill"></i> + Test Greeting-->
<!--            </a>-->
        </div>
    </mat-card-content>
</mat-card>

<div *ngIf="selectedTabIndex === 0">
    <bookings-list-component
        [bookingsDetails]="bookingDetails.asObservable()"
    >
    </bookings-list-component>
</div>
<div *ngIf="selectedTabIndex === 1">
    <bookings-calendar-component
        [bookingsDetails]="bookingDetails.asObservable()"
    >
    </bookings-calendar-component>
</div>
