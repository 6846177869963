import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject, Injectable} from "@angular/core";
import {SupabaseAuthService} from "../services/supabase.auth.service";
import {filter, Observable, switchMap, take} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class AuthGuardService {

    constructor(private router: Router, private supabaseAuthService: SupabaseAuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.supabaseAuthService.initialLoadComplete$.pipe(
            filter(isInitialLoadComplete => isInitialLoadComplete), // Wait for the initial load to complete.
            take(1), // Take the first emission after load completion.
            switchMap(() => this.supabaseAuthService.getCurrentUser().pipe(
                map(user => {
                    if(user) {
                        if(state.url === '/' || state.url === '/login' || state.url === '/register'
                            || state.url === '/confirm-email') {
                            this.router.navigate(['/analytics']);
                            return false;
                        }
                        return true;
                    } else {
                        if(state.url !== '/' && state.url !== '/register'
                            && state.url !== '/forgot-password' && state.url !== '/confirm-email') {
                            this.router.navigate(['/']);
                            return false;
                        }
                        return true;
                    }
                })
            ))
        );
    }
}

//Use this AuthGuard to make sure that a route is protected; add this to the routes in app-routing.module.ts
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(AuthGuardService).canActivate(next,state);
}
