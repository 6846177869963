import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Performer, Booking_Performer_Flight} from "../../../../common/model/model";
import {MatSelectChange} from "@angular/material/select";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'add-performerflight',
    templateUrl: 'add-performer-flight-component-dialog.component.html',
    styleUrls: ['add-performer-flight-component-dialog.component.scss']
})
export class AddPerformerFlightComponentDialog implements OnInit {

    public addPerformerFlightFormGroup: FormGroup;
    public addPerformerFlightSubmitted = false;
    public bookingPerformers: Booking_Performer[] = [];
    public selectedPerformer: Booking_Performer | null = null;


    constructor(public dialogRef: MatDialogRef<AddPerformerFlightComponentDialog>,
                private addPerformerFlightFormBuilder: FormBuilder,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    performers: Booking_Performer[],
                    bookingPerformerFlight?: Booking_Performer_Flight
                }) {
        this.bookingPerformers = data.performers;
    }

    ngOnInit() {
        this.enableAddPerformerFlightDialogForm();

        if(this.data.bookingPerformerFlight) {
            this.populateForm(this.data.bookingPerformerFlight);
        }
    }

    get addPerformerFlightFormGroupControls() {
        return this.addPerformerFlightFormGroup.controls;
    }

    public enableAddPerformerFlightDialogForm() {
        this.addPerformerFlightFormGroup = this.addPerformerFlightFormBuilder.group({
            performer: ['',Validators.required],
            description: ['',Validators.required],
            flightnumber: ['', Validators.required],
            fromdeparture: ['',Validators.required],
            todestination: ['',Validators.required],
            flightdate: ['',Validators.required],
            flighttime: ['',Validators.required]
        });
    }

    public populateForm(bookingPerformerFlight: Booking_Performer_Flight) {
        // Find the matching performer object from the bookingPerformers array
        const selectedPerformer = this.bookingPerformers.find(
            performer => performer.performer_id === bookingPerformerFlight.performer_id
        );

        if (selectedPerformer) {
            this.addPerformerFlightFormGroup.patchValue({
                performer: selectedPerformer,
                description: bookingPerformerFlight.description,
                flightnumber: bookingPerformerFlight.flight_number,
                fromdeparture: bookingPerformerFlight.from_departure,
                todestination: bookingPerformerFlight.to_destination,
                flightdate: bookingPerformerFlight.flight_date,
                flighttime: bookingPerformerFlight.flight_time
            });

            // Update the selectedPerformer property
            this.selectedPerformer = selectedPerformer;
        } else {
            this.notificationService.openFailureNotitication('No matching performer found for this performer');
        }
    }

    public comparePerformers(performer1: Booking_Performer, performer2: Booking_Performer): boolean {
        return performer1 && performer2 ? performer1.performer_id === performer2.performer_id : performer1 === performer2;
    }

    public onPerformerSelected(event: MatSelectChange) {
        this.selectedPerformer = event.value;
    }

    public addPerformerFlight() {
        this.addPerformerFlightSubmitted = true;

        if (this.addPerformerFlightFormGroup.invalid) {
        } else {

            if (!this.selectedPerformer || !this.selectedPerformer.performer_id) {
                throw new Error('Performer is not selected');
            }

            const bookingPerformerFlight: Booking_Performer_Flight = {
                id: this.data.bookingPerformerFlight?.id, // Include the id if it exists
                performer_id: this.selectedPerformer.performer_id,
                description: this.addPerformerFlightFormGroup.controls['description'].value,
                flight_number: this.addPerformerFlightFormGroup.controls['flightnumber'].value,
                from_departure: this.addPerformerFlightFormGroup.controls['fromdeparture'].value,
                to_destination: this.addPerformerFlightFormGroup.controls['todestination'].value,
                flight_date: this.addPerformerFlightFormGroup.controls['flightdate'].value,
                flight_time: this.addPerformerFlightFormGroup.controls['flighttime'].value
            }

            this.dialogRef.close(bookingPerformerFlight);
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
