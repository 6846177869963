import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {Currency, Performer_Product} from "../../../common/model/model";
import {CurrencyService} from "../../../services/currency.service";
import {Observable} from "rxjs";

@Component({
    selector: 'add-product',
    templateUrl: './add-product-dialog.component.html',
    styleUrls: ['./add-product-dialog.component.scss']
})
export class AddProductDialogComponent implements OnInit, OnChanges {
    public addProductFormGroup: FormGroup;
    public addProductSubmitted = false;
    public picture: File[] = [];
    public selectedCurrency: Currency;
    public existingPicture: string | null = null;

    constructor(public dialogRef: MatDialogRef<AddProductDialogComponent>,
                private addProductFormBuilder: FormBuilder,
                private currencyService: CurrencyService,
                @Inject(MAT_DIALOG_DATA) public data: Currency) {
    }

    ngOnInit(): void {
        this.enableAddProductDialogForm();
        this.loadExistingData();
        //this.listenToSelectedCurrency();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedCurrency'] && changes['selectedCurrency'].currentValue) {
            console.log('tester');
            this.selectedCurrency = this.currencyService.getSelectedCurrency();
        }
    }

    get addProductFormGroupControls() {
        return this.addProductFormGroup.controls;
    }

    public enableAddProductDialogForm() {
        this.addProductFormGroup = this.addProductFormBuilder.group({
            id: [''],
            name: ['', Validators.required],
            description: ['', Validators.required],
            price: ['', Validators.required],
            cost: ['', Validators.required],
            picture: ['', Validators.required]
        });
    }

    private loadExistingData() {
        if (this.data && isPerformerProduct(this.data)) {
            console.log('received data: ' + JSON.stringify(this.data));
            this.addProductFormGroup.patchValue({
                id: this.data.id,
                name: this.data.name || '',
                description: this.data.description || '',
                price: this.data.price || '',
                cost: this.data.cost || '',
            });

            if (this.data.picture) {
                this.existingPicture = this.data.picture;
                this.addProductFormGroup.patchValue({
                    picture: this.existingPicture
                });
            }
        }
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event: any) {
        this.picture = [];
        this.existingPicture = null;

        if (event.addedFiles.length > 0) {
            this.picture.push(event.addedFiles[0]);
        }
        this.addProductFormGroup.controls['picture'].setValue(this.picture[0]);
    }

    public onRemove() {
        this.existingPicture = null;
        this.addProductFormGroup.controls['picture'].setValue(null);
    }

    public listenToSelectedCurrency() {
        this.currencyService.selectedCurrency$.subscribe(currency => {
            if (currency) {
                this.selectedCurrency = currency;
            }
        });
    }

    public saveProduct() {
        this.addProductSubmitted = true;
        if(this.addProductFormGroup.invalid) {
            return;
        }

        if (this.existingPicture) {
            this.saveProductWithPicture(this.existingPicture);
        } else if (this.picture[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                if(reader.result) {
                    this.saveProductWithPicture(reader.result.toString());
                }
            }
            reader.readAsDataURL(this.picture[0]);
        }
    }

    private saveProductWithPicture(pictureData: string) {
        let performerProduct: Performer_Product = {
            id: this.addProductFormGroup.get('id')?.value || undefined,
            name: this.addProductFormGroup.get('name')?.value,
            description: this.addProductFormGroup.get('description')?.value,
            price: this.addProductFormGroup.get('price')?.value,
            cost: this.addProductFormGroup.get('cost')?.value,
            currency_code: "usd",
            picture: pictureData
        };

        // Remove the id property if it's undefined
        if (performerProduct.id === undefined) {
            delete performerProduct.id;
        }

        this.dialogRef.close(performerProduct);
    }
}

function isPerformerProduct(obj: any): obj is Performer_Product {
    return obj &&
        typeof obj === 'object' &&
        'name' in obj &&
        'description' in obj &&
        'price' in obj &&
        'cost' in obj &&
        'currency_code' in obj &&
        'picture' in obj;
}
