<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">{{data.bookingGuest ? 'Update Guest' : 'Add Guest'}}</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addGuestFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Name</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guest_name'].errors && addGuestSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addGuestFormGroup.get('guest_name')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                    Guest Name
                                </mat-label>
                                <input matInput formControlName="guest_name">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">E-Mail</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guest_email'].errors && addGuestSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addGuestFormGroup.get('guest_email')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                    Guest E-Mail
                                </mat-label>
                                <input matInput formControlName="guest_email">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Description</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guest_description'].errors && addGuestSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addGuestFormGroup.get('guest_description')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                    Guest Description
                                </mat-label>
                                <input matInput formControlName="guest_description">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="text-end">
                    <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                    <button mat-flat-button class="phobos" (click)="addGuest()">
                        {{data.bookingGuest ? 'Update Guest' : 'Add Guest'}}
                    </button>
                </div>

            </div>
        </form>
    </mat-card>

</div>
