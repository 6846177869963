<div class="row">
    <div class="col-lg-8 col-md-12">
        <mat-card class="mb-25 phobos-card" [class.component-dark-theme]="themeService.isDark()"
                  [class.component-rtl-enabled]="themeService.isRTLEnabled()">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">General Performer Info</h5>
            </mat-card-header>
            <mat-card-content *ngIf="performer">
                <form [formGroup]="performerInfoFormGroup">
                    <div class="row mt-20">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Performer Name</label>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': performerInfoFormControls['name'].errors}">
                                    <i class="ri-book-line"></i>
                                    <input formControlName="name" matInput value="{{performer.name}}">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Performer E-Mail</label>
                                <mat-form-field appearance="fill">
                                    <i class="ri-book-line"></i>
                                    <input formControlName="email" matInput value="{{performer.email}}">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Performer Exclusivity</label>
                                <mat-slide-toggle formControlName="exclusiveArtist">Exclusive Artist</mat-slide-toggle>
                            </div>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="col-lg-12 col-md-12 col-sm-12 align-items-center" *ngIf="!performerExclusivity">
                            <div class="phobos-form-group flex-grow-1">
                                <label class="d-block mb-12 fw-semibold">External Agency</label>
                                <mat-form-field class="example-full-width" appearance="fill" [ngClass]="{'error-border': performerInfoFormGroup.hasError('externalAgencyRequired')}">
                                    <mat-label>External Agency</mat-label>
                                    <i class="ri-home-office-line"></i>
                                    <input matInput aria-label="State" [matAutocomplete]="auto" formControlName="externalAgency">
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onExternalAgencySelected($event)" [displayWith]="displayFn">
                                        <mat-option *ngFor="let externalAgency of filteredExternalAgencies | async" [value]="externalAgency">
                                            <span>{{externalAgency.name}} - {{externalAgency.city}}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="align-bottom">
                                <button mat-stroked-button class="phobos full-height-button" (click)="openAddExternalAgencyDialog('300ms','100ms')">Add External Agency</button>
                            </div>
                            <br>
                            <br>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Price ({{performer.currency_code}})</label>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': performerInfoFormControls['price'].errors}">
                                    <i class="ri-book-line"></i>
                                    <input formControlName="price" matInput value="{{performer.price}}">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Cost ({{performer.currency_code}})</label>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': performerInfoFormControls['cost'].errors}">
                                    <i class="ri-book-line"></i>
                                    <input formControlName="cost" matInput value="{{performer.cost}}">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold">Play Times</label>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': performerInfoFormControls['playtimes'].errors}">
                                    <i class="ri-price-tag-3-line"></i>
                                    <mat-label>Performer Play times</mat-label>
                                    <mat-chip-grid #chipGrid2 aria-label="Enter Play Times" formControlName="playtimes">
                                        <mat-chip-row *ngFor="let playtime of performer.playtimes"
                                                      (removed)="removePlayTime(playtime)"
                                                      [editable]="true"
                                                      (edited)="editPlaytime(playtime, $event)"
                                                      [aria-description]="'press enter to edit ' + playtime"
                                        >
                                            {{playtime}}
                                            <button matChipRemove [attr.aria-label]="'remove ' + playtime">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>
                                    </mat-chip-grid>
                                    <input matInput placeholder="New playtime..."
                                           [matChipInputFor]="chipGrid2"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="addPlayTime($event)"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="phobos-form-group">
                                <label class="d-block mb-12 fw-semibold ">Description</label>
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': performerInfoFormControls['description'].errors}">
                                    <i class="ri-pages-line"></i>
                                    <textarea formControlName="description" matInput rows="4" type="text" required value="{{performer.description}}">
                            </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Picture</label>
                            <mat-card class="mb-25 phobos-card">
                                <mat-card-content>
                                    <ngx-dropzone (change)="onSelect($event)" class="centered-dropzone">
                                        <ngx-dropzone-label class="fw-semibold" *ngIf="!picture.length && !existingPicture">
                                            Drop files here or click to upload.
                                        </ngx-dropzone-label>
                                        <div *ngIf="existingPicture" class="custom-preview">
                                            <img [src]="getSafeUrl(existingPicture)" style="max-width: 190px; max-height: 100%; object-fit: contain;">

                                        </div>
                                        <ngx-dropzone-image-preview
                                                *ngFor="let f of picture"
                                                [file]="f"
                                                [removable]="true"
                                                (removed)="onRemove()"
                                        >
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-image-preview>
                                    </ngx-dropzone>
                                </mat-card-content>
                                <div class="align-items-center" *ngIf="performerInfoFormControls['picture'].errors" style="color:red;font-size:small">
                                    <div *ngIf="performerInfoFormControls['picture'].errors?.['required']">Please add your product picture</div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-content>
                <button mat-raised-button class="phobos fw-semibold" style="margin-right: 5px" (click)="updatePerformerInfo()" [disabled]="updateDisabled">Update</button>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-12">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Category</h5>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <mat-form-field appearance="fill">
                        <i class="ri-price-tag-3-line"></i>
                        <mat-label>Categories</mat-label>
                        <mat-select multiple [(ngModel)]="selectedCategoryIds" [ngModelOptions]="{standalone: true}">
                            <mat-option *ngFor="let category of allCategories" [value]="category.id">{{category.name}}</mat-option>
                            <!-- Ensure new categories are reflected -->
<!--                            <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>-->
                        </mat-select>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <button mat-stroked-button (click)="openAddCategoryDialog('300ms','100ms')">Add New Category</button>
        </mat-card>
        <mat-card class="mb-25 phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Members</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-label *ngIf="members && members[0] == null ">No members addded yet</mat-label>
                <div class="recent-files-table table-responsive" *ngIf="members && members.length > 0 && members[0] !== null">
                    <table class="table" *ngIf="members && members.length > 0">
                        <thead >
                        <tr>
                            <th class="fw-semibold main-color">Name</th>
                            <th class="fw-semibold main-color">Role</th>
                            <th class="fw-semibold main-color">Phone</th>
                            <th class="fw-semibold main-color">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let member of members; let k = index">
                            <td>{{member.name}}</td>
                            <td>{{member.role}}</td>
                            <td>{{member.phone}}</td>
                            <td>
                                <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                     (click)="trigger.openMenu()"
                                     #trigger="matMenuTrigger"
                                     [matMenuTriggerFor]="memberActionMenu">
                                    <i class="ri-more-fill"></i>
                                </div>
                                <mat-menu #memberActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editMember(k, '300ms','100ms')">
                                        <i class="ri-edit-2-line"></i> Edit
                                    </button>
                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeMember(k)">
                                        <i class="ri-delete-bin-line"></i> Remove
                                    </button>
                                </mat-menu>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
            <br>
            <button mat-stroked-button (click)="openAddPerformerMemberDialog('300ms','100ms')">Add New Member</button>
        </mat-card>
    </div>
</div>


