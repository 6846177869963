import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class NotificationService {
    constructor(private toastrService: ToastrService) {
    }

    public openSuccessfulNotification(message: string) {
        this.toastrService.success(message, 'Success', {
            positionClass: 'toast-top-center',
            closeButton: true,
            timeOut: 3000
        });
    }

    public openFailureNotitication(message: string) {
        this.toastrService.error(message, 'Error', {
            positionClass: 'toast-top-center',
            closeButton: true
        });
    }

}
