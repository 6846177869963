<div
    class="row"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="col-lg-3 col-sm-6">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
                <h3>199</h3>
                <div class="icon">
                    <i class="ri-coupon-line"></i>
                </div>
            </div>
            <h4 class="fw-semibold">New Tickets</h4>
            <p>From Average Yesterday</p>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
                <h3>207</h3>
                <div class="icon">
                    <i class="ri-shape-2-line"></i>
                </div>
            </div>
            <h4 class="fw-semibold">Open Tickets</h4>
            <p>From Average Yesterday</p>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
                <h3>30</h3>
                <div class="icon">
                    <i class="ri-stack-line"></i>
                </div>
            </div>
            <h4 class="fw-semibold">On Hold</h4>
            <p>From Average Yesterday</p>
        </mat-card>
    </div>
    <div class="col-lg-3 col-sm-6">
        <mat-card class="single-stats-box phobos-card mb-25">
            <div class="d-flex align-items-center justify-content-space-between">
                <h3>150</h3>
                <div class="icon">
                    <i class="ri-apps-line"></i>
                </div>
            </div>
            <h4 class="fw-semibold">Unassigned</h4>
            <p>From Average Yesterday</p>
        </mat-card>
    </div>
</div>
