import {Component, OnDestroy, OnInit} from '@angular/core';
import {SupabaseAuthService} from "../../services/supabase.auth.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-ecommerce',
    templateUrl: './ecommerce.component.html',
    styleUrls: ['./ecommerce.component.scss']
})
export class EcommerceComponent implements OnInit, OnDestroy{

    private sessionSubscription: Subscription;

    constructor(private supabaseAuthService: SupabaseAuthService, private router: Router) {
    }

    ngOnInit(): void {
        this.sessionSubscription = this.supabaseAuthService.currentUser$.subscribe(user => {
            if(!user) {
                this.router.navigate(['/']);
            }
        });
    }

    ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
    }
}
