import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {MatMenuModule} from '@angular/material/menu';
import {FullCalendarModule} from '@fullcalendar/angular';
import {HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {NgxEditorModule} from 'ngx-editor';
import {MatCardModule} from '@angular/material/card';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgApexchartsModule} from "ng-apexcharts";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {NgxEchartsModule} from 'ngx-echarts';
import {NgxGaugeModule} from 'ngx-gauge';
import {NgChartsModule} from 'ng2-charts';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {QuillModule} from 'ngx-quill';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxDropzoneModule} from 'ngx-dropzone';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {EcommerceComponent} from './components/dashboard/ecommerce/ecommerce.component';
import {AnalyticsComponent} from './components/dashboard/analytics/analytics.component';
import {ProjectManagementComponent} from './components/dashboard/project-management/project-management.component';
import {LmsCoursesComponent} from './components/dashboard/lms-courses/lms-courses.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarComponent} from './components/common/sidebar/sidebar.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {HeaderComponent} from './components/common/header/header.component';
import {RecentOrdersComponent} from './components/dashboard/ecommerce/recent-orders/recent-orders.component';
import {TeamMembersListComponent} from './components/dashboard/ecommerce/team-members-list/team-members-list.component';
import {
    BestSellingProductsComponent
} from './components/dashboard/ecommerce/best-selling-products/best-selling-products.component';
import {EcommerceStatsComponent} from './components/dashboard/ecommerce/ecommerce-stats/ecommerce-stats.component';
import {
    AudienceOverviewComponent
} from './components/dashboard/ecommerce/audience-overview/audience-overview.component';
import {
    EcommerceRatingsComponent
} from './components/dashboard/ecommerce/ecommerce-ratings/ecommerce-ratings.component';
import {
    EcommerceImpressionsComponent
} from './components/dashboard/ecommerce/ecommerce-impressions/ecommerce-impressions.component';
import {VisitsByDayComponent} from './components/dashboard/ecommerce/visits-by-day/visits-by-day.component';
import {
    EcommerceActivityTimelineComponent
} from './components/dashboard/ecommerce/ecommerce-activity-timeline/ecommerce-activity-timeline.component';
import {
    SalesByLocationsComponent
} from './components/dashboard/ecommerce/sales-by-locations/sales-by-locations.component';
import {NewCustomersComponent} from './components/dashboard/ecommerce/new-customers/new-customers.component';
import {
    LiveVisitsOnOurSiteComponent
} from './components/dashboard/ecommerce/live-visits-on-our-site/live-visits-on-our-site.component';
import {RevenueStatusComponent} from './components/dashboard/ecommerce/revenue-status/revenue-status.component';
import {LmsStatsComponent} from './components/dashboard/lms-courses/lms-stats/lms-stats.component';
import {LmsExperienceComponent} from './components/dashboard/lms-courses/lms-experience/lms-experience.component';
import {LmsProgressComponent} from './components/dashboard/lms-courses/lms-progress/lms-progress.component';
import {LmsHoursSpentComponent} from './components/dashboard/lms-courses/lms-hours-spent/lms-hours-spent.component';
import {LmsPlanningComponent} from './components/dashboard/lms-courses/lms-planning/lms-planning.component';
import {
    LmsActiveCourseComponent
} from './components/dashboard/lms-courses/lms-active-course/lms-active-course.component';
import {
    LmsCourseCompletionComponent
} from './components/dashboard/lms-courses/lms-course-completion/lms-course-completion.component';
import {LmsInstructorsComponent} from './components/dashboard/lms-courses/lms-instructors/lms-instructors.component';
import {
    LmsCurrentCoursesComponent
} from './components/dashboard/lms-courses/lms-current-courses/lms-current-courses.component';
import {LmsMessagesComponent} from './components/dashboard/lms-courses/lms-messages/lms-messages.component';
import {
    LmsEnrolledCreatedComponent
} from './components/dashboard/lms-courses/lms-enrolled-created/lms-enrolled-created.component';
import {LmsStudentsComponent} from './components/dashboard/lms-courses/lms-students/lms-students.component';
import {LmsStatusComponent} from './components/dashboard/lms-courses/lms-status/lms-status.component';
import {
    LmsEnrolledComponent
} from './components/dashboard/lms-courses/lms-enrolled-created/lms-enrolled/lms-enrolled.component';
import {
    LmsCreatedComponent
} from './components/dashboard/lms-courses/lms-enrolled-created/lms-created/lms-created.component';
import {
    WelcomeDashboardComponent
} from './components/dashboard/analytics/welcome-dashboard/welcome-dashboard.component';
import {AnalyticsStatsComponent} from './components/dashboard/analytics/analytics-stats/analytics-stats.component';
import {AnalyticsStatusComponent} from './components/dashboard/analytics/analytics-status/analytics-status.component';
import {
    AnalyticsAudienceOverviewComponent
} from './components/dashboard/analytics/analytics-audience-overview/analytics-audience-overview.component';
import {SalesAnalyticsComponent} from './components/dashboard/analytics/sales-analytics/sales-analytics.component';
import {
    AnalyticsTotalRevenueComponent
} from './components/dashboard/analytics/analytics-total-revenue/analytics-total-revenue.component';
import {RevenueReportComponent} from './components/dashboard/analytics/revenue-report/revenue-report.component';
import {
    AnalyticsActivityComponent
} from './components/dashboard/analytics/analytics-activity/analytics-activity.component';
import {
    TotalTransactionsComponent
} from './components/dashboard/analytics/total-transactions/total-transactions.component';
import {TerminalsComponent} from './components/dashboard/analytics/terminals/terminals.component';
import {NewVsReturingComponent} from './components/dashboard/analytics/new-vs-returing/new-vs-returing.component';
import {AnalyticsGenderComponent} from './components/dashboard/analytics/analytics-gender/analytics-gender.component';
import {VisitorsAgeComponent} from './components/dashboard/analytics/visitors-age/visitors-age.component';
import {SessionsDeviceComponent} from './components/dashboard/analytics/sessions-device/sessions-device.component';
import {
    AnalyticsLanguageComponent
} from './components/dashboard/analytics/analytics-language/analytics-language.component';
import {
    SessionsByCountriesComponent
} from './components/dashboard/analytics/sessions-by-countries/sessions-by-countries.component';
import {
    BrowserUsedTrafficReportsComponent
} from './components/dashboard/analytics/browser-used-traffic-reports/browser-used-traffic-reports.component';
import {PmStatsComponent} from './components/dashboard/project-management/pm-stats/pm-stats.component';
import {
    TaskDistributionComponent
} from './components/dashboard/project-management/task-distribution/task-distribution.component';
import {MyTasksComponent} from './components/dashboard/project-management/my-tasks/my-tasks.component';
import {PmTotalUsersComponent} from './components/dashboard/project-management/pm-total-users/pm-total-users.component';
import {
    CompletedTasksComponent
} from './components/dashboard/project-management/completed-tasks/completed-tasks.component';
import {
    TasksPerformanceComponent
} from './components/dashboard/project-management/tasks-performance/tasks-performance.component';
import {
    IssuesSummaryComponent
} from './components/dashboard/project-management/issues-summary/issues-summary.component';
import {
    PmTeamMembersComponent
} from './components/dashboard/project-management/pm-team-members/pm-team-members.component';
import {
    PmAllProjectsComponent
} from './components/dashboard/project-management/pm-all-projects/pm-all-projects.component';
import {
    PmActivityTimelineComponent
} from './components/dashboard/project-management/pm-activity-timeline/pm-activity-timeline.component';
import {PoStatsComponent} from './components/pages/ecommerce/products-orders/po-stats/po-stats.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {PersonalInfoComponent} from './components/pages/profile/personal-info/personal-info.component';
import {ActivityTimelineComponent} from './components/pages/profile/activity-timeline/activity-timeline.component';
import {StatsComponent} from './components/pages/profile/stats/stats.component';
import {OverviewComponent} from './components/pages/profile/overview/overview.component';
import {TasksComponent} from './components/pages/profile/tasks/tasks.component';
import {AccountComponent} from './components/pages/account/account.component';
import {SecurityComponent} from './components/pages/security/security.component';
import {ConnectionsComponent} from './components/pages/connections/connections.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {NotFoundComponent} from './components/common/not-found/not-found.component';
import {InternalErrorComponent} from './components/common/internal-error/internal-error.component';
import {ResetPasswordComponent} from './components/authentication/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './components/authentication/forgot-password/forgot-password.component';
import {LoginComponent} from './components/authentication/login/login.component';
import {RegisterComponent} from './components/authentication/register/register.component';
import {SigninSignupComponent} from './components/authentication/signin-signup/signin-signup.component';
import {LogoutComponent} from './components/authentication/logout/logout.component';
import {ConfirmMailComponent} from './components/authentication/confirm-mail/confirm-mail.component';
import {LockScreenComponent} from './components/authentication/lock-screen/lock-screen.component';
import {CustomizerSettingsComponent} from './components/customizer-settings/customizer-settings.component';
import {CryptoComponent} from './components/dashboard/crypto/crypto.component';
import {HelpDeskComponent} from './components/dashboard/help-desk/help-desk.component';
import {SaasAppComponent} from './components/dashboard/saas-app/saas-app.component';
import {CryptoStatsComponent} from './components/dashboard/crypto/crypto-stats/crypto-stats.component';
import {MarketGraphComponent} from './components/dashboard/crypto/market-graph/market-graph.component';
import {CurrentRateComponent} from './components/dashboard/crypto/current-rate/current-rate.component';
import {CryptoMyProfileComponent} from './components/dashboard/crypto/crypto-my-profile/crypto-my-profile.component';
import {UserActivitiesComponent} from './components/dashboard/crypto/user-activities/user-activities.component';
import {OrderActivitiesComponent} from './components/dashboard/crypto/order-activities/order-activities.component';
import {MyCurrenciesComponent} from './components/dashboard/crypto/my-currencies/my-currencies.component';
import {TradingComponent} from './components/dashboard/crypto/trading/trading.component';
import {HdStatsComponent} from './components/dashboard/help-desk/hd-stats/hd-stats.component';
import {TicketsStatusComponent} from './components/dashboard/help-desk/tickets-status/tickets-status.component';
import {
    CustomerSatisfactionComponent
} from './components/dashboard/help-desk/customer-satisfaction/customer-satisfaction.component';
import {SupportStatusComponent} from './components/dashboard/help-desk/support-status/support-status.component';
import {HdActivityComponent} from './components/dashboard/help-desk/hd-activity/hd-activity.component';
import {AverageTimeCallComponent} from './components/dashboard/help-desk/average-time-call/average-time-call.component';
import {
    AgentPerformanceComponent
} from './components/dashboard/help-desk/agent-performance/agent-performance.component';
import {
    SaCompletedTasksComponent
} from './components/dashboard/saas-app/sa-completed-tasks/sa-completed-tasks.component';
import {
    MilestonesOverviewComponent
} from './components/dashboard/saas-app/milestones-overview/milestones-overview.component';
import {
    SaTasksPerformanceComponent
} from './components/dashboard/saas-app/sa-tasks-performance/sa-tasks-performance.component';
import {
    SaAudienceOverviewComponent
} from './components/dashboard/saas-app/sa-audience-overview/sa-audience-overview.component';
import {SaAllProjectsComponent} from './components/dashboard/saas-app/sa-all-projects/sa-all-projects.component';
import {SaMessagesComponent} from './components/dashboard/saas-app/sa-messages/sa-messages.component';
import {SaStatsComponent} from './components/dashboard/saas-app/sa-stats/sa-stats.component';
import {
    SaRevenueSummaryComponent
} from './components/dashboard/saas-app/sa-revenue-summary/sa-revenue-summary.component';
import {
    SaSalesAnalyticsComponent
} from './components/dashboard/saas-app/sa-sales-analytics/sa-sales-analytics.component';
import {AuthGuardService} from "./components/guards/auth.guard";
import {SupabaseAuthService} from "./components/services/supabase.auth.service";
import {BookingsComponent} from "./components/pages/bookings/bookings-overview/bookings.component";
import {AddBookingComponent} from "./components/pages/bookings/add-booking/add-booking.component";
import {CategoryService} from "./components/services/category.service";
import {AddCategoryDialogComponent} from "./components/pages/categories/add-category/add-category-dialog.component";
import {AddLocationDialogComponent} from "./components/pages/locations/add-location/add-location-dialog.component";
import {AddAgencyDialogComponent} from "./components/pages/agency/add-agency/add-agency-dialog.component";
import {LocationsComponent} from "./components/pages/locations/locations.component";
import {PerformersComponent} from "./components/pages/performers/performers.component";
import {NotificationService} from "./components/services/notification.service";
import {ToastrModule} from "ngx-toastr";
import {AddPerformerComponent} from "./components/pages/performers/add-performer/add-performer.component";
import {
    AddPerformerMemberDialogComponent
} from "./components/pages/performers/add-performer-member/add-performer-member-dialog.component";
import {PerformerService} from "./components/services/performer.service";
import {BookingService} from "./components/services/booking.service";
import {AttachmentService} from "./components/services/attachment.service";
import {LocationService} from "./components/services/location.service";
import {BookingDetailsComponent} from "./components/pages/bookings/booking-details/booking-details.component";
import {BookingInfoComponent} from "./components/pages/bookings/booking-details/booking-info/booking-info.component";
import {
    ContactsInfoComponents
} from "./components/pages/bookings/booking-details/contacts-info/contacts-info.components";
import {
    FinancialInfoComponent
} from "./components/pages/bookings/booking-details/financial-info/financial-info.component";
import {AddProductDialogComponent} from "./components/pages/product/add-product/add-product-dialog.component";
import {PerformerDetailsComponent} from "./components/pages/performers/performer-details/performer-details.component";
import {
    PerformerInfoComponent
} from "./components/pages/performers/performer-details/performer-info/performer-info.component";
import {
    PerformerProductsInfoComponent
} from "./components/pages/performers/performer-details/performerproducts-info/performerproducts-info.component";
import {CustomersComponent} from "./components/pages/customers/customers.component";
import {AddCustomerDialogComponent} from "./components/pages/customers/add-customer/add-customer-dialog.component";
import {CustomerService} from "./components/services/customer.service";
import {ngxLoadingAnimationTypes, NgxLoadingModule} from "ngx-loading";
import {
    ProductSummaryComponent
} from "./components/pages/bookings/add-booking/product-summary/product-summary.component";
import {
    BookingsListComponent
} from "./components/pages/bookings/bookings-overview/bookings-list/bookings-list.component";
import {
    BookingsCalendarComponent
} from "./components/pages/bookings/bookings-overview/bookings-calendar/bookings-calendar.component";
import {SearchService} from "./components/services/search.service";
import {ProductsInfoComponent} from "./components/pages/bookings/booking-details/products-info/products-info.component";
import {
    AttachmentsInfoComponent
} from "./components/pages/bookings/booking-details/attachments-info/attachments-info.component";
import {
    EditBookingPerformerDialogComponent
} from "./components/pages/bookings/add-booking/edit-bookingperformer/edit-booking-performer-dialog.component";
import {
    EditBookingProductDialogComponent
} from "./components/pages/bookings/add-booking/edit-bookingproduct/edit-booking-product-dialog.component";
import {
    AddPerformerFlightComponentDialog
} from "./components/pages/bookings/add-booking/add-performerflight/add-performer-flight-component-dialog.component";
import {
    AddPerformerHotelDialogComponent
} from "./components/pages/bookings/add-booking/add-performerhotel/add-performer-hotel-dialog.component";
import {TravelInfoComponent} from "./components/pages/bookings/booking-details/travel-info/travel-info.component";
import {
    AddAgencyExternalDialogComponent
} from "./components/pages/agency/add-agency-external/add-agency-external-dialog.component";
import {AgencyGuardService} from "./components/guards/agency.guard";
import {
    PerformerAttachmentsInfoComponent
} from "./components/pages/performers/performer-details/performerattachments-info/performerattachments-info.component";
import {
    PerformerBookingsInfoComponent
} from "./components/pages/performers/performer-details/performerbookings-info/performerbookings-info.component";
import {CdkMenuModule} from "@angular/cdk/menu";
import {
    AddPerformerProductDialogComponent
} from "./components/pages/bookings/add-booking/add-performerproduct/add-performer-product-dialog.component";
import {
    AddProductCategoriesDialogComponent
} from "./components/pages/bookings/add-booking/add-productcategories/add-product-categories-dialog.component";
import {ProductService} from "./components/services/product.service";
import {GuestInfoComponents} from "./components/pages/bookings/booking-details/guest-info/guest-info.components";
import {
    AddInvoiceDialogComponent
} from "./components/pages/bookings/booking-details/add-invoice/add-invoice-dialog.component";
import {InvoiceService} from "./components/services/invoice.service";
import {InvoicesComponent} from "./components/pages/invoices/invoices-overview/invoices.component";
import {
    AddBookingProductDialogComponent
} from "./components/pages/bookings/booking-details/add-product/add-booking-product-dialog.component";
import {
    AddGuestDialogComponent
} from "./components/pages/bookings/booking-details/add-guest/add-guest-dialog.component";
import {
    AddContactDialogComponent
} from "./components/pages/bookings/booking-details/add-contact/add-contact-dialog.component";
import {
    AddPerformerDialogComponent
} from "./components/pages/bookings/booking-details/add-performer/add-performer-dialog.component";
import {BookingCommunicationService} from "./components/services/booking-communication.service";
import {
    AddAttachmentDialogComponent
} from "./components/pages/bookings/booking-details/add-attachment/add-attachment-dialog.component";
import {EmailService} from "./components/services/email.service";

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        EcommerceComponent,
        AnalyticsComponent,
        ProjectManagementComponent,
        LmsCoursesComponent,
        SidebarComponent,
        FooterComponent,
        HeaderComponent,
        RecentOrdersComponent,
        TeamMembersListComponent,
        BestSellingProductsComponent,
        EcommerceStatsComponent,
        AudienceOverviewComponent,
        EcommerceRatingsComponent,
        EcommerceImpressionsComponent,
        VisitsByDayComponent,
        EcommerceActivityTimelineComponent,
        SalesByLocationsComponent,
        NewCustomersComponent,
        LiveVisitsOnOurSiteComponent,
        RevenueStatusComponent,
        LmsStatsComponent,
        LmsExperienceComponent,
        LmsProgressComponent,
        LmsHoursSpentComponent,
        LmsPlanningComponent,
        LmsActiveCourseComponent,
        LmsCourseCompletionComponent,
        LmsInstructorsComponent,
        LmsCurrentCoursesComponent,
        LmsMessagesComponent,
        LmsEnrolledCreatedComponent,
        LmsStudentsComponent,
        LmsStatusComponent,
        LmsEnrolledComponent,
        LmsCreatedComponent,
        WelcomeDashboardComponent,
        AnalyticsStatsComponent,
        AnalyticsStatusComponent,
        AnalyticsAudienceOverviewComponent,
        SalesAnalyticsComponent,
        AnalyticsTotalRevenueComponent,
        RevenueReportComponent,
        AnalyticsActivityComponent,
        TotalTransactionsComponent,
        TerminalsComponent,
        NewVsReturingComponent,
        AnalyticsGenderComponent,
        VisitorsAgeComponent,
        SessionsDeviceComponent,
        AnalyticsLanguageComponent,
        SessionsByCountriesComponent,
        BrowserUsedTrafficReportsComponent,
        PmStatsComponent,
        TaskDistributionComponent,
        MyTasksComponent,
        PmTotalUsersComponent,
        CompletedTasksComponent,
        TasksPerformanceComponent,
        IssuesSummaryComponent,
        PmTeamMembersComponent,
        PmAllProjectsComponent,
        PmActivityTimelineComponent,
        PoStatsComponent,
        ProfileComponent,
        PersonalInfoComponent,
        ActivityTimelineComponent,
        StatsComponent,
        OverviewComponent,
        TasksComponent,
        AccountComponent,
        SecurityComponent,
        ConnectionsComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        NotFoundComponent,
        InternalErrorComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        SigninSignupComponent,
        LogoutComponent,
        ConfirmMailComponent,
        LockScreenComponent,
        CustomizerSettingsComponent,
        CryptoComponent,
        HelpDeskComponent,
        SaasAppComponent,
        CryptoStatsComponent,
        MarketGraphComponent,
        CurrentRateComponent,
        CryptoMyProfileComponent,
        UserActivitiesComponent,
        OrderActivitiesComponent,
        MyCurrenciesComponent,
        TradingComponent,
        HdStatsComponent,
        TicketsStatusComponent,
        CustomerSatisfactionComponent,
        SupportStatusComponent,
        HdActivityComponent,
        AverageTimeCallComponent,
        AgentPerformanceComponent,
        SaCompletedTasksComponent,
        MilestonesOverviewComponent,
        SaTasksPerformanceComponent,
        SaAudienceOverviewComponent,
        SaAllProjectsComponent,
        SaMessagesComponent,
        SaStatsComponent,
        SaRevenueSummaryComponent,
        SaSalesAnalyticsComponent,
        //Newly Added Components
        BookingsComponent,
        LocationsComponent,
        AddBookingComponent,
        AddCategoryDialogComponent,
        AddLocationDialogComponent,
        AddAgencyDialogComponent,
        PerformersComponent,
        AddPerformerComponent,
        AddPerformerMemberDialogComponent,
        BookingDetailsComponent,
        BookingInfoComponent,
        ContactsInfoComponents,
        FinancialInfoComponent,
        AddProductDialogComponent,
        PerformerDetailsComponent,
        PerformerInfoComponent,
        PerformerProductsInfoComponent,
        CustomersComponent,
        AddCustomerDialogComponent,
        ProductSummaryComponent,
        BookingsListComponent,
        BookingsCalendarComponent,
        ProductsInfoComponent,
        AttachmentsInfoComponent,
        EditBookingPerformerDialogComponent,
        EditBookingProductDialogComponent,
        AddPerformerFlightComponentDialog,
        AddPerformerHotelDialogComponent,
        TravelInfoComponent,
        AddAgencyExternalDialogComponent,
        PerformerAttachmentsInfoComponent,
        PerformerBookingsInfoComponent,
        AddPerformerProductDialogComponent,
        AddProductCategoriesDialogComponent,
        GuestInfoComponents,
        AddInvoiceDialogComponent,
        InvoicesComponent,
        AddBookingProductDialogComponent,
        AddGuestDialogComponent,
        AddContactDialogComponent,
        AddPerformerDialogComponent,
        AddAttachmentDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatMenuModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        MatProgressBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgScrollbarModule,
        FormsModule,
        FullCalendarModule,
        MatNativeDateModule ,
        ReactiveFormsModule,
        NgxEditorModule,
        DragDropModule,
        HttpClientModule,
        CdkAccordionModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgxGaugeModule,
        CdkMenuModule,
        NgChartsModule,
        NgxMatTimepickerModule,
        QuillModule.forRoot(),
        ColorPickerModule,
        NgxDropzoneModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: "rgba(0,0,0,0.5)",
            backdropBorderRadius: "4px",
            primaryColour: "#ffffff",
            secondaryColour: "#ffffff",
            tertiaryColour: "#ffffff",
        })

    ],
    providers: [
        DatePipe,
        AuthGuardService,
        AgencyGuardService,
        SupabaseAuthService,
        CategoryService,
        NotificationService,
        PerformerService,
        BookingService,
        AttachmentService,
        LocationService,
        CustomerService,
        SearchService,
        ProductService,
        InvoiceService,
        BookingCommunicationService,
        EmailService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
