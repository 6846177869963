import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {Booking_Performer, BookingDetails, BookingStatus, Customer, Location} from "../../../../common/model/model";
import {Observable} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {BookingService} from "../../../../services/booking.service";
import {NotificationService} from "../../../../services/notification.service";
import {MatDialog} from "@angular/material/dialog";
import {AddPerformerDialogComponent} from "../add-performer/add-performer-dialog.component";
import {BookingCommunicationService} from "../../../../services/booking-communication.service";

@Component({
    selector: 'bookinginfo-component',
    templateUrl: './booking-info.component.html',
    styleUrls: ['./booking-info.component.scss']
})
export class BookingInfoComponent implements OnInit, OnChanges {

    @Input() bookingDetails: BookingDetails;
    @Input() locations: Location[];
    @Input() customers: Customer[];

    public bookingInfoFormGroup: FormGroup;

    public selectedLocation: Location;
    public selectedCustomer: Customer;

    public locationFormControl = new FormControl(<Location | null>(null));
    public customerFormControl = new FormControl(<Customer | null>(null));
    public eventDateFormControl = new FormControl(new Date());

    public filteredLocations: Observable<Location[]>;
    public filteredCustomers: Observable<Customer[]>;

    public updateDisabled: boolean = true;
    public bookingStatusOptions = Object.values(BookingStatus);

    constructor(public themeService: CustomizerSettingsService,
                private bookingInfoFormBuilder: FormBuilder,
                private bookingService: BookingService,
                private notificationService: NotificationService,
                private bookingCommunicationService: BookingCommunicationService,
                public matDialog: MatDialog) {
    }

    ngOnInit(): void {
        this.setInitialLocationValue();
        this.setInitialCustomerValue();
        this.setInitialDateValue();

        this.enableFilteredLocations();
        this.enableBookingInfoForm();

        this.bookingInfoFormGroup.valueChanges.subscribe(() => {
            this.updateDisabled = this.bookingInfoFormGroup.pristine;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['locations'] && changes['locations'].currentValue) {
            this.enableFilteredLocations();
        }

        if (changes['customers'] && changes['customers'].currentValue) {
            this.enableFilteredCustomers();
        }
    }

    public enableBookingInfoForm() {
        this.bookingInfoFormGroup = this.bookingInfoFormBuilder.group({
            eventname: [this.bookingDetails.booking.event_name, Validators.required],
            eventdate: [this.bookingDetails.booking.event_date,Validators.required],
            bookingstatus: [this.bookingDetails.booking.status, Validators.required],
            eventwebsite: [this.bookingDetails.booking.event_website],
            eventbudget: [this.bookingDetails.booking.event_budget],
            openingtime: [this.bookingDetails.booking.opening_time],
            begintime: [this.bookingDetails.booking.begin_time],
            endtime: [this.bookingDetails.booking.end_time],
            bookingdescription: [this.bookingDetails.booking.booking_description],
            lineupevent: [this.bookingDetails.booking.line_up_event],
            internalnotes: [this.bookingDetails.booking.internal_notes],
            constructionstart: [this.bookingDetails.booking.construction_start_time],
            soundchecktime: [this.bookingDetails.booking.sound_check_time],
            podiumready: [this.bookingDetails.booking.podium_ready_time],
            performancefloor: [this.bookingDetails.booking.performance_floor],
            distancetostage: [this.bookingDetails.booking.distance_to_stage],
            elevator: [this.bookingDetails.booking.elevator_availability],
            callsheetmemo: [this.bookingDetails.booking.call_sheet_memo],
            visitorexpectation: [this.bookingDetails.booking.visitor_expectation],
            availabletickets: [this.bookingDetails.booking.available_tickets],
        });
    }

    public openAddPerformerDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addPerformerDialog = this.matDialog.open(AddPerformerDialogComponent, {
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails
            }
        });

        addPerformerDialog.afterClosed().subscribe(result => {
            if (result) {
                let bookingPerformer: Booking_Performer = result as Booking_Performer;

                if(this.bookingDetails.performers.find(p => p.performer_id === bookingPerformer.performer_id)) {
                    this.notificationService.openFailureNotitication('Performer is already added to the booking');
                } else {
                    this.bookingService.addBookingPerformer(bookingPerformer).then(addedPerformer => {
                        this.bookingDetails.performers.push(addedPerformer[0]);
                        this.notificationService.openSuccessfulNotification('Performer added successfully');
                    });
                }
            }
        });
    }

    public openEditPerformerDialog(enterAnimationDuration: string, exitAnimationDuration: string, bookingPerformer: Booking_Performer) {
        const editPerformerDialog = this.matDialog.open(AddPerformerDialogComponent, {
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails,
                bookingPerformer
            }
        });

        editPerformerDialog.afterClosed().subscribe(result => {
            if (result) {
                let bookingPerformer: Booking_Performer = result as Booking_Performer;

                this.bookingService.updateBookingPerformer(bookingPerformer).then(updatedPerformer => {
                    this.bookingDetails.performers = this.bookingDetails.performers.map(p => {
                        if(p.id === updatedPerformer[0].id) {
                            return updatedPerformer[0];
                        }
                        return p;
                    });

                    this.notificationService.openSuccessfulNotification('Performer updated successfully');
                });
            }
        });
    }

    public onLocationSelected(event: any) {
        this.selectedLocation = event.option.value;
    }

    public onCustomerSelected(event: any) {
        this.selectedCustomer = event.option.value;
    }

    public enableFilteredLocations() {
        this.filteredLocations = this.locationFormControl.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filteredLocations(state) : this.locations.slice())),
        );
    }

    public enableFilteredCustomers() {
        this.filteredCustomers = this.customerFormControl.valueChanges.pipe(
            startWith(''),
            map(state => (state ? this._filteredCustomers(state) : this.customers.slice())),
        );
    }

    private _filteredLocations(value: any): Location[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && typeof value === 'object' && value.name) {
            filterValue = value.name.toLowerCase();
        }
        return this.locations.filter(location => location.name.toLowerCase().includes(filterValue));
    }

    private _filteredCustomers(value: any): Customer[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && typeof value === 'object' && value.name) {
            filterValue = value.name.toLowerCase();
        }
        return this.customers.filter(customer => customer.name.toLowerCase().includes(filterValue));
    }

    public setInitialLocationValue() {
        if(this.bookingDetails) {
            this.locationFormControl.setValue(this.bookingDetails.location);
        }
    }

    public setInitialCustomerValue() {
        if(this.bookingDetails) {
            this.customerFormControl.setValue(this.bookingDetails.customer);
        }
    }

    public setInitialDateValue() {
        if (this.bookingDetails) {
            this.eventDateFormControl.setValue(new Date(this.bookingDetails.booking.event_date));
        }
    }

    public deletePerformer(performer: Booking_Performer) {
        this.bookingService.deleteBookingPerformer(performer).then(deletedPerformer => {
            if(deletedPerformer) {
                this.bookingDetails.performers = this.bookingDetails.performers.filter(p => p.performer_id !== performer.performer_id);
                this.bookingService.deleteBookingProductPerformer(performer).then(isDeleted => {
                   if(isDeleted) {
                       const bookingId = this.bookingDetails?.booking?.id ?? '';
                       this.bookingCommunicationService.refreshProducts(bookingId);
                       this.notificationService.openSuccessfulNotification('Performer deleted successfully');
                   }
                });
                //this.notificationService.openSuccessfulNotification('Performer deleted successfully');
            } else {
                this.notificationService.openFailureNotitication('Failed to delete performer');
            }
        });
    }

    public updateBookingInfo() {
        //Main information
        let eventName = this.bookingInfoFormGroup.controls['eventname'].value;
        let eventDate = this.bookingInfoFormGroup.controls['eventdate'].value;

        //Rest of the information
        let eventWebsite = this.bookingInfoFormGroup.controls['eventwebsite'].value;
        let eventBudget = this.bookingInfoFormGroup.controls['eventbudget'].value;
        let bookingStatus = this.bookingInfoFormGroup.controls['bookingstatus'].value;
        let openingTime = this.bookingInfoFormGroup.controls['openingtime'].value;
        let beginTime = this.bookingInfoFormGroup.controls['begintime'].value;
        let endTime = this.bookingInfoFormGroup.controls['endtime'].value;
        let visitorExpectation = this.bookingInfoFormGroup.controls['visitorexpectation'].value;
        let availableTickets = this.bookingInfoFormGroup.controls['availabletickets'].value;
        let bookingDescription = this.bookingInfoFormGroup.controls['bookingdescription'].value;
        let lineUpEvent = this.bookingInfoFormGroup.controls['lineupevent'].value;
        let internalNotes = this.bookingInfoFormGroup.controls['internalnotes'].value;
        let constructionStart = this.bookingInfoFormGroup.controls['constructionstart'].value;
        let soundCheckTime = this.bookingInfoFormGroup.controls['soundchecktime'].value;
        let podiumReady = this.bookingInfoFormGroup.controls['podiumready'].value;
        let performanceFloor = this.bookingInfoFormGroup.controls['performancefloor'].value;
        let distanceToStage = this.bookingInfoFormGroup.controls['distancetostage'].value;
        let elevator = this.bookingInfoFormGroup.controls['elevator'].value;
        let callSheetMemo = this.bookingInfoFormGroup.controls['callsheetmemo'].value;

        //Assign values to the Booking object
        this.bookingDetails.booking.event_name = eventName;
        this.bookingDetails.booking.event_date = eventDate;
        this.bookingDetails.booking.status = bookingStatus;
        this.bookingDetails.booking.event_website = eventWebsite;
        this.bookingDetails.booking.event_budget = eventBudget;
        this.bookingDetails.booking.opening_time = openingTime;
        this.bookingDetails.booking.begin_time = beginTime;
        this.bookingDetails.booking.end_time = endTime;
        this.bookingDetails.booking.visitor_expectation = visitorExpectation;
        this.bookingDetails.booking.available_tickets = availableTickets;
        this.bookingDetails.booking.booking_description = bookingDescription;
        this.bookingDetails.booking.line_up_event = lineUpEvent;
        this.bookingDetails.booking.internal_notes = internalNotes;
        this.bookingDetails.booking.construction_start_time = constructionStart;
        this.bookingDetails.booking.sound_check_time = soundCheckTime;
        this.bookingDetails.booking.podium_ready_time = podiumReady;
        this.bookingDetails.booking.performance_floor = performanceFloor;
        this.bookingDetails.booking.distance_to_stage = distanceToStage;
        this.bookingDetails.booking.elevator_availability = elevator;
        this.bookingDetails.booking.call_sheet_memo = callSheetMemo;

        //Update the booking
        this.bookingService.updateBooking(this.bookingDetails.booking).then(updateBooking => {
            if(updateBooking) {
                this.notificationService.openSuccessfulNotification('Booking information updated successfully');
            }
        });
    }

    displayFnLocations(location: Location): string {
        return location && location.name ? location.name : '';
    }

    displayFnCustomers(customer: Customer): string {
        return customer && customer.name ? customer.name : '';
    }
}
