import {Component, OnInit} from '@angular/core';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {Agency} from "../model/model";
import {AddAgencyDialogComponent} from "../../pages/agency/add-agency/add-agency-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {AgencyService} from "../../services/agency.service";
import {NotificationService} from "../../services/notification.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    public agencies: Agency[] = [];
    public latestSelectedAgency: any;
    agenciesFormControl: FormControl = new FormControl();
    panelOpenState = false;

    isToggled = false;

    constructor(private toggleService: ToggleService,
                public themeService: CustomizerSettingsService,
                private matDialog: MatDialog,
                private agencyService: AgencyService,
                private notificationService: NotificationService) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    ngOnInit(): void {
        this.getAllAgencies();
    }

    toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

    public onAgencySelected(agency: any) {
        // Update the latest selected agency
        this.latestSelectedAgency = agency;
        // Update the FormControl value
        this.agenciesFormControl.setValue(agency);

        this.agencyService.setSelectedAgency(agency);
    }

    public getAllAgencies() {
        this.agencyService.getAllAgencies().then((agencies) => {
            this.agencies = agencies;
            this.agenciesFormControl = new FormControl(this.agencies.length > 0 ? this.agencies[0] : null);

            // Select the first agency by default and set it as the selected agency to be picked up by other components
            this.agencyService.setSelectedAgency(this.agencies[0]);
            this.latestSelectedAgency = this.agencies[0];
        }).catch((error) => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    public openAddAgencyDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const agencyDialogRef = this.matDialog.open(AddAgencyDialogComponent, {
            width: '500px',
            enterAnimationDuration,
            exitAnimationDuration
        });

        agencyDialogRef.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                this.agencies.push(result);
                //const updatedValue = [...(this.agenciesFormControl.value || []), result];
                this.agenciesFormControl.setValue(result);
                this.agencyService.setSelectedAgency(result);
            } else {
                //This code selects the last selected Agency from the overview
                this.agenciesFormControl = new FormControl(this.latestSelectedAgency ?
                    this.latestSelectedAgency : (this.agencies.length > 0 ? this.agencies[0] : null));
            }
        });
    }
}
