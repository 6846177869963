<div *ngIf="bookingDetails">
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="bookingInfoFormGroup">
            <div class="row mt-20">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="phobos-form-group">
                        <label class="d-block mb-12 fw-semibold">Event Name</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-book-line"></i>
                            <input formControlName="eventname" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="d-block mb-12 fw-semibold">Event Date</label>
                    <mat-form-field appearance="fill" class="date-input">
                        <mat-label>MM/DD/YYYY</mat-label>
                        <input matInput formControlName="eventdate" [matDatepicker]="eventDatePicker" [formControl]="eventDateFormControl">
                        <mat-datepicker-toggle matIconSuffix [for]="eventDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #eventDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <div class="phobos-form-group">
                        <label class="d-block mb-12 fw-semibold">Booking Status</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-book-line"></i>
                            <mat-select formControlName="bookingstatus">
                                <mat-option *ngFor="let status of bookingStatusOptions" [value]="status">
                                    {{ status }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="d-block mb-12 fw-semibold">Location</label>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <i class="ri-map-pin-2-line"></i>
                        <input matInput aria-label="State" [matAutocomplete]="auto" [formControl]="locationFormControl">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLocationSelected($event)" [displayWith]="displayFnLocations">
                            <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                                <img alt="flag" class="example-option-img" [src]="location.logo " width="20">
                                <span>{{location.name}}</span> |
                                <small>Occupation: {{location.occupation}}</small>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2" *ngIf="customers">
                    <label class="d-block mb-12 fw-semibold">Customer</label>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <i class="ri-map-pin-2-line"></i>
                        <input matInput aria-label="State" [matAutocomplete]="auto" [formControl]="customerFormControl">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCustomerSelected($event)" [displayWith]="displayFnCustomers">
                            <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
                                <img alt="flag" class="example-option-img" [src]="customer.logo " width="20">
                                <span>{{customer.name}}</span> |
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

            </div>
        </form>
    </mat-card>
    <mat-card class="mb-25 phobos-card" *ngIf="bookingDetails">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Performers</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddPerformerDialog('300ms', '100ms')">
                <i class="ri-add-fill"></i>Add Performer
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.performers.length < 1">No performers added yet</mat-label>
            <div class="all-projects-table table-responsive">
                <table class="table" *ngIf="bookingDetails.performers.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Performer</th>
                        <th class="fw-semibold main-color">Status</th>
                        <th class="fw-semibold main-color">Start Time</th>
                        <th class="fw-semibold main-color">End Time</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let bookingPerformer of bookingDetails.performers">
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/performer.png" alt="icon1">
                                </div>
                                <h5 class="fw-semibold mb-0">{{ bookingPerformer.name }}</h5>
                            </div>
                        </td>
                        <td>
                            <span class="badge fw-medium"
                                  [ngClass]="{
                                  'confirmed': bookingPerformer.status === 'Confirmed',
                                  'option': bookingPerformer.status === 'Option',
                                  'draft': bookingPerformer.status === 'Draft'
                                  }">
                                {{bookingPerformer.status}}
                            </span>
                        </td>
                        <td>{{bookingPerformer.start_time}}</td>
                        <td>{{bookingPerformer.end_time}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="performerActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #performerActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="openEditPerformerDialog('300ms', '100ms', bookingPerformer)">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="deletePerformer(bookingPerformer)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="mb-25 phobos-card" [class.component-dark-theme]="themeService.isDark()"
              [class.component-rtl-enabled]="themeService.isRTLEnabled()">
        <mat-card-header class="border-bottom">
            <h5 class="mb-0">Booking Info</h5>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="bookingInfoFormGroup">
                <div class="row mt-20">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold ">Event Website</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-pages-line"></i>
                                <mat-label>Website of the event/organization</mat-label>
                                <input formControlName="eventwebsite" matInput value="{{bookingDetails.booking.event_website}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Event Budget</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-book-line"></i>
                                <mat-label>Budget for the event</mat-label>
                                <input formControlName="eventbudget" matInput value="{{bookingDetails.booking.event_budget}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Opening Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <input formControlName="openingtime" matInput type="time" value="{{bookingDetails.booking.opening_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Begin Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Time the event starts</mat-label>
                                <input formControlName="begintime" matInput type="time" value="{{bookingDetails.booking.begin_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">End Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Time the event ends</mat-label>
                                <input formControlName="endtime" matInput type="time" value="{{bookingDetails.booking.end_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Visitor Expectation</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Expected amount of visitors</mat-label>
                                <input formControlName="visitorexpectation" matInput value="{{bookingDetails.booking.visitor_expectation}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Available Tickets</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Available tickets sold</mat-label>
                                <input formControlName="availabletickets" matInput value="{{bookingDetails.booking.available_tickets}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Booking Description</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Enter additional booking description here</mat-label>
                                <textarea formControlName="bookingdescription" matInput rows="6" value="{{bookingDetails.booking.booking_description}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Line-Up Event</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Line-up of the event</mat-label>
                                <textarea formControlName="lineupevent" matInput rows="6" value="{{bookingDetails.booking.line_up_event}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Internal Notes</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Private internal notes</mat-label>
                                <textarea formControlName="internalnotes" matInput rows="6" value="{{bookingDetails.booking.internal_notes}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Construction Start Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Start of the construction</mat-label>
                                <input formControlName="constructionstart" type="time" matInput value="{{bookingDetails.booking.construction_start_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Sound Check Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Start of sound check</mat-label>
                                <input formControlName="soundchecktime" type="time" matInput value="{{bookingDetails.booking.sound_check_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Podium Ready Time</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-time-line"></i>
                                <mat-label>Time when the podium is ready</mat-label>
                                <input formControlName="podiumready" type="time" matInput value="{{bookingDetails.booking.podium_ready_time}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Performance Floor</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Floor on which the performance will take place</mat-label>
                                <input formControlName="performancefloor" matInput value="{{bookingDetails.booking.performance_floor}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Distance to Stage</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Distance to stage for equipment</mat-label>
                                <input formControlName="distancetostage" matInput value="{{bookingDetails.booking.distance_to_stage}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Elevator Availability</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Information about availability of elevator</mat-label>
                                <input formControlName="elevator" matInput value="{{bookingDetails.booking.elevator_availability}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="phobos-form-group">
                            <label class="d-block mb-12 fw-semibold">Call Sheet Memo</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-information-line"></i>
                                <mat-label>Relevant information regarding call sheet</mat-label>
                                <textarea formControlName="callsheetmemo" matInput rows="6" value="{{bookingDetails.booking.call_sheet_memo}}"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <button mat-raised-button class="phobos fw-semibold" style="margin-right: 5px" (click)="updateBookingInfo()" [disabled]="updateDisabled">Update</button>
                <button mat-raised-button class="phobos fw-semibold">Confirm & Send</button>
            </form>
        </mat-card-content>
    </mat-card>

</div>
