<div
    class="settings-sidebar bg-white box-shadow"
    [ngClass]="{'active': isToggled}"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="settings-header bg-gray p-20">
        <div class="d-flex align-items-center justify-content-space-between">
            <h5 class="m-0">Configuration Panel</h5>
            <img src="assets/img/logo-icon.png" alt="logo-icon">
        </div>
    </div>
    <ng-scrollbar visibility="hover">
        <div class="settings-body">
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">RTL Mode</span>
                <button (click)="toggleRTLEnabledTheme()" class="switch-btn" [class.active]="themeService.isRTLEnabled()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Dark Mode</span>
                <button (click)="toggleTheme()" class="switch-btn" [class.active]="themeService.isDark()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Only Sidebar Dark</span>
                <button (click)="toggleSidebarTheme()" class="switch-btn" [class.active]="themeService.isSidebarDark()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Right Sidebar</span>
                <button (click)="toggleRightSidebarTheme()" class="switch-btn" [class.active]="themeService.isRightSidebar()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Hide Sidebar</span>
                <button (click)="toggleHideSidebarTheme()" class="switch-btn" [class.active]="themeService.isHideSidebar()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Only Header Dark</span>
                <button (click)="toggleHeaderTheme()" class="switch-btn" [class.active]="themeService.isHeaderDark()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Borderd Card</span>
                <button (click)="toggleCardBorderTheme()" class="switch-btn" [class.active]="themeService.isCardBorder()"></button>
            </div>
            <mat-divider></mat-divider>
            <div class="p-20">
                <span class="sub-title d-block mb-12 fw-semibold">Card Border Radius</span>
                <button (click)="toggleCardBorderRadiusTheme()" class="switch-btn" [class.active]="themeService.isCardBorderRadius()"></button>
            </div>
        </div>
    </ng-scrollbar>
</div>

<button mat-fab class="phobos settings-btn" (click)="toggle()" [ngClass]="{'active': isToggled}" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <mat-icon>settings</mat-icon>
</button>
