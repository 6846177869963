<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Add New Category</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="addCategoryFormGroup">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Category Name</label>
                    <input type="text" formControlName="name" class="input-control" placeholder="Category Name *"
                           [ngClass]="{ 'is-invalid': addCategorySubmitted && addCategoryFormGroupControls['name'].errors}">
                    <div *ngIf="addCategorySubmitted && addCategoryFormGroupControls['name'].errors" style="color:red;font-size:small">
                        <div *ngIf="addCategoryFormGroupControls['name'].errors?.['required']">
                            Please fill in a category name
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="saveCategory()">Save</button>
        </div>
    </form>
</div>
