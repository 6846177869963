<mat-card
    class="mb-25 phobos-card tickets-status-box"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header class="border-bottom">
        <h5 class="mb-0">Tickets Status</h5>
        <button type="button" mat-button class="position-relative btn bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            This Week
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">This Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">This Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="chart ticketsStatusChart">
                    <apx-chart
                        [series]="chartOptions.series!"
                        [chart]="chartOptions.chart!"
                        [dataLabels]="chartOptions.dataLabels!"
                        [stroke]="chartOptions.stroke!"
                        [colors]="chartOptions.colors!"
                        [title]="chartOptions.title!"
                        [grid]="chartOptions.grid!"
                        [tooltip]="chartOptions.tooltip!"
                        [plotOptions]="chartOptions.plotOptions!"
                        [yaxis]="chartOptions.yaxis!"
                        [legend]="chartOptions.legend!"
                        [xaxis]="chartOptions.xaxis!"
                    ></apx-chart>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="box">
                    <div class="d-flex align-items-center justify-content-space-between">
                        <span class="gray-color">New Tickets</span>
                        <span class="up gray-color">
                            <i class="flaticon-trend"></i>
                            +2.48%
                        </span>
                    </div>
                    <h4 class="fw-semibold">12,508</h4>
                    <mat-progress-bar mode="determinate" value="45"></mat-progress-bar>
                </div>
                <div class="box">
                    <div class="d-flex align-items-center justify-content-space-between">
                        <span class="gray-color">Solved Tickets</span>
                        <span class="down gray-color">
                            <i class="flaticon-trend-1"></i>
                            -1.02%
                        </span>
                    </div>
                    <h4 class="fw-semibold">10,431</h4>
                    <mat-progress-bar mode="determinate" value="76"></mat-progress-bar>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
