<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Generate Invoice</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addInvoiceFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group" *ngIf="data.invoice">
                        <label class="d-block fw-semibold">Invoice Nr</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label>
                                    Invoice Nr
                                </mat-label>
                                <input matInput [disabled]="true" value="{{data.invoice.invoice_nr}}">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Customer</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label>
                                    Customer name
                                </mat-label>
                                <input matInput [disabled]="true" value="{{data.bookingDetails.customer.name}}">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Booking</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label>
                                    Booking Event
                                </mat-label>
                                <input matInput [disabled]="true" value="{{data.bookingDetails.booking.event_name}}">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Booking Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label>
                                    Booking Date
                                </mat-label>
                                <input matInput [disabled]="true" value="{{data.bookingDetails.booking.event_date}}">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Invoice Amount</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label>
                                    Invoice Amount
                                </mat-label>
                                <input matInput [disabled]="true" value="{{data.bookingDetails.booking.currency_symbol}} {{data.financialsTotalWithVAT}} {{data.bookingDetails.booking.currency_code}}">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Invoice Description</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addInvoiceFormGroupControls['invoice_description'].errors && addInvoiceSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addInvoiceFormGroup.get('invoice_description')?.hasError('required') && addInvoiceSubmitted) ? '#d73a49' : 'inherit'}">
                                    Invoice Description
                                </mat-label>
                                <input matInput formControlName="invoice_description">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Invoice Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addInvoiceFormGroupControls['invoice_date'].errors && addInvoiceSubmitted}">
                                <i class="ri-calendar-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addInvoiceFormGroup.get('invoice_date')?.hasError('required') && addInvoiceSubmitted) ? '#d73a49' : 'inherit'}">
                                    Invoice Date
                                </mat-label>
                                <input matInput formControlName="invoice_date" [matDatepicker]="invoiceDatePicker">
                                <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #invoiceDatePicker></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Invoice Due Date</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addInvoiceFormGroupControls['invoice_due_date'].errors && addInvoiceSubmitted}">
                                <i class="ri-calendar-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addInvoiceFormGroup.get('invoice_due_date')?.hasError('required') && addInvoiceSubmitted) ? '#d73a49' : 'inherit'}">
                                    Flight date
                                </mat-label>
                                <input matInput formControlName="invoice_due_date" [matDatepicker]="invoiceDueDatePicker">
                                <mat-datepicker-toggle matIconSuffix [for]="invoiceDueDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #invoiceDueDatePicker></mat-datepicker>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Invoice Status</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addInvoiceFormGroupControls['invoice_status'].errors && addInvoiceSubmitted}">
                                <i class="ri-menu-search-line"></i>
                                <mat-label>Select Status</mat-label>
                                <mat-select [(value)]="selectedInvoiceStatus" formControlName="invoice_status">
                                    <mat-option *ngFor="let invoiceStatus of invoiceStatuses" [value]="invoiceStatus">{{invoiceStatus}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
            </div>
            <div class="text-end">
                <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                <button mat-flat-button class="phobos" (click)="generateInvoice()">
                    {{data.invoice ? 'Update Invoice' : 'Add Invoice'}}
                </button>
            </div>

        </form>
    </mat-card>

</div>
