import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EditBookingPerformerDialogComponent} from "../edit-bookingperformer/edit-booking-performer-dialog.component";
import {Booking_Product} from "../../../../common/model/model";

@Component({
    selector: 'edit-bookingproduct-dialog',
    templateUrl: './edit-booking-product-dialog.component.html',
    styleUrls: ['./edit-booking-product-dialog.component.scss']
})
export class EditBookingProductDialogComponent implements OnInit {

    public editBookingProductFormGroup: FormGroup;
    public editBookingProductSubmitted = false;
    public bookingProduct: Booking_Product;

    constructor(public dialogRef: MatDialogRef<EditBookingPerformerDialogComponent>,
                private editBookingProductFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public retrievedBookingProduct: Booking_Product) {
        this.bookingProduct = retrievedBookingProduct;
    }

    ngOnInit(): void {
        this.enableEditBookingProductDialogForm();
    }

    get editBookingProductFormGroupControls() {
        return this.editBookingProductFormGroup.controls;
    }

    public enableEditBookingProductDialogForm() {
        this.editBookingProductFormGroup = this.editBookingProductFormBuilder.group({
            name: [this.bookingProduct.name, Validators.required],
            description: [this.bookingProduct.description, Validators.required],
            subcategory: [this.bookingProduct.subcategory, Validators.required],
            category: [this.bookingProduct.category, Validators.required],
            cost: [this.bookingProduct.cost, Validators.required],
            price: [this.bookingProduct.price, Validators.required]
        });
    }

    public editBookingProduct() {
        this.editBookingProductSubmitted = true;

        if (this.editBookingProductFormGroup.invalid) {
        } else {
            let name = this.editBookingProductFormGroup.controls['name'].value;
            let description = this.editBookingProductFormGroup.controls['description'].value;
            let subcategory = this.editBookingProductFormGroup.controls['subcategory'].value;
            let category = this.editBookingProductFormGroup.controls['category'].value;
            let cost = this.editBookingProductFormGroup.controls['cost'].value;
            let price = this.editBookingProductFormGroup.controls['price'].value;

            this.bookingProduct.name = name;
            this.bookingProduct.description = description;
            this.bookingProduct.subcategory = subcategory;
            this.bookingProduct.category = category;
            this.bookingProduct.cost = cost;
            this.bookingProduct.price = price;

            this.dialogRef.close(this.bookingProduct);
        }
    }

    public close(){
        this.dialogRef.close(true);
    }
}
