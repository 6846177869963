import {Component, model, OnInit, ViewChild} from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import {CategoryService} from "../../../services/category.service";
import {AddCategoryDialogComponent} from "../../categories/add-category/add-category-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AddPerformerMemberDialogComponent} from "../add-performer-member/add-performer-member-dialog.component";
import {Location} from "@angular/common";
import {
    Agency,
    Agency_External,
    Category,
    Currency,
    FileTypes,
    InvitationStatus,
    InvitationType,
    Member,
    Performer,
    Performer_Product,
    UserRole,
} from "../../../common/model/model";
import {AddProductDialogComponent} from "../../product/add-product/add-product-dialog.component";
import {CurrencyService} from "../../../services/currency.service";
import {PerformerService} from "../../../services/performer.service";
import {AgencyService} from "../../../services/agency.service";
import {Router} from "@angular/router";
import {NotificationService} from "../../../services/notification.service";
import {MatChipEditedEvent, MatChipGrid, MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable, of} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {AddAgencyExternalDialogComponent} from "../../agency/add-agency-external/add-agency-external-dialog.component";
import {AgencyExternalService} from "../../../services/agencyexternal.service";
import {externalAgencyRequiredValidator} from "../../../validation/externalagency.validator";
import {InvitationService} from "../../../services/invitation.service";
import {SupabaseAuthService} from "../../../services/supabase.auth.service";


@Component({
    selector: "add-performer",
    templateUrl: "./add-performer.component.html",
    styleUrls: ["./add-performer.component.scss"],
    //encapsulation: ViewEncapsulation.Emulated,
})
export class AddPerformerComponent implements OnInit {

    @ViewChild('chipGrid') chipGrid: MatChipGrid;

    public addPerformerFormGroup: FormGroup;
    public addPerformerSubmitted = false;
    public categoriesFormControl = new FormControl<number[]>([], Validators.required);

    public playtimesFormControl: FormControl;
    public membersFormControl = new FormControl([""]);
    public externalAgencyFormControl = new FormControl<Agency_External | null>(null);
    public members: Member[] = [];
    public performerProducts: Performer_Product[] = [];
    public categories: Category[] = [];
    public externalAgencies: Agency_External[] = [];
    public selectedCurrency: Currency;
    public performerRider: File;
    public pressKit: File;
    public var: File;
    public selectedAgency: Agency;
    //public playTimes: string[] = [];
    public playTimes: string[] = ['2 x 30 min', '60 min'];
    public isLoading = false;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    addOnBlur = true;
    public performerExclusivity = true;
    public selectedExternalAgency: Agency_External;

    public selectedCategory: Category;
    public selectedCategories: number[] = [];

    public picture: File[] = [];
    public existingPicture: string | null = null;

    public exclusivityPerformer = model<boolean>(true);

    public filteredExternalAgencies: Observable<Agency_External[]>;

    constructor(
        private categoryService: CategoryService,
        public matDialog: MatDialog,
        private location: Location,
        private addPerformerFormBuilder: FormBuilder,
        private currencyService: CurrencyService,
        private performerService: PerformerService,
        private agencyService: AgencyService,
        private router: Router,
        private notificationService: NotificationService,
        private agencyExternalService: AgencyExternalService,
        private invitationService: InvitationService,
        private supabaseAuthService: SupabaseAuthService,
    ) {

        this.categoriesFormControl.valueChanges.subscribe(value => {
            if (value !== null && value !== undefined) {
                this.selectedCategories = value;
            }
        });

        this.playtimesFormControl = new FormControl(
            [],
            [this.minPlayTimesValidator(1)],
        );
    }

    ngOnInit(): void {
        this.enableFilterExternalAgencies();
        this.getAllCategories();
        this.listenToSelectedCurrency();
        this.enableAddPerformerForm();
        this.listenToSelectedAgency();

        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.getAllExternalAgencies(agency);
            }
        });
    }

    public enableAddPerformerForm() {
        this.addPerformerFormGroup = this.addPerformerFormBuilder.group({
            name: ["", Validators.required],
            email: ["", Validators.required],
            description: ["", Validators.required],
            price: ["", Validators.required],
            cost: ["", Validators.required],
            performerrider: ["", Validators.required],
            presskit: ["", Validators.required],
            var: ["", Validators.required],
            playtimes: this.playtimesFormControl,
            categories: this.categoriesFormControl,
            picture: ['', Validators.required],
            externalAgency: [""],
            exclusiveArtist: ["", Validators.required],
        }, { validators: [externalAgencyRequiredValidator] });
    }

    get addPerformerFormGroupControls() {
        return this.addPerformerFormGroup.controls;
    }

    public listenToSelectedCurrency() {
        this.currencyService.selectedCurrency$.subscribe((currency) => {
            if (currency) {
                this.selectedCurrency = currency;
            }
        });
    }

    public listenToSelectedAgency() {
        this.agencyService.selectedAgency$.subscribe((agency) => {
            if (agency) {
                this.selectedAgency = agency;
            }
        });
    }

    public onRiderFileChange(event: any) {
        if (event.target.files.length > 0) {
            this.performerRider = event.target.files[0];
        }
    }

    public onPresskitFileChange(event: any) {
        if (event.target.files.length > 0) {
            this.pressKit = event.target.files[0];
        }
    }

    public onVarFileChange(event: any) {
        if (event.target.files.length > 0) {
            this.var = event.target.files[0];
        }
    }

    public addPlayTime(event: MatChipInputEvent) {
        const value = (event.value || "").trim();

        if (value) {
            this.playTimes.push(value);
        }

        event.chipInput!.clear();
    }

    public removePlayTime(playtime: string) {
        const index = this.playTimes.indexOf(playtime);

        if (index >= 0) {
            this.playTimes.splice(index, 1);
        }
    }

    public editPlaytime(playtime: string, event: MatChipEditedEvent) {
        const value = event.value.trim();

        if (!value) {
            this.removePlayTime(playtime);
            return;
        }

        const index = this.playTimes.indexOf(playtime);
        if (index >= 0) {
            this.playTimes[index] = value;
        }
    }

    private requireMinCategories(min: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const selected = control.value;
            return selected.length >= min
                ? null
                : { minCategoriesRequired: { value: control.value } };
        };
    }

    public minPlayTimesValidator(min: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const playtimes = control.value;
            return Array.isArray(playtimes) && playtimes.length >= min ? null : { minPlayTimes: { requiredMin: min } };
        };
    }

    public getAllCategories() {
        this.categoryService
            .getAllCategories()
            .then((categories) => {
                this.categories = categories;
            })
            .catch((error) => {
                console.error("Failed to load categories:", error);
            });
    }

    public getAllExternalAgencies(agency: Agency) {
        this.agencyExternalService.getAllAgenciesExternal(agency).then(externalAgencies => {
            this.externalAgencies = externalAgencies;
        });
    }

    public enableFilterExternalAgencies() {
        this.filteredExternalAgencies = this.externalAgencyFormControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                const name = typeof value === 'string' ? value : value?.name;
                return name ? this._filterExternalAgencies(name as string) : this.externalAgencies.slice();
            })
        );
    }

    private _filterExternalAgencies(value: string): Agency_External[] {
        const filterValue = value.toLowerCase();
        return this.externalAgencies.filter(agency =>
            agency.name.toLowerCase().includes(filterValue) ||
            agency.city.toLowerCase().includes(filterValue)
        );
    }

    private _filteredExternalAgencies(value: any): Agency_External[] {
        let filterValue = '';
        if (typeof value === 'string') {
            filterValue = value.toLowerCase();
        } else if (value && typeof value === 'object' && value.name) {
            filterValue = value.name.toLowerCase();
        }
        return this.externalAgencies.filter(externalAgency => externalAgency.name.toLowerCase().includes(filterValue));
    }

    public displayFn(agency: Agency_External): string {
        return agency && agency.name ? `${agency.name} - ${agency.city}` : '';
    }

    public openAddPerformerMemberDialog(
        enterAnimationDuration: string,
        exitAnimationDuration: string
    ) {
        const addPerformerMemberDialogRef = this.matDialog.open(
            AddPerformerMemberDialogComponent,
            {
                width: "500px",
                enterAnimationDuration,
                exitAnimationDuration,
            },
        );

        addPerformerMemberDialogRef.afterClosed().subscribe((result) => {
            if (result != null) {
                this.members.push(result);
                console.log("Members: " + JSON.stringify(this.members));
            }
        });
    }

    public openAddCategoryDialog(
        enterAnimationDuration: string,
        exitAnimationDuration: string,
    ): void {
        const categoryDialogRef = this.matDialog.open(
            AddCategoryDialogComponent,
            {
                width: "500px",
                enterAnimationDuration,
                exitAnimationDuration,
            },
        );

        categoryDialogRef.afterClosed().subscribe((result) => {
            if (result != null) {
                this.categories.push(result);
                const currentValue = this.categoriesFormControl.value || [];
                this.categoriesFormControl.setValue([...currentValue, result.id]);
            }
        });
    }

    public openAddProductDialog(
        enterAnimationDuration: string,
        exitAnimationDuration: string,
    ): void {
        const productDialogRef = this.matDialog.open(
            AddProductDialogComponent,
            {
                width: "600px",
                enterAnimationDuration,
                exitAnimationDuration,
                data: this.selectedCurrency
            },
        );

        productDialogRef.afterClosed().subscribe((result) => {
            if (result != null && typeof result === "object") {
                this.performerProducts.push(result);
            }
        });
    }

    public openAddExternalAgencyDialog(enterAnimationDuration: string,
        exitAnimationDuration: string,
    ): void {
        const externalAgencyDialogRef = this.matDialog.open(
            AddAgencyExternalDialogComponent,
            {
                width: "600px",
                enterAnimationDuration,
                exitAnimationDuration,
            },
        );

        externalAgencyDialogRef.afterClosed().subscribe((result) => {
            if (result != null && typeof result === "object") {
                this.externalAgencies.push(result);
                this.externalAgencyFormControl.setValue(result);
                this.selectedExternalAgency = result;

                // Reset the filteredExternalAgencies to show all agencies
                this.filteredExternalAgencies = of(this.externalAgencies);

                // Trigger change detection
                this.externalAgencyFormControl.updateValueAndValidity();

                // Open the autocomplete dropdown
                this.externalAgencyFormControl.setErrors({'incorrect': true});
                this.externalAgencyFormControl.markAsTouched();
            }
        });
    }

    public onExternalAgencySelected(event: any) {
        this.selectedExternalAgency = event.option.value;
        this.externalAgencyFormControl.setValue(this.selectedExternalAgency);
    }

    public onSelect(event: any) {
        this.picture = [];
        this.existingPicture = null;

        if (event.addedFiles.length > 0) {
            this.picture.push(event.addedFiles[0]);
        }
        this.addPerformerFormGroup.controls['picture'].setValue(this.picture[0]);
    }

    public onRemove() {
        this.existingPicture = null;
        this.addPerformerFormGroup.controls['picture'].setValue(null);
    }

    public addPerformer() {
        this.addPerformerSubmitted = true;
        this.addPerformerFormGroup.markAllAsTouched();

        if (this.addPerformerFormGroup.invalid) {
            // NO NEED TO DO ANYTHING HERE
        } else {
            this.isLoading = true;

            // Selected Currency check
            if (!this.selectedCurrency || !this.selectedCurrency.currency_code) {
                throw new Error("Selected currency or its code is undefined");
            }

            // Selected Categories
            let categoriesControl = this.addPerformerFormGroup.get("categories");
            let selectedCategories = categoriesControl ? categoriesControl.value : null;

            const reader = new FileReader();
            reader.readAsDataURL(this.picture[0]);
            reader.onload = () => {
                if(reader.result) {

                    // Selected Agency check
                    if (!this.selectedAgency || !this.selectedAgency.id) {
                        throw new Error("Selected agency or its ID is undefined");
                    }

                    let performer: Performer = {
                        name: this.addPerformerFormGroup.controls["name"].value,
                        email: this.addPerformerFormGroup.controls["email"].value,
                        description: this.addPerformerFormGroup.controls["description"].value,
                        price: this.addPerformerFormGroup.controls["price"].value,
                        cost: this.addPerformerFormGroup.controls["cost"].value,
                        currency_code: this.selectedCurrency.currency_code,
                        playtimes: this.playTimes,
                        agency_id: this.selectedAgency.id,
                        exclusivity: this.performerExclusivity,
                        currency_symbol: this.selectedCurrency.symbol,
                        picture: reader.result.toString()
                    };

                    if (!this.performerExclusivity) {
                        performer.agency_external_id = this.selectedExternalAgency.id;
                    }

                    this.performerService.addPerformer(performer, selectedCategories, this.performerProducts, this.members)
                        .then((performer) => {
                            if (!performer || !performer.id) {
                                this.notificationService.openFailureNotitication("Unable to add performer, and not able to continue with adding files");
                                throw new Error("Unable to add performer, and not able to continue with adding files");
                            } else {
                                Promise.all([
                                    this.performerService.addPerformerFiles(this.performerRider, this.selectedAgency, FileTypes.performer_rider, performer.id)
                                        .then((performerRiderPath) => {
                                            performer.performerrider = performerRiderPath;
                                        }),
                                    this.performerService.addPerformerFiles(this.pressKit, this.selectedAgency, FileTypes.performer_presskit, performer.id)
                                        .then((pressKitPath) => {
                                            performer.presskit = pressKitPath;
                                        }),
                                    this.performerService.addPerformerFiles(this.var, this.selectedAgency, FileTypes.performer_var, performer.id)
                                        .then((varPath) => {
                                            performer.var = varPath;
                                        })
                                ]).then(() => {
                                    // After all file paths are set, update the performer
                                    this.performerService.updatePerformerAttachments(performer).then((updatedPerformer) => {
                                        if (updatedPerformer) {

                                            this.notificationService.openSuccessfulNotification("Successfully added performer: " + performer.name + " to the agency: " + this.selectedAgency.name);
                                            this.router.navigate(["/performer-details", performer.id]);
                                        }
                                    }).catch((error) => {
                                        this.notificationService.openFailureNotitication("Failed to update performer, because: " + error.message);
                                    });
                                }).catch((error) => {
                                    this.notificationService.openFailureNotitication("Failed to add performer files, because: " + error.message);
                                }).finally(() => {
                                    this.isLoading = false;
                                });
                            }
                        }).catch((error) => {
                        this.notificationService.openFailureNotitication("Failed to add performer, because: " + error.message);
                    }).finally(() => {
                        //this.isLoading = false;
                        //NOTHING TO DO HERE ANYMORE
                    });
                }
            }
        }
    }

    public cancel() {
        this.location.back();
    }

    protected readonly open = open;
}
