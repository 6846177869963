import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomizerSettingsService} from '../../customizer-settings/customizer-settings.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SupabaseAuthService} from "../../services/supabase.auth.service";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnChanges {

    public registerUserFormGroup: FormGroup;
    public registerUserSubmitted = false;
    public isLoading = false;

    hide = true;
    public specificRoles: string[]= [];
    public selectedRole: string;

    constructor(public themeService: CustomizerSettingsService,
                private registerFormBuilder: FormBuilder,
                private supabaseAuthService: SupabaseAuthService,
                private notificationService: NotificationService,
                private router: Router) {}

    ngOnInit(): void {
        this.enableRegisterUserForm();
        this.getSpecificUserRoles();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['specificRoles'] && changes['specificRoles'].currentValue) {
            console.log('specific roles 1111')
            //this.specificRoles = changes['specificRoles'].currentValue;
            //this.getSpecificUserRoles();
        }
    }

    get registerUserFormGroupControls() {
        return this.registerUserFormGroup.controls;
    }

    public enableRegisterUserForm() {
        this.registerUserFormGroup = this.registerFormBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            password: ['', [Validators.required]],
            confirmpassword: ['', [Validators.required]],
            name: ['', [Validators.required]],
            role: ['', [Validators.required]]
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    public getSpecificUserRoles() {
        this.supabaseAuthService.getSpecificUserRoles().then(roles => {
            this.specificRoles = roles.map(role => role.roles);
            console.log('specific roles: ' + JSON.stringify(this.specificRoles));
        });
    }

    public registerUser() {
        this.registerUserSubmitted = true;

        if (this.registerUserFormGroup.invalid) {
            //DO NOTHING
        } else {
            this.isLoading = true;
            let name = this.registerUserFormGroup.controls['name'].value;
            let email = this.registerUserFormGroup.controls['email'].value;
            let password = this.registerUserFormGroup.controls['password'].value;
            let confirmpassword = this.registerUserFormGroup.controls['confirmpassword'].value;
            let role = this.registerUserFormGroup.controls['role'].value;

            // Check if password and confirm password match
            if (password !== confirmpassword) {
                console.log('Passwords do not match');
                this.notificationService.openFailureNotitication('Passwords do not match, please check again');
                return;
            }

            this.supabaseAuthService.checkUserAvailableAlreadyRegistered(email).then(userCheck => {
                //Check if user is already registered or not
                if(userCheck?.length > 0) {
                  this.notificationService.openFailureNotitication('User already registered');
                  return;
                } else {
                    //Register user if not already registered
                    this.supabaseAuthService.signUpRegister(email, password, name, role).then(user => {
                        if (user && user.id) {
                            this.router.navigate(['/confirm-email'], {
                                state: {
                                    email: user.email,
                                    name: user.user_metadata['name'],
                                    role: user.user_metadata['role']
                                }
                            });
                            this.isLoading = false;
                        }
                    });
                }
            }).catch((error) => {
              this.notificationService.openFailureNotitication(error.message);
            });
        }
    }
}
