<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">Edit Booking Product</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <form [formGroup]="editBookingProductFormGroup">
        <div class="row" *ngIf="bookingProduct">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Product Name</label>
                    <input type="text" formControlName="name" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['name'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['name'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label class="d-block fw-semibold">Description</label>
                    <input type="text" formControlName="description" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['description'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['description'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Category</label>
                    <input type="text" formControlName="category" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['category'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['category'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Sub Category</label>
                    <input type="text" formControlName="subcategory" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['subcategory'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['subcategory'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Cost</label>
                    <input type="number" formControlName="cost" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['cost'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['cost'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Price</label>
                    <input type="number" formControlName="price" class="input-control"
                           [ngClass]="{ 'is-invalid': editBookingProductSubmitted && editBookingProductFormGroupControls['price'].errors}"
                           [ngStyle]="{'border-color': editBookingProductSubmitted && editBookingProductFormGroupControls['price'].errors?.['required'] ? 'red' : 'grey'}">
                </div>
            </div>
        </div>

        <div class="text-end">
            <button type="button" class="gray" (click)="close()"><i class="ri-close-fill"></i> Cancel</button>

            <button mat-flat-button class="phobos" (click)="editBookingProduct()">Save</button>
        </div>
    </form>
</div>
