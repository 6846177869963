import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Booking_Performer, Booking_Performer_Hotel} from "../../../../common/model/model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSelectChange} from "@angular/material/select";
import {NotificationService} from "../../../../services/notification.service";

@Component({
    selector: 'add-performer-hotel-dialog',
    templateUrl: 'add-performer-hotel-dialog.component.html',
    styleUrls: ['add-performer-hotel-dialog.component.scss']
})
export class AddPerformerHotelDialogComponent implements OnInit {

    public addPerformerHotelFormGroup: FormGroup;
    public addPerformerHotelSubmitted = false;
    public bookingPerformers: Booking_Performer[] = [];
    public selectedPerformer: Booking_Performer;

    constructor(public dialogRef: MatDialogRef<AddPerformerHotelDialogComponent>,
                private addPerformerHotelFormBuilder: FormBuilder,
                private notificationService: NotificationService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    performers: Booking_Performer[],
                    bookingPerformerHotel?: Booking_Performer_Hotel
                }) {
        this.bookingPerformers = data.performers;
    }

    ngOnInit(): void {
        this.enableAddPerformerHotelDialogForm();

        if(this.data.bookingPerformerHotel) {
            this.populateForm(this.data.bookingPerformerHotel);
        }
    }

    get addPerformerHotelFormGroupControls() {
        return this.addPerformerHotelFormGroup.controls;
    }

    public enableAddPerformerHotelDialogForm() {
        this.addPerformerHotelFormGroup = this.addPerformerHotelFormBuilder.group({
            performer: ['',Validators.required],
            hotel_name: ['', Validators.required],
            hotel_address: ['',Validators.required],
            check_in_date: ['', Validators.required],
            check_in_time: ['', Validators.required]
        });
    }

    public populateForm(bookingPerformerHotel: Booking_Performer_Hotel) {
        // Find the matching performer object from the bookingPerformers array
        const selectedPerformer = this.bookingPerformers.find(
            performer => performer.performer_id === bookingPerformerHotel.performer_id
        );
        if(selectedPerformer) {
            this.addPerformerHotelFormGroup.patchValue({
                performer: selectedPerformer,
                hotel_name: bookingPerformerHotel.hotel_name,
                hotel_address: bookingPerformerHotel.hotel_address,
                check_in_date: bookingPerformerHotel.check_in_date,
                check_in_time: bookingPerformerHotel.check_in_time
            });
            this.selectedPerformer = selectedPerformer;

        } else {
            this.notificationService.openFailureNotitication('No matching performer found for this performer');
        }
    }

    public comparePerformers(performer1: Booking_Performer, performer2: Booking_Performer): boolean {
        return performer1 && performer2 ? performer1.performer_id === performer2.performer_id : performer1 === performer2;
    }

    public onPerformerSelected(event: MatSelectChange) {
        this.selectedPerformer = event.value;
    }

    public addPerformerHotel() {
        this.addPerformerHotelSubmitted = true;

        if (this.addPerformerHotelFormGroup.invalid) {

        } else {
            if (!this.selectedPerformer || !this.selectedPerformer.performer_id) {
                throw new Error('Performer is not selected');
            }

            const bookingPerformerHotel: Booking_Performer_Hotel = {
                id: this.data.bookingPerformerHotel?.id,
                performer_id: this.selectedPerformer.performer_id,
                hotel_name: this.addPerformerHotelFormGroup.controls['hotel_name'].value,
                hotel_address: this.addPerformerHotelFormGroup.controls['hotel_address'].value,
                check_in_date: this.addPerformerHotelFormGroup.controls['check_in_date'].value,
                check_in_time: this.addPerformerHotelFormGroup.controls['check_in_time'].value
            };

            this.dialogRef.close(bookingPerformerHotel);
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
