<mat-card
    class="mb-25 phobos-card status-box bg-main-color"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-content>
        <div class="item d-flex align-items-center justify-content-space-between text-white fw-semibold position-relative">
            Total Watched
            <span class="fw-normal"><i class="flaticon-bar-chart-4"></i> 66:15h</span>
        </div>
        <div class="item d-flex align-items-center justify-content-space-between text-white fw-semibold position-relative">
            Assignment Completed
            <span class="fw-normal">81%</span>
        </div>
        <div class="item d-flex align-items-center justify-content-space-between text-white fw-semibold position-relative">
            Monthly Progress
            <span class="fw-normal">56%</span>
        </div>
        <div class="item d-flex align-items-center justify-content-space-between text-white fw-semibold position-relative">
            Yearly Percentage
            <span class="fw-normal">76%</span>
        </div>
        <div class="item d-flex align-items-center justify-content-space-between text-white fw-semibold position-relative">
            Overall Pass Percentage
            <span class="fw-normal">67%</span>
        </div>
    </mat-card-content>
</mat-card>
