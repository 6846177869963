import {Component, Input, OnInit} from "@angular/core";
import {Booking_Contact, BookingDetails} from "../../../../common/model/model";
import {CustomizerSettingsService} from "../../../../customizer-settings/customizer-settings.service";
import {BookingService} from "../../../../services/booking.service";
import {MatDialog} from "@angular/material/dialog";
import {NotificationService} from "../../../../services/notification.service";
import {AddContactDialogComponent} from "../add-contact/add-contact-dialog.component";

@Component({
    selector: 'contactsinfo-component',
    templateUrl: './contacts-info.components.html',
    styleUrls: ['./contacts-info.components.scss']
})
export class ContactsInfoComponents implements OnInit {

    @Input() bookingDetails: BookingDetails;

    constructor(public themeService: CustomizerSettingsService,
                private bookingService: BookingService,
                private notificationService: NotificationService,
                private matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    public openAddContactDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addContactDialogRef = this.matDialog.open(AddContactDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails
            }
        });

        addContactDialogRef.afterClosed().subscribe((result: Booking_Contact) => {
            if(result != null && typeof result === 'object') {
                this.bookingService.addBookingContact(result).then(bookingContacts => {
                    this.notificationService.openSuccessfulNotification('Contact ' + bookingContacts[0].contact_name + ' added successfully');
                    this.bookingDetails.contacts.push(bookingContacts[0]);
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }

    public openEditContactDialog(enterAnimationDuration: string, exitAnimationDuration: string, contact: Booking_Contact) {
        const editContactDialogRef = this.matDialog.open(AddContactDialogComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails,
                bookingContact: contact
            }
        });

        editContactDialogRef.afterClosed().subscribe((result: Booking_Contact) => {
            if(result != null && typeof result === 'object') {
                this.bookingService.updateBookingContact(result).then(bookingContacts => {
                    this.notificationService.openSuccessfulNotification('Contact ' + bookingContacts[0].contact_name + ' updated successfully');
                    this.bookingDetails.contacts = this.bookingDetails.contacts.map(contact => {
                        if(contact.id === bookingContacts[0].id) {
                            return bookingContacts[0];
                        }

                        return contact;
                    });
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }

    public removeContact(contact: Booking_Contact) {
        this.bookingService.deleteBookingContact(contact).then(() => {
            this.bookingDetails.contacts = this.bookingDetails.contacts.filter(c => c.id !== contact.id);
            this.notificationService.openSuccessfulNotification('Successfully deleted contact ' + contact.contact_name);
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }
}
