import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BookingDetails} from "../../../../common/model/model";

@Component({
    selector: 'add-attachment-dialog-component',
    templateUrl: './add-attachment-dialog.component.html',
    styleUrls: ['./add-attachment-dialog.component.scss']
})
export class AddAttachmentDialogComponent implements OnInit {

    public addAttachmentFormGroup: FormGroup;
    public addAttachmentSubmitted = false;

    public selectedFiles: File[] = [];

    constructor(public dialogRef: MatDialogRef<AddAttachmentDialogComponent>,
                public addAttachmentFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: {
                    bookingDetails: BookingDetails,
                }) {

    }

    ngOnInit(): void {
        this.enableAddAttachmentForm();
    }

    public enableAddAttachmentForm() {
        this.addAttachmentFormGroup = this.addAttachmentFormBuilder.group({
            files: ['', Validators.required]
        });
    }

    public onFileSelected(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.files) {
            this.selectedFiles = Array.from(target.files);
            target.value = '';
        }
    }

    public removeFile(index: number) {
        this.selectedFiles.splice(index, 1);
    }

    public addAttachment() {
        this.addAttachmentSubmitted = true;
        if(this.addAttachmentFormGroup.invalid) {
        } else {
            this.dialogRef.close(this.selectedFiles);
        }
    }

    public close() {
        this.dialogRef.close(true);
    }
}
