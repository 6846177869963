import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Agency, Product_Category, Product_Subcategory} from "../common/model/model";
import {TABLE} from "../common/model/tables";

@Injectable()
export class ProductService {

    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllProductCategories(agency: Agency): Promise<Product_Category[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.PRODUCT_CATEGORIES)
            .select('*')
            .eq('agency_id', agency.id);

        if(error) {
            throw new Error("Failed to retrieve product categories, because: " + error.message);
        }
        return data as Product_Category[];
    }

    public async getAllProductSubCategories(agency: Agency, productCategory: Product_Category): Promise<Product_Subcategory[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.PRODUCT_SUBCATEGORIES)
            .select('*')
            .eq('agency_id', agency.id)
            .eq('product_category_id', productCategory.id);

        if(error) {
            throw new Error("Failed to retrieve product subcategories, because: " + error.message);
        }
        return data as Product_Subcategory[];
    }


    public async addProductCategory(productCategory: Product_Category): Promise<Product_Category[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.PRODUCT_CATEGORIES)
            .insert(productCategory)
            .select('*');

        if(error) {
            throw new Error("Failed to add product category, because: " + error.message);
        }

        return data;
    }

    public async addProductSubCategory(productSubCategory: Product_Subcategory): Promise<Product_Subcategory[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.PRODUCT_SUBCATEGORIES)
            .insert(productSubCategory)
            .select('*');

        if(error) {
            throw new Error("Failed to add product subcategory, because: " + error.message);
        }

        return data;
    }

}
