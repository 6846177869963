import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {TABLE} from "../common/model/tables";
import {Category} from "../common/model/model";

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(private supabaseService: SupabaseService) {
    }

    public async getAllCategories(): Promise<Category[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.CATEGORIES)
            .select('*');

        if(error) {
            throw new Error("Failed to retrieve categories, because: " + error.message);
        }

        return data as Category[];
    }

    public async addCategory(category: Category): Promise<Category[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from(TABLE.CATEGORIES)
            .insert(category)
            .select();

        if(error) {
            throw new Error("Failed to add category, because: " + error.message);
        }
        return data as Category[];
    }
}
