<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Attachments</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddAttachmentDialog('300ms','100ms')">
                <i class="ri-add-fill"></i>Add Attachment
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.attachments.length < 1">No attachments added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.attachments.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">File Name</th>
                        <th class="fw-semibold main-color">MIME Type</th>
                        <th class="fw-semibold main-color">Uploaded Date</th>
                        <th class="fw-semibold main-color">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let attachment of bookingDetails.attachments">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/folder.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{attachment.file_name}}</h5>
                            </div>
                        </td>
                        <td class="gray-color">{{attachment.mime_type}}</td>
                        <td class="gray-color">{{attachment.uploaded_date}}</td>
                        <td>
                            <button mat-flat-button class="gray me-15" (click)="deleteAttachment(attachment)">Remove</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
