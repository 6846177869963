import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {AgencyService} from "../services/agency.service";
import {Observable} from "rxjs";
import {inject, Injectable} from "@angular/core";
import {map} from "rxjs/operators";

@Injectable()
export class AgencyGuardService {

    constructor(private router: Router, private agencyService: AgencyService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.agencyService.selectedAgency$.pipe(
            map(agency => {
                if (agency) {
                    return true;
                } else {
                    this.router.navigate(['/']);
                    return false;
                }
            })
        );
    }
}

export const AgencyGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
    return inject(AgencyGuardService).canActivate(next,state);
}
