<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Flights</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddPerformerFlightDialog('300ms','100ms')">
                <i class="ri-add-fill"></i>Add Flight
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.booking_performer_flights.length < 1">No Flights added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.booking_performer_flights.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Performer</th>
                        <th class="fw-semibold main-color">Flight#</th>
                        <th class="fw-semibold main-color">From</th>
                        <th class="fw-semibold main-color">To</th>
                        <th class="fw-semibold main-color">Date</th>
                        <th class="fw-semibold main-color">Time</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let booking_performer_flight of bookingDetails.booking_performer_flights">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/flight.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{getPerformerName(booking_performer_flight.performer_id)}}</h5>
                            </div>
                        </td>
                        <td>{{booking_performer_flight.flight_number}}</td>
                        <td>{{booking_performer_flight.from_departure}}</td>
                        <td>{{booking_performer_flight.to_destination}}</td>
                        <td>{{booking_performer_flight.flight_date}}</td>
                        <td>{{booking_performer_flight.flight_time}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="flightActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #flightActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editAddPerformerFlightDialog(booking_performer_flight,'300ms', '100ms')">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeBookingFlight(booking_performer_flight)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Hotels</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddPerformerHotelDialog('300ms', '100ms')">
                <i class="ri-add-fill"></i>Add Hotel
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.booking_performer_hotels.length < 1">No Hotels added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.booking_performer_hotels.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Performer</th>
                        <th class="fw-semibold main-color">Hotel Name</th>
                        <th class="fw-semibold main-color">Hotel Address</th>
                        <th class="fw-semibold main-color">Check-In Date</th>
                        <th class="fw-semibold main-color">Check-In Time</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let booking_performer_hotel of bookingDetails.booking_performer_hotels">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/hotel.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{getPerformerName(booking_performer_hotel.performer_id)}}</h5>
                            </div>
                        </td>
                        <td>{{booking_performer_hotel.hotel_name}}</td>
                        <td>{{booking_performer_hotel.hotel_address}}</td>
                        <td>{{booking_performer_hotel.check_in_date}}</td>
                        <td>{{booking_performer_hotel.check_in_time}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="hotelActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #hotelActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editAddPerformerHotelDialog(booking_performer_hotel, '300ms', '100ms')">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeBookingHotel(booking_performer_hotel)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>
