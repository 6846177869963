<div *ngIf="bookingDetails">
    <mat-card class="mb-25 phobos-card">
        <mat-card-header>
            <h5 class="mb-0">Contact</h5>
            <a mat-stroked-button class="default-btn phobos" (click)="openAddContactDialog('300ms', '100ms')">
                <i class="ri-add-fill"></i>Add Contact
            </a>
        </mat-card-header>
        <mat-card-content>
            <mat-label *ngIf="bookingDetails.contacts.length < 1">No contact persons added yet</mat-label>
            <div class="recent-files-table table-responsive">
                <table class="table" *ngIf="bookingDetails.contacts.length >= 1">
                    <thead >
                    <tr>
                        <th class="fw-semibold main-color">Name</th>
                        <th class="fw-semibold main-color">Role</th>
                        <th class="fw-semibold main-color">Phone Number</th>
                        <th class="fw-semibold main-color">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let contactPerson of bookingDetails.contacts">
                        <td>
                            <div class="name-info d-flex align-items-center">
                                <img src="assets/img/icon/guest.png" alt="folder">
                                <h5 class="fw-semibold mb-0">{{contactPerson.contact_name}}</h5>
                            </div>
                        </td>
                        <td class="gray-color">{{contactPerson.contact_role}}</td>
                        <td class="gray-color">{{contactPerson.contact_phone}}</td>
                        <td>
                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                 (click)="trigger.openMenu()"
                                 #trigger="matMenuTrigger"
                                 [matMenuTriggerFor]="contactActionMenu">
                                <i class="ri-more-fill"></i>
                            </div>
                            <mat-menu #contactActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="openEditContactDialog('300ms', '100ms', contactPerson)">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeContact(contactPerson)">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

</div>
