import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {AgencyExternalService} from "../../../services/agencyexternal.service";
import {Agency, Agency_External} from "../../../common/model/model";
import {AgencyService} from "../../../services/agency.service";
import {NotificationService} from "../../../services/notification.service";

@Component({
    selector: 'add-agency-external-dialog',
    templateUrl: './add-agency-external-dialog.component.html',
    styleUrls: ['./add-agency-external-dialog.component.scss']
})
export class AddAgencyExternalDialogComponent implements OnInit {

    public selectedAgency: Agency;
    public addAgencyExternalFormGroup: FormGroup;
    public addAgencyExternalSubmitted = false;

    constructor(public dialogRef: MatDialogRef<AddAgencyExternalDialogComponent>,
                private addAgencyExternalFormBuilder: FormBuilder,
                private agencyExternalService: AgencyExternalService,
                private agencyService: AgencyService,
                private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.enableAddAgencyExternalDialogForm();
        this.listenToSelectedAgency();
    }

    get addAgencyExternalFormGroupControls() {
        return this.addAgencyExternalFormGroup.controls;
    }

    public enableAddAgencyExternalDialogForm() {
        this.addAgencyExternalFormGroup = this.addAgencyExternalFormBuilder.group({
            name: ['', Validators.required],
            address: ['', Validators.required],
            postcode: ['', Validators.required],
            city: ['', Validators.required],
            country: ['', Validators.required],
            phone: ['', Validators.required],
            website: ['', Validators.required],
            email: ['', Validators.required]
        });
    }

    public close() {
        this.dialogRef.close(true);
    }

    public listenToSelectedAgency() {
        this.agencyService.selectedAgency$.subscribe((agency) => {
            if (agency) {
                this.selectedAgency = agency;
            }
        });
    }

    public saveAgencyExternal() {
        this.addAgencyExternalSubmitted = true;

        if(this.addAgencyExternalFormGroup.invalid) {
        } else {

            //this.addPerformerFormGroup.controls["name"].value,
            let agencyExternal: Agency_External = {
                name: this.addAgencyExternalFormGroup.controls["name"].value,
                address: this.addAgencyExternalFormGroup.controls["address"].value,
                postcode: this.addAgencyExternalFormGroup.controls["postcode"].value,
                city: this.addAgencyExternalFormGroup.controls["city"].value,
                country: this.addAgencyExternalFormGroup.controls["country"].value,
                phone: this.addAgencyExternalFormGroup.controls["phone"].value,
                website: this.addAgencyExternalFormGroup.controls["website"].value,
                email: this.addAgencyExternalFormGroup.controls["email"].value,
                agency_id: this.selectedAgency.id
            };

            console.log('object: ' + JSON.stringify(agencyExternal));

            this.agencyExternalService.addAgencyExternal(agencyExternal).then(agencyExternal => {
                this.notificationService
                    .openSuccessfulNotification('External agency: ' + agencyExternal.name + ' added successfully.');
                this.dialogRef.close(agencyExternal);
            }).catch(error => {
                this.notificationService.openFailureNotitication(error.message);
            });
        }
    }
}
