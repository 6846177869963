import {Component, Input, OnInit} from "@angular/core";
import {Booking_Attachment, BookingDetails} from "../../../../common/model/model";
import {MatDialog} from "@angular/material/dialog";
import {BookingService} from "../../../../services/booking.service";
import {NotificationService} from "../../../../services/notification.service";
import {AddAttachmentDialogComponent} from "../add-attachment/add-attachment-dialog.component";
import {error} from "echarts/types/src/util/log";

@Component({
    selector: 'attachments-info',
    templateUrl: './attachments-info.component.html',
    styleUrls: ['./attachments-info.component.scss']
})
export class AttachmentsInfoComponent implements OnInit {

    @Input() bookingDetails: BookingDetails;

    constructor(private matDialog: MatDialog,
                private bookingService: BookingService,
                private notificationService: NotificationService) {
    }

    ngOnInit(): void {
    }

    public openAddAttachmentDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addAttachmentDialog = this.matDialog.open(AddAttachmentDialogComponent, {
            width: '800px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails
            }
        });

        addAttachmentDialog.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let selectedFiles = result as File[];

                this.bookingService.uploadBookingAttachments(selectedFiles, this.bookingDetails.booking, this.bookingDetails.booking.agency_id).then(addedAttachment => {
                    this.bookingService.insertBookingAttachment(addedAttachment).then(areAttachmentsAdded => {
                        if(areAttachmentsAdded) {
                            this.bookingDetails.attachments.push(...addedAttachment);
                            this.notificationService.openSuccessfulNotification('Attachment(s) added successfully');
                        }
                    }).catch(error => {
                        this.notificationService.openFailureNotitication(error.message);
                    });
                }).catch(error => {
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }

    public deleteAttachment(attachment: Booking_Attachment) {
        this.bookingService.deleteBookingAttachment(attachment).then(deletedAttachment => {
            if(deletedAttachment) {
                this.bookingDetails.attachments = this.bookingDetails.attachments.filter(a => a.id !== attachment.id);
                this.notificationService.openSuccessfulNotification('Attachment deleted successfully');
            }
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }
}
