import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {InvoiceService} from "../../../services/invoice.service";
import {AgencyService} from "../../../services/agency.service";
import {Agency, Invoice} from "../../../common/model/model";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";

@Component({
    selector: 'invoices-component',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, AfterViewInit {

    public selectedAgency: Agency;
    public invoices: Invoice[] = [];

    dataSource = new MatTableDataSource<Invoice>(this.invoices);
    displayedColumns: string[] = ['number', 'status', 'description', 'amount', 'date', 'due date', 'actions'];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private invoiceService: InvoiceService,
                private agencyService: AgencyService,
                public themeService: CustomizerSettingsService) { }

    ngOnInit() {
        this.agencyService.selectedAgency$.subscribe(agency => {
            this.selectedAgency = agency;
            this.getInvoices(agency);
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;

    }

    public getInvoices(agency: Agency) {
        this.invoiceService.getAllInvoices(agency).then(invoices => {
            this.invoices = invoices;
            this.dataSource.data = this.invoices;
        });
    }
}
