import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {
    Agency,
    Booking_Product,
    BookingDetails,
    Currency,
    Performer_Product_With_Performer
} from "../../../../common/model/model";
import {MatDialog} from "@angular/material/dialog";
import {AddBookingProductDialogComponent} from "../add-product/add-booking-product-dialog.component";
import {NotificationService} from "../../../../services/notification.service";
import {CurrencyService} from "../../../../services/currency.service";
import {BookingService} from "../../../../services/booking.service";
import {Subscription} from "rxjs";
import {BookingCommunicationService} from "../../../../services/booking-communication.service";

@Component({
    selector: 'products-info',
    templateUrl: './products-info.component.html',
    styleUrls: ['./products-info.component.scss']
})
export class ProductsInfoComponent implements OnInit, OnChanges, OnDestroy {

    @Input() bookingDetails: BookingDetails;
    @Input() agency: Agency;

    private bookingProductsSubscription: Subscription;

    public selectedCurrency: Currency;

    constructor(public matDialog: MatDialog,
                public notificationService: NotificationService,
                public bookingCommunicationService: BookingCommunicationService,
                public currencyService: CurrencyService,
                public bookingService: BookingService) {
    }

    ngOnInit(): void {
        this.bookingProductsSubscription = this.bookingCommunicationService.bookingProducts$
            .subscribe(products => {
                if (this.bookingDetails) {
                    this.bookingDetails.products = products;
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['bookingDetails'] && changes['bookingDetails'].currentValue) {
            this.selectedCurrency = this.currencyService.getSelectedCurrency();
            this.currencyService.selectedCurrency$.subscribe(currency => {
                this.selectedCurrency = currency;
            });

            if (this.bookingDetails && this.bookingDetails.booking && this.bookingDetails.booking.id) {
                this.bookingCommunicationService.refreshProducts(this.bookingDetails.booking.id);
            }
        }
    }

    ngOnDestroy(): void {
        if (this.bookingProductsSubscription) {
            this.bookingProductsSubscription.unsubscribe();
        }
    }

    public openAddBookingProductDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
        const addBookingProductDialog = this.matDialog.open(AddBookingProductDialogComponent, {
            width: '900px',
            enterAnimationDuration: enterAnimationDuration,
            exitAnimationDuration: exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails,
                agency: this.agency,
                currency: this.selectedCurrency
            }
        });

        addBookingProductDialog.afterClosed().subscribe(result => {
            if (result != null && typeof result === 'object') {

                if (Array.isArray(result) && result.length > 0 && 'performer_product' in result[0]) {
                    console.log('blablabla');
                    // Handle Performer_Product_With_Performer[]
                    this.handlePerformerProducts(result as Performer_Product_With_Performer[]);
                } else if ('booking_id' in result) {
                    // Handle single Booking_Product
                    this.handleBookingProduct(result as Booking_Product);
                } else {
                    console.error('Unknown result type:', result);
                }
            }
        });
    }

    private handlePerformerProducts(performerProducts: Performer_Product_With_Performer[]) {
        performerProducts.forEach(performerProduct => {
            if (this.isPerformerProductAdded(performerProduct)) {
                this.notificationService.openFailureNotitication('Performer product: ' + performerProduct.performer_product.name + ' is already added');
                return;
            }

            let bookingProduct: Booking_Product = {
                booking_id: this.bookingDetails.booking.id,
                name: performerProduct.performer_product.name,
                description: performerProduct.performer_product.description,
                price: performerProduct.performer_product.price,
                cost: performerProduct.performer_product.cost,
                category: "Performer Product",
                subcategory: performerProduct.performer.name,
                currency_code: this.selectedCurrency.currency_code,
                currency_symbol: this.selectedCurrency.symbol,
                performer_id: performerProduct.performer.performer_id
            };
            this.addBookingProduct(bookingProduct);
        });
    }

    private handleBookingProduct(bookingProduct: Booking_Product) {
        this.addBookingProduct(bookingProduct);
    }

    private addBookingProduct(bookingProduct: Booking_Product) {
        this.bookingService.addBookingProduct(bookingProduct).then(addedBookingProduct => {
            this.bookingDetails.products.push(bookingProduct);
            this.notificationService.openSuccessfulNotification('Product: ' + bookingProduct.name + ' added successfully');
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }

    public editBookingProduct(retrievedBookingProduct: Booking_Product, enterAnimationDuration: string, exitAnimationDuration: string) {
        const addBookingProductDialog = this.matDialog.open(AddBookingProductDialogComponent, {
            width: '900px',
            enterAnimationDuration: enterAnimationDuration,
            exitAnimationDuration: exitAnimationDuration,
            data: {
                bookingDetails: this.bookingDetails,
                agency: this.agency,
                currency: this.selectedCurrency,
                bookingProduct: retrievedBookingProduct
            }
        });

        addBookingProductDialog.afterClosed().subscribe(result => {
            if(result != null && typeof result === 'object') {
                let updatedBookingProduct = result as Booking_Product;
                this.bookingService.updateBookingProduct(updatedBookingProduct).then(receivedUpdatedProduct => {
                    this.bookingDetails.products = this.bookingDetails.products.map(product => product.id === receivedUpdatedProduct[0].id ? receivedUpdatedProduct[0] : product);
                    this.notificationService.openSuccessfulNotification('Product: ' + receivedUpdatedProduct[0].name + ' updated successfully');
                }).catch(error => {
                    console.log(error.message);
                    this.notificationService.openFailureNotitication(error.message);
                });
            }
        });
    }

    public getBookingProducts(bookingId: string) {
        this.bookingService.getBookingProducts(bookingId).then((bookingProducts: Booking_Product[]) => {
            console.log('get booking products called');
            console.log('new overview of products: ' + JSON.stringify(bookingProducts));
            this.bookingDetails.products = bookingProducts;
        });
    }

    public isPerformerProductAdded(performerProduct: Performer_Product_With_Performer): boolean {
        return this.bookingDetails.products.some(product => product.name === performerProduct.performer_product.name);
    }

    public deleteBookingProduct(bookingProduct: Booking_Product) {
        this.bookingService.deleteBookingProduct(bookingProduct).then(isDeleted => {
            if(isDeleted) {
                this.bookingDetails.products = this.bookingDetails.products.filter(product => product.id !== bookingProduct.id);
                this.notificationService.openSuccessfulNotification('Product: ' + bookingProduct.name + ' deleted successfully');
            }
        }).catch(error => {
            this.notificationService.openFailureNotitication(error.message);
        });
    }
}
