<ng-template #customLoadingTemplate>
    <div class="custom-class">
    </div>
</ng-template>

<mat-card class="mb-25 tagus-card">
    <ngx-loading
            [show]="isLoading"
            [config]="{ backdropBorderRadius: '4px' }"
            [template]="customLoadingTemplate"
    ></ngx-loading>
    <mat-card-content>
        <div class="recent-files-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th class="fw-semibold main-color">Event Name</th>
                    <th class="fw-semibold main-color">Event Date</th>
                    <th class="fw-semibold main-color">Event Status</th>
                    <th class="fw-semibold main-color">Customer</th>
                    <th class="fw-semibold main-color">Location</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let bookingSummary of bookingSummaries" (click)="openBookingDetails(bookingSummary)" class="cursor-pointer">
                    <td>
                        <div class="name-info d-flex align-items-center">
                            <img src="assets/img/icon/event_icon.png" alt="folder">
                            <h5 class="fw-semibold mb-0">{{bookingSummary.event_name}}</h5>
                        </div>
                    </td>
                    <td>{{bookingSummary.event_date}}</td>
                    <td>{{bookingSummary.status}}</td>
                    <td>{{bookingSummary.customer_name}}</td>
                    <td>{{bookingSummary.location_name}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
