import {Component, OnInit} from '@angular/core';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SupabaseAuthService} from "../../services/supabase.auth.service";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {retry} from "rxjs";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    public registerUserFormGroup: FormGroup;
    public registerUserSubmitted = false;
    public isLoading = false;

    hide = true;

    constructor(public themeService: CustomizerSettingsService,
                private registerFormBuilder: FormBuilder,
                private supabaseAuthService: SupabaseAuthService,
                private notificationService: NotificationService,
                private router: Router) {}

    ngOnInit(): void {
        this.enableRegisterUserForm();
    }

    get registerUserFormGroupControls() {
        return this.registerUserFormGroup.controls;
    }

    public enableRegisterUserForm() {
        this.registerUserFormGroup = this.registerFormBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]],
            password: ['', [Validators.required]],
            confirmpassword: ['', [Validators.required]],
            name: ['', [Validators.required]]
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleCardBorderTheme() {
        this.themeService.toggleCardBorderTheme();
    }

    toggleCardBorderRadiusTheme() {
        this.themeService.toggleCardBorderRadiusTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    public registerUser() {
        this.registerUserSubmitted = true;

        if (this.registerUserFormGroup.invalid) {
            console.log('Invalid form');
        } else {
            this.isLoading = true;
            let name = this.registerUserFormGroup.controls['name'].value;
            let email = this.registerUserFormGroup.controls['email'].value;
            let password = this.registerUserFormGroup.controls['password'].value;
            let confirmpassword = this.registerUserFormGroup.controls['confirmpassword'].value;

            // Check if password and confirm password match
            if (password !== confirmpassword) {
                console.log('Passwords do not match');
                this.notificationService.openFailureNotitication('Passwords do not match, please check again');
                return;
            }

            this.supabaseAuthService.checkUserAvailableAlreadyRegistered(email).then(userCheck => {
                //Check if user is already registered or not
                if(userCheck?.length > 0) {
                  this.notificationService.openFailureNotitication('User already registered');
                  return;
                } else {
                    //Register user if not already registered
                    this.supabaseAuthService.signUpRegister(email, password, name).then(user => {
                        if (user && user.id) {
                            this.router.navigate(['/confirm-email'], {
                                state: {
                                    email: user.email,
                                    name: user.user_metadata['name']
                                }
                            });
                            this.isLoading = false;
                        }
                    });
                }
            }).catch((error) => {
              this.notificationService.openFailureNotitication(error.message);
            });
        }
    }
}
