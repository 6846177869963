import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Booking_Performer, Performer_Product, Performer_Product_With_Performer} from "../../../../common/model/model";
import {PerformerService} from "../../../../services/performer.service";

@Component({
    selector: 'app-add-performer-product-dialog',
    templateUrl: './add-performer-product-dialog.component.html',
    styleUrls: ['./add-performer-product-dialog.component.scss']

})
export class AddPerformerProductDialogComponent implements OnInit {

    public performerProductsWithPerformer: Performer_Product_With_Performer[] = [];
    private selectedProducts: Set<Performer_Product_With_Performer> = new Set();

    constructor(public dialogRef: MatDialogRef<AddPerformerProductDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public bookingPerformers: Booking_Performer[],
                private performerService: PerformerService) {

    }

    ngOnInit() {
        this.getPerformerProducts(this.bookingPerformers);
    }

    public close() {
        this.dialogRef.close(true);
    }

    public getPerformerProducts(bookingPerformers: Booking_Performer[]) {
        bookingPerformers.forEach(performer => {

            if (!performer || !performer.performer_id) {
                throw new Error('Performer or its ID is undefined, thus unable to get performer products');
            }

            this.performerService.getPerformerProducts(performer.performer_id).then(performerProducts => {
                performerProducts.forEach(performerProduct => {
                    let performerProductWithPerformer: Performer_Product_With_Performer = {
                        performer_product: performerProduct,
                        performer: performer
                    }

                    this.performerProductsWithPerformer.push(performerProductWithPerformer);
                });
            });
        });
    }

    public onSelectionChange(product: Performer_Product_With_Performer, isChecked: boolean) {
        if (isChecked) {
            this.selectedProducts.add(product);
        } else {
            this.selectedProducts.delete(product);
        }
    }

    public isSelected(product: Performer_Product_With_Performer): boolean {
        return this.selectedProducts.has(product);
    }

    public addPerformerProducts() {
        const selectedProductsArray = Array.from(this.selectedProducts);
        this.dialogRef.close(selectedProductsArray);
    }
}


