<div class="create-dialog-box">
    <div class="title d-flex align-items-center justify-content-space-between">
        <h4 class="mb-0">{{data.bookingPerformer ? 'Update Performer' : 'Add Performer'}}</h4>
        <button class="close-btn bg-transparent p-0 border-none" (click)="close()"><i class="flaticon-close"></i></button>
    </div>
    <mat-card class="mb-25 create-project-card phobos-card">
        <form [formGroup]="addPerformerFormGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Performer</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['performer'].errors && addPerformerSubmitted}">
                                <i class="ri-account-circle-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFormGroup.get('performer')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Select Performer
                                </mat-label>
                                <mat-select formControlName="performer" (selectionChange)="onPerformerSelected($event)">
                                    <mat-option *ngFor="let performer of performers" [value]="performer">{{performer.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12" *ngIf="selectedPerformer">
                    <div class="phobos-form-group">
                        <b>Play times:</b> {{transformPlaytimes()}}
                    </div>
                </div>
                <br>
                <br>
                <br>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Start Time</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['start_time'].errors && addPerformerSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFormGroup.get('start_time')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Start Time
                                </mat-label>
                                <input type="time" matInput formControlName="start_time">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Endf Time</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['end_time'].errors && addPerformerSubmitted}">
                                <i class="ri-sticky-note-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFormGroup.get('end_time')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    End Time
                                </mat-label>
                                <input type="time" matInput formControlName="end_time">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="d-block fw-semibold">Status</label>
                        <mat-card-content>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['status'].errors && addPerformerSubmitted}">
                                <i class="ri-menu-search-line"></i>
                                <mat-label>Select Status</mat-label>
                                <mat-select [(value)]="selectedBookingPerformerStatus" formControlName="status">
                                    <mat-option *ngFor="let bookingPerformerStatus of bookingPerformerStatuses" [value]="bookingPerformerStatus">{{bookingPerformerStatus}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <mat-card-content>
                        <mat-checkbox formControlName="buma_fee" class="example-margin">Buma Fee (7%)</mat-checkbox>
                    </mat-card-content>
                </div>
                <br>
                <br>
                <br>
                <div class="text-end">
                    <button mat-flat-button class="gray" (click)="close()">Cancel</button>

                    <button mat-flat-button class="phobos" (click)="addPerformer()">
                        {{data.bookingPerformer ? 'Update Performer' : 'Add Performer'}}
                    </button>
                </div>
            </div>
        </form>

    </mat-card>
</div>
