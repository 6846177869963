<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Booking</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/bookings">
                Bookings
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Booking</li>
    </ol>
</div>

<ng-template #customLoadingTemplate>
    <div class="custom-class">
        <h3>Loading.......................</h3>
    </div>
</ng-template>

<mat-card class="mb-25 phobos-card">
    <ngx-loading
        [show]="isLoading"
        [config]="{ backdropBorderRadius: '4px' }"
        [template]="customLoadingTemplate"
    ></ngx-loading>
    <mat-card-content>
        <mat-stepper class="phobos-wizard" #bookingStepper orientation="vertical">
            <mat-step>
                <ng-template matStepLabel>Main Information</ng-template>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form [formGroup]="addBookingInformationFormGroup">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Event Name</label>
                                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingFormGroupControls['eventname'].errors && addBookingSubmitted}">
                                                <i class="ri-book-line"></i>
                                                <mat-label
                                                    [ngStyle]="{'color': (addBookingInformationFormGroup.get('eventname')?.hasError('required') && addBookingSubmitted) ? '#d73a49' : 'inherit'}">
                                                    Enter the name of the event
                                                </mat-label>
                                                <input formControlName="eventname" matInput
                                                       [ngClass]="{ 'is-invalid': addBookingSubmitted && addBookingFormGroupControls['eventname'].errors}"
                                                       [ngStyle]="{'border-color': addBookingSubmitted && addBookingFormGroupControls['eventname'].errors?.['required'] ? 'red' : 'grey'}">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Event date</label>
                                            <mat-form-field appearance="fill" class="date-input" [ngClass]="{'error-border': addBookingFormGroupControls['eventdate'].errors && addBookingSubmitted}">
                                                <mat-label
                                                    [ngStyle]="{'color': (addBookingInformationFormGroup.get('eventdate')?.hasError('required') && addBookingSubmitted) ? '#d73a49' : 'inherit'}">
                                                    MM/DD/YYYY
                                                </mat-label>
                                                <input formControlName="eventdate" matInput [matDatepicker]="picker"
                                                       [ngClass]="{ 'is-invalid': addBookingSubmitted && addBookingFormGroupControls['eventdate'].errors}"
                                                       [ngStyle]="{'border-color': addBookingSubmitted && addBookingFormGroupControls['eventdate'].errors?.['required'] ? 'red' : 'grey'}">
                                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form [formGroup]="addPerformersFormGroup">
                                    <div class="row">
                                        <label class="d-block mb-12 fw-semibold">Add Performer</label>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformersFormGroupControls['performer'].errors && addPerformerSubmitted}">
                                                    <i class="ri-file-music-line"></i>
                                                    <mat-label>Select Performer</mat-label>
                                                    <mat-select [(value)]="selectedPerformer" formControlName="performer">
                                                        <mat-option *ngFor="let performer of performers" [value]="performer">{{performer.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformersFormGroupControls['starttime'].errors && addPerformerSubmitted}">
                                                    <i class="ri-time-line"></i>
                                                    <mat-label>Start time</mat-label>
                                                    <input type="time" matInput formControlName="performer_starttime" name="startTime" type="time" formControlName="starttime">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformersFormGroupControls['endtime'].errors && addPerformerSubmitted}">
                                                    <i class="ri-time-line"></i>
                                                    <mat-label>End Time</mat-label>
                                                    <input matInput formControlName="performer_endtime" name="endTime" type="time" formControlName="endtime">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformersFormGroupControls['performer_status'].errors && addPerformerSubmitted}">
                                                    <i class="ri-menu-search-line"></i>
                                                    <mat-label>Select Status</mat-label>
                                                    <mat-select [(value)]="selectedBookingPerformerStatus" formControlName="performer_status">
                                                        <mat-option *ngFor="let bookingPerformerStatus of bookingPerformerStatuses" [value]="bookingPerformerStatus">{{bookingPerformerStatus}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <div class="mt-5">
                                                    <button mat-flat-button class="phobos" (click)="addPerformerBooking()">Add Performer</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <mat-card-content>
                                            <mat-checkbox formControlName="buma_fee" class="example-margin">Buma Fee (7%)</mat-checkbox>
                                        </mat-card-content>
                                    </div>
                                    <br>
                                    <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="selectedPerformer">
                                        <div class="phobos-form-group">
                                            <b>Play times:</b> {{transformPlaytimes()}}
                                        </div>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="mb-25 phobos-card" [formGroup]="addBookingInformationFormGroup" [ngClass]="{'error-border': addBookingFormGroupControls['performers'].errors && addBookingSubmitted}">
                            <mat-card-header>
                                <h5 class="mb-0">Added Performers</h5>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-label *ngIf="bookingPerformers.length < 1">No performers added yet</mat-label>
                                <div class="recent-files-table table-responsive">
                                    <table class="table" *ngIf="bookingPerformers.length >= 1">
                                        <thead >
                                        <tr>
                                            <th class="fw-semibold main-color">Performer</th>
                                            <th class="fw-semibold main-color">Start Time</th>
                                            <th class="fw-semibold main-color">End Time</th>
                                            <th class="fw-semibold main-color">Status</th>
                                            <th class="fw-semibold main-color">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let bookingPerformer of bookingPerformers">
                                            <td>
                                                <div class="name-info d-flex align-items-center">
                                                    <img src="assets/img/icon/performer.png" alt="folder">
                                                    <h5 class="fw-semibold mb-0">{{bookingPerformer.name}}</h5>
                                                </div>
                                            </td>
                                            <td>{{bookingPerformer.start_time}}</td>
                                            <td>{{bookingPerformer.end_time}}</td>
                                            <td>{{bookingPerformer.status}}</td>
                                            <td>
                                                <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenuPerformers">
                                                    <i class="ri-more-fill"></i>
                                                </button>
                                                <mat-menu #actionMenuPerformers="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editAddedBookingPerformer(bookingPerformer)">
                                                        <i class="ri-edit-2-line"></i> Edit
                                                    </button>
                                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeAddedBookingPerformer(bookingPerformer)">
                                                        <i class="ri-delete-bin-line"></i> Remove
                                                    </button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
<!--                                <mat-error *ngIf="addBookingSubmitted && addBookingFormGroupControls['performers'].errors">-->
                                <mat-error *ngIf="addBookingSubmitted && bookingPerformers.length == 0">
                                    You must add at least one performer.
                                </mat-error>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-lg-5 col-xl-4 col-md-12">
                        <product-summary-component
                            [groupedProducts]="groupedProducts"
                            [selectedCurrency]="selectedCurrency"
                            [bookingTotalCost]="bookingTotalCost"
                            [bookingTotalPrice]="bookingTotalPrice"
                            (modifyProducts)="goToProductsOverview()">
                        </product-summary-component>
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form>
                                    <mat-card-header class="border-bottom">
                                        <h5 class="mb-0">Customer</h5>
                                    </mat-card-header>
                                    <mat-form-field appearance="fill" [ngClass]="{'error-border': customerFormControl.hasError('required') && customerFormControl.touched}">
                                        <i class="ri-group-line"></i>
                                        <mat-label
                                                [ngStyle]="{'color': (customerFormControl.hasError('required') && customerFormControl.touched) ? '#d73a49' : 'inherit'}">
                                            Select Customer
                                        </mat-label>
                                        <mat-select [formControl]="customerFormControl" (selectionChange)="onCustomerSelected($event)">
                                            <mat-option *ngFor="let customer of customers" [value]="customer">{{customer.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button mat-stroked-button (click)="openAddCustomerDialog('300ms','100ms')">Add New Customer</button>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form>
                                    <mat-card-header class="border-bottom">
                                        <h5 class="mb-0">Location</h5>
                                    </mat-card-header>
                                    <mat-form-field class="example-full-width" appearance="fill" [ngClass]="{'error-border': locationFormControl.invalid && locationFormControl.touched}">
                                        <mat-label
                                                [ngStyle]="{'color': (locationFormControl.invalid && locationFormControl.touched) ? '#d73a49' : 'inherit'}">
                                            Location
                                        </mat-label>
                                        <i class="ri-map-pin-2-line"></i>
                                        <input matInput aria-label="State" [matAutocomplete]="auto" [formControl]="locationFormControl">
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLocationSelected($event)" [displayWith]="displayFn">
                                            <mat-option *ngFor="let location of filteredLocations | async" [value]="location">
                                                <img alt="flag" class="example-option-img" [src]="location.logo " width="20">
                                                <span>{{location.name}}</span> |
                                                <small>Occupation: {{location.occupation}}</small>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error *ngIf="locationFormControl.hasError('invalidLocation') && locationFormControl.touched">
                                            Please select a valid location from the list
                                        </mat-error>
                                    </mat-form-field>
                                    <button mat-stroked-button (click)="openAddLocationDialog('300ms','100ms')">Add New Location</button>
                                </form>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="mt-5">
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                    <button type="button" mat-flat-button class="phobos" style="margin-left: 10px" (click)="goToSaveStep()">
                        Go To Save
                    </button>
                </div>

            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Products & Packages</ng-template>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <label class="d-block mb-12 fw-semibold">Add a Custom Product</label>
                                <form [formGroup]="addBookingProductFormGroup">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-4">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_name'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-align-item-horizontal-center-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_name')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Name
                                                    </mat-label>
                                                    <input matInput formControlName="product_name">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_category'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-file-music-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_category')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Select Category
                                                    </mat-label>
                                                    <mat-select formControlName="product_category" [(value)]="selectedProductCategory" (selectionChange)="onCategoryChange()">
                                                        <mat-option *ngFor="let productCategory of productCategories" [value]="productCategory">{{productCategory.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_subcategory'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-file-music-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_subcategory')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Select Sub-Category
                                                    </mat-label>
                                                    <mat-select formControlName="product_subcategory" [(value)]="selectedProductSubCategory">
                                                        <mat-option *ngFor="let productSubCategory of productSubCategories" [value]="productSubCategory">
                                                            {{productSubCategory.name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 col-sm-2">
                                            <div class="phobos-form-group">
                                                <button mat-stroked-button class="phobos" (click)="openAddCategoriesDialog('300ms','100ms')">+ Categories</button>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_description'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-folder-info-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_description')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Description
                                                    </mat-label>
                                                    <input matInput formControlName="product_description">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_cost'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-price-tag-3-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_cost')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Cost ({{ selectedCurrency ? selectedCurrency.currency_code : '' }})
                                                    </mat-label>
                                                    <input type="number" matInput formControlName="product_cost">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3">
                                            <div class="phobos-form-group">
                                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addBookingProductFormGroupControls['product_price'].errors && addBookingProductSubmitted}">
                                                    <i class="ri-price-tag-3-line"></i>
                                                    <mat-label
                                                            [ngStyle]="{'color': (addBookingProductFormGroup.get('product_price')?.hasError('required') && addBookingProductSubmitted) ? '#d73a49' : 'inherit'}">
                                                        Price ({{ selectedCurrency ? selectedCurrency.currency_code : '' }})
                                                    </mat-label>
                                                    <input type="number" matInput formControlName="product_price">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <button mat-flat-button class="phobos" (click)="addBookingProduct()">Add Product</button>
                                    <button mat-flat-button class="phobos" (click)="openAddPerformerProductDialogComponent('300ms','100ms')" style="margin-left: 5px">
                                        Add Performer Product
                                    </button>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-card class="mb-25 phobos-card">
                            <mat-card-header>
                                <h5 class="mb-0">Added Custom Products</h5>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-label *ngIf="bookingProducts.length < 1">No products added yet</mat-label>
                                <div class="recent-files-table table-responsive">
                                    <table class="table" *ngIf="bookingProducts.length >= 1">
                                        <thead >
                                        <tr>
                                            <th class="fw-semibold main-color">Name</th>
                                            <th class="fw-semibold main-color">Sub Category</th>
                                            <th class="fw-semibold main-color">Category</th>
                                            <th class="fw-semibold main-color">Cost</th>
                                            <th class="fw-semibold main-color">Price</th>
                                            <th class="fw-semibold main-color">Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let bookingProduct of bookingProducts">
                                            <td>
                                                <div class="name-info d-flex align-items-center">
                                                    <img src="assets/img/icon/product.png" alt="folder">
                                                    <h5 class="fw-semibold mb-0">{{bookingProduct.name}}</h5>
                                                </div>
                                            </td>
                                            <td>{{bookingProduct.subcategory}}</td>
                                            <td>{{bookingProduct.category}}</td>
                                            <td>{{bookingProduct.cost}}</td>
                                            <td>{{bookingProduct.price}}</td>
                                            <td>
                                                <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                                     (click)="trigger.openMenu()"
                                                     #trigger="matMenuTrigger"
                                                     [matMenuTriggerFor]="actionMenu">
                                                    <i class="ri-more-fill"></i>
                                                </div>
                                                <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="editBookingProduct(bookingProduct)">
                                                        <i class="ri-edit-2-line"></i> Edit
                                                    </button>
                                                    <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeBookingProduct(bookingProduct)">
                                                        <i class="ri-delete-bin-line"></i> Remove
                                                    </button>
                                                </mat-menu>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-lg-5 col-xl-4 col-md-12">
                        <mat-card class="mb-25 phobos-card">
                            <mat-card-header>
                                <h5 class="mb-0">Products & Packages Summary</h5>
                            </mat-card-header>
                            <mat-divider class="mb-5"></mat-divider>
                            <mat-label *ngIf="groupedProducts.length < 1">
                                No products added yet
                            </mat-label>
                            <mat-card-content>
                                <form>
                                    <ul class="list ps-0 mb-0 mt-0 list-product-summary">
                                        <ng-container *ngFor="let group of groupedProducts">
                                            <li class="fw-bold">{{ group.category }}</li>
                                            <ng-container *ngFor="let subcategory of group.subcategories">
                                                <li class="fw-bold">{{ subcategory.subcategory }}</li>
                                                <ng-container *ngFor="let product of subcategory.products">
                                                    <li class="d-flex align-items-center justify-content-space-between">
                                                        {{ product.name }} :
                                                        <span class="fw-semibold">{{selectedCurrency.symbol}}{{product.price}}</span>
                                                    </li>
                                                </ng-container>
                                            </ng-container>
                                            <br>
                                            <mat-divider class="mb-5"></mat-divider>
                                            <br>
                                            <li class="d-flex fw-bold align-items-center justify-content-space-between">
                                                Total Cost {{selectedCurrency.currency_code}}:
                                                <span class="fw-semibold">{{selectedCurrency.symbol}}{{bookingTotalCost}}</span>
                                            </li>
                                            <li class="d-flex fw-bold align-items-center justify-content-space-between">
                                                Total Price {{selectedCurrency.currency_code}}:
                                                <span class="fw-semibold">{{selectedCurrency.symbol}}{{bookingTotalPrice}}</span>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </form>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="mt-5">
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Booking Information</ng-template>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form [formGroup]="addBookingInformationFormGroup">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Event Website</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-pages-line"></i>
                                                <mat-label>Website of the event</mat-label>
                                                <input formControlName="eventwebsite" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Event Budget</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-book-line"></i>
                                                <mat-label>Budget of the event</mat-label>
                                                <input formControlName="eventbudget" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Opening time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Opening time of the event</mat-label>
                                                <input type="time" formControlName="openingtime" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Begin time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Start time of the event</mat-label>
                                                <input type="time" formControlName="begintime" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">End time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>End time of the event</mat-label>
                                                <input type="time" formControlName="endtime" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Visitor Expectation</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Amount of visitors expected</mat-label>
                                                <input matInput formControlName="visitorexpectation">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Available Tickets</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Amount of tickets for sale</mat-label>
                                                <input matInput formControlName="availabletickets">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label class="d-block mb-12 fw-semibold">Booking Description</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Enter additional booking description here</mat-label>
                                                <textarea formControlName="bookingdescription" matInput rows="6"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label class="d-block mb-12 fw-semibold">Line-Up Event</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Line-Up of the event</mat-label>
                                                <textarea formControlName="lineupevent" matInput rows="6"></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label class="d-block mb-12 fw-semibold">Internal notes</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Enter any internal notes for yourself with regard to the event</mat-label>
                                                <textarea formControlName="internalnotes" matInput rows="6"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <br>
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form [formGroup]="addBookingInformationFormGroup">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Construction Start Time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Start of the construction</mat-label>
                                                <input type="time" formControlName="constructionstart" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Sound Check Time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Start of sound check</mat-label>
                                                <input type="time" formControlName="soundchecktime" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Podium Ready Time</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-time-line"></i>
                                                <mat-label>Time when the podium is ready</mat-label>
                                                <input type="time" formControlName="podiumready" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Performance Floor</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Floor on which the performance will take place</mat-label>
                                                <input formControlName="performancefloor" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Distance to Stage</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Distance to stage for equipment</mat-label>
                                                <input formControlName="distancetostage" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6">
                                            <label class="d-block mb-12 fw-semibold">Elevator Availability</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Information about availability of elevator</mat-label>
                                                <input formControlName="elevator" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <label class="d-block mb-12 fw-semibold">Call Sheet Memo</label>
                                            <mat-form-field appearance="fill">
                                                <i class="ri-information-line"></i>
                                                <mat-label>Relevant information regarding call sheet</mat-label>
                                                <textarea formControlName="callsheetmemo" matInput rows="6"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-lg-5 col-xl-4 col-md-12">
                        <product-summary-component
                            [groupedProducts]="groupedProducts"
                            [selectedCurrency]="selectedCurrency"
                            [bookingTotalCost]="bookingTotalCost"
                            [bookingTotalPrice]="bookingTotalPrice"
                            (modifyProducts)="goToProductsOverview()">
                        </product-summary-component>
                        <mat-card class="mb-25 create-project-card phobos-card">
                            <mat-card-content>
                                <form>
                                    <mat-card-header class="border-bottom">
                                        <h5 class="mb-0">Team Lead</h5>
                                    </mat-card-header>
                                    <mat-form-field appearance="fill">
                                        <i class="ri-group-line"></i>
                                        <mat-label>Select Member</mat-label>
                                        <mat-select [formControl]="members" multiple>
                                            <mat-option *ngFor="let member of membersList" [value]="member">{{member}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </form>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div class="mt-5">
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Add Contact Person(s)</ng-template>
                <form [formGroup]="addContactPersonFormGroup">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="phobos-form-group">
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactPersonFormGroupControls['contactname'].errors && addContactPersonSubmitted}">
                                    <i class="ri-user-line"></i>
                                    <mat-label
                                        [ngStyle]="{'color': (addContactPersonFormGroup.get('contactname')?.hasError('required') && addContactPersonSubmitted) ? '#d73a49' : 'inherit'}">
                                        Name Contact
                                    </mat-label>
                                    <input matInput formControlName="contactname">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="phobos-form-group">
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactPersonFormGroupControls['contactrole'].errors && addContactPersonSubmitted}">
                                    <i class="ri-user-settings-line"></i>
                                    <mat-label
                                        [ngStyle]="{'color': (addContactPersonFormGroup.get('contactrole')?.hasError('required') && addContactPersonSubmitted) ? '#d73a49' : 'inherit'}">
                                        Role
                                    </mat-label>
                                    <input matInput formControlName="contactrole">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="phobos-form-group">
                                <mat-form-field appearance="fill" [ngClass]="{'error-border': addContactPersonFormGroupControls['contactphone'].errors && addContactPersonSubmitted}">
                                    <i class="ri-phone-line"></i>
                                    <mat-label
                                        [ngStyle]="{'color': (addContactPersonFormGroup.get('contactphone')?.hasError('required') && addContactPersonSubmitted) ? '#d73a49' : 'inherit'}">
                                        Phone Number
                                    </mat-label>
                                    <input matInput formControlName="contactphone">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3">
                            <div class="phobos-form-group">
                                <div class="mt-5">
                                    <button mat-flat-button class="phobos" (click)="addContactPerson()">Add Contact</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-card class="mb-25 phobos-card">
                        <mat-card-header>
                            <h5 class="mb-0">Added Contact Persons</h5>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-label *ngIf="bookingContacts.length < 1">No contact persons added yet</mat-label>
                            <div class="recent-files-table table-responsive">
                                <table class="table" *ngIf="bookingContacts.length >= 1">
                                    <thead >
                                    <tr>
                                        <th class="fw-semibold main-color">Name</th>
                                        <th class="fw-semibold main-color">Role</th>
                                        <th class="fw-semibold main-color">Phone Number</th>
                                        <th class="fw-semibold main-color">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let contactPerson of bookingContacts; let i = index">
                                        <td>
                                            <div class="name-info d-flex align-items-center">
                                                <img src="assets/img/icon/contactperson.png" alt="folder">
                                                <h5 class="fw-semibold mb-0">{{contactPerson.contact_name}}</h5>
                                            </div>
                                        </td>
                                        <td class="gray-color">{{contactPerson.contact_role}}</td>
                                        <td class="gray-color">{{contactPerson.contact_phone}}</td>
                                        <td>
                                            <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                                 (click)="trigger.openMenu()"
                                                 #trigger="matMenuTrigger"
                                                 [matMenuTriggerFor]="contactActionMenu">
                                                <i class="ri-more-fill"></i>
                                            </div>
                                            <mat-menu #contactActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                                    <i class="ri-edit-2-line"></i> Edit
                                                </button>
                                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeAddedContactPerson(i)">
                                                    <i class="ri-delete-bin-line"></i> Remove
                                                </button>
                                            </mat-menu>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <div class="mt-5">
                        <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                        <button mat-flat-button matStepperNext class="phobos">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Travel Information</ng-template>
                <mat-card class="mb-25 create-project-card phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">All Flights</h5>
                        <button mat-stroked-button class="phobos" (click)="openAddPerformerFlightDialog('300ms', '300ms')"> + Add Flight</button>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-label *ngIf="bookingPerformerFlights.length < 1">No flights added yet</mat-label>
                        <div class="recent-files-table table-responsive">
                            <table class="table" *ngIf="bookingPerformerFlights.length >= 1">
                                <thead >
                                <tr>
                                    <th class="fw-semibold main-color">Performer</th>
                                    <th class="fw-semibold main-color">Flight #</th>
                                    <th class="fw-semibold main-color">From</th>
                                    <th class="fw-semibold main-color">To</th>
                                    <th class="fw-semibold main-color">Date</th>
                                    <th class="fw-semibold main-color">Time</th>
                                    <th class="fw-semibold main-color">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let bookingPerformerFlight of bookingPerformerFlights; let f = index">
                                    <td>
                                        <div class="name-info d-flex align-items-center">
                                            <img src="assets/img/icon/flight.png" alt="folder">
                                            <h5 class="fw-semibold mb-0">{{ getPerformerName(bookingPerformerFlight.performer_id) }}</h5>
                                        </div>
                                    </td>
                                    <td>{{bookingPerformerFlight.flight_number}}</td>
                                    <td>{{bookingPerformerFlight.from_departure}}</td>
                                    <td>{{bookingPerformerFlight.to_destination}}</td>
                                    <td>{{bookingPerformerFlight.flight_date | date: 'shortDate'}}</td>
                                    <td>{{bookingPerformerFlight.flight_time}}</td>
                                    <td>
                                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                             (click)="trigger.openMenu()"
                                             #trigger="matMenuTrigger"
                                             [matMenuTriggerFor]="flightActionMenu">
                                            <i class="ri-more-fill"></i>
                                        </div>
                                        <mat-menu #flightActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                                <i class="ri-edit-2-line"></i> Edit
                                            </button>
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeAddedFlight(f)">
                                                <i class="ri-delete-bin-line"></i> Remove
                                            </button>
                                        </mat-menu>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mb-25 create-project-card phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Added Hotels</h5>
                        <button mat-stroked-button class="phobos" (click)="openAddPerformerHotelDialog('300ms', '300ms')"> + Add Hotel</button>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-label *ngIf="bookingPerformerHotels.length < 1">No hotels added yet</mat-label>
                        <div class="recent-files-table table-responsive">
                            <table class="table" *ngIf="bookingPerformerHotels.length >= 1">
                                <thead >
                                <tr>
                                    <th class="fw-semibold main-color">Performer</th>
                                    <th class="fw-semibold main-color">Hotel Name</th>
                                    <th class="fw-semibold main-color">Hotel Address</th>
                                    <th class="fw-semibold main-color">Check-In Date</th>
                                    <th class="fw-semibold main-color">Check-In Time</th>
                                    <th class="fw-semibold main-color">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let bookingPerformerHotel of bookingPerformerHotels; let h = index">
                                    <td>
                                        <div class="name-info d-flex align-items-center">
                                            <img src="assets/img/icon/hotel.png" alt="folder">
                                            <h5 class="fw-semibold mb-0">{{ getPerformerName(bookingPerformerHotel.performer_id) }}</h5>
                                        </div>
                                    </td>
                                    <td>{{bookingPerformerHotel.hotel_name}}</td>
                                    <td>{{bookingPerformerHotel.hotel_address}}</td>
                                    <td>{{bookingPerformerHotel.check_in_date | date: 'shortDate'}}</td>
                                    <td>{{bookingPerformerHotel.check_in_time}}</td>
                                    <td>
                                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                             (click)="trigger.openMenu()"
                                             #trigger="matMenuTrigger"
                                             [matMenuTriggerFor]="hotelActionMenu">
                                            <i class="ri-more-fill"></i>
                                        </div>
                                        <mat-menu #hotelActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                                <i class="ri-edit-2-line"></i> Edit
                                            </button>
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeAddedHotel(h)">
                                                <i class="ri-delete-bin-line"></i> Remove
                                            </button>
                                        </mat-menu>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </mat-card-content>
                </mat-card>
                <div class="mt-5">
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Guest(s)</ng-template>
                <mat-card class="mb-25 create-project-card phobos-card">
                    <mat-card-content>
                        <form [formGroup]="addGuestFormGroup">
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <div class="phobos-form-group">
                                        <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guestname'].errors && addGuestSubmitted}">
                                            <i class="ri-user-line"></i>
                                            <mat-label
                                                [ngStyle]="{'color': (addGuestFormGroup.get('guestname')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                                Name Guest
                                            </mat-label>
                                            <input matInput formControlName="guestname">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <div class="phobos-form-group">
                                        <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guestemail'].errors && addGuestSubmitted}">
                                            <i class="ri-mail-line"></i>
                                            <mat-label
                                                [ngStyle]="{'color': (addGuestFormGroup.get('guestemail')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                                E-Mail Address
                                            </mat-label>
                                            <input matInput formControlName="guestemail">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <div class="phobos-form-group">
                                        <mat-form-field appearance="fill" [ngClass]="{'error-border': addGuestFormGroupControls['guestdescription'].errors && addGuestSubmitted}">
                                            <i class="ri-file-info-line"></i>
                                            <mat-label
                                                [ngStyle]="{'color': (addGuestFormGroup.get('guestdescription')?.hasError('required') && addGuestSubmitted) ? '#d73a49' : 'inherit'}">
                                                Description
                                            </mat-label>
                                            <input matInput formControlName="guestdescription">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                    <div class="phobos-form-group">
                                        <div class="mt-5">
                                            <button mat-flat-button class="phobos" (click)="addGuest()">Add Guest</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
                <mat-card class="mb-25 phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Added Guest(s)</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-label *ngIf="bookingGuests.length < 1">No guests added yet</mat-label>
                        <div class="recent-files-table table-responsive">
                            <table class="table" *ngIf="bookingGuests.length >= 1">
                                <thead >
                                <tr>
                                    <th class="fw-semibold main-color">Name</th>
                                    <th class="fw-semibold main-color">E-Mail</th>
                                    <th class="fw-semibold main-color">Description</th>
                                    <th class="fw-semibold main-color">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let bookingGuest of bookingGuests; let i = index">
                                <td>
                                        <div class="name-info d-flex align-items-center">
                                            <img src="assets/img/icon/guest.png" alt="folder">
                                            <h5 class="fw-semibold mb-0">{{bookingGuest.name}}</h5>
                                        </div>
                                    </td>
                                    <td class="gray-color">{{bookingGuest.email}}</td>
                                    <td class="gray-color">{{bookingGuest.description}}</td>
                                    <td>
                                        <div class="position-relative dot-btn p-0 bg-transparent border-none"
                                             (click)="trigger.openMenu()"
                                             #trigger="matMenuTrigger"
                                             [matMenuTriggerFor]="guestActionMenu">
                                            <i class="ri-more-fill"></i>
                                        </div>
                                        <mat-menu #guestActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                                <i class="ri-edit-2-line"></i> Edit
                                            </button>
                                            <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="removeAddedGuest(i)">
                                                <i class="ri-delete-bin-line"></i> Remove
                                            </button>
                                        </mat-menu>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div class="mt-5">
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Attach Files</ng-template>
                <mat-card class="mb-25 create-project-card phobos-card">
                    <mat-card-content>
                        <form>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label class="d-block mb-12 fw-semibold">Attached Files</label>
                                    <div class="custom-file-input">
                                        <input type="file" id="file" multiple (change)="onFileSelected($event)" style="display: none;">
                                        <label for="file">{{selectedFiles.length > 0 ? selectedFiles.length + ' file(s) selected' : 'No files chosen'}}</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
                <mat-card class="mb-25 phobos-card">
                    <mat-card-header>
                        <h5 class="mb-0">Added Files</h5>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-label *ngIf="selectedFiles.length < 1">No files added yet</mat-label>
                        <div class="recent-files-table table-responsive">
                            <table class="table" *ngIf="selectedFiles.length >= 1">
                                <thead >
                                <tr>
                                    <th class="fw-semibold main-color">File Name</th>
                                    <th class="fw-semibold main-color">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let file of selectedFiles; index as i">
                                    <td>
                                        <div class="name-info d-flex align-items-center">
                                            <img src="assets/img/icon/folder.png" alt="folder">
                                            <h5 class="fw-semibold mb-0">{{file.name}}</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <button mat-flat-button class="gray me-15" (click)="removeFile(i)">Remove</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div class="mt-5">
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>
                    <button mat-flat-button matStepperNext class="phobos">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Save Booking</ng-template>
                <div>
                    <button mat-flat-button matStepperPrevious class="gray me-15">Back</button>

                    <button type="button" mat-flat-button class="phobos" [matMenuTriggerFor]="saveActionMenu">
                        Save Booking...
                    </button>
                    <mat-menu #saveActionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="addBooking(BookingStatus.DRAFT)">
                            <i class="ri-draft-line"></i> Save Draft
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="addBooking(BookingStatus.OPTIONAL)">
                            <i class="ri-save-fill"></i> Save Optional
                        </button>
                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative" (click)="addBooking(BookingStatus.CONFIRMED)">
                            <i class="ri-mail-send-fill"></i> Save & Confirm
                        </button>
                    </mat-menu>
                </div>
            </mat-step>
        </mat-stepper>
    </mat-card-content>
</mat-card>
