import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Member} from "../../../common/model/model";

@Component({
    selector: 'add-performer-member',
    templateUrl: './add-performer-member-dialog.component.html',
    styleUrls: ['./add-performer-member-dialog.component.scss']
})
export class AddPerformerMemberDialogComponent implements OnInit {

    public addPerformerMemberFormGroup: FormGroup;
    public addPerformerMemberSubmitted = false;
    public logo: File[] = [];

    constructor(public dialogRef: MatDialogRef<AddPerformerMemberDialogComponent>,
                private addPerformerMemberFormBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.enableAddPerformerMemberForm();
    }

    get addPerformerMemberFormGroupControls() {
        return this.addPerformerMemberFormGroup.controls;
    }

    public close(){
        this.dialogRef.close(true);
    }

    public onSelect(event:any) {
        this.logo = [];

        if (event.addedFiles.length > 0) {
            this.logo.push(event.addedFiles[0]);
        }

        this.addPerformerMemberFormGroup.controls['logo'].setValue(this.logo[0]);
    }

    public enableAddPerformerMemberForm() {
        this.addPerformerMemberFormGroup = this.addPerformerMemberFormBuilder.group({
            name: [this.data?.name, Validators.required],
            role: [this.data?.role, Validators.required],
            phone: [this.data?.phone, Validators.required],
            email: [this.data?.email, Validators.required]
        });
    }

    public addPerformerMember() {
        this.addPerformerMemberSubmitted = true;
        if(this.addPerformerMemberFormGroup.invalid) {
        } else {
            let performerMember: Member = {
                name: this.addPerformerMemberFormGroup.controls['name'].value,
                role: this.addPerformerMemberFormGroup.controls['role'].value,
                phone: this.addPerformerMemberFormGroup.controls['phone'].value,
                email: this.addPerformerMemberFormGroup.controls['email'].value
            }

            this.dialogRef.close(performerMember);
        }
    }
}
