import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {CustomizerSettingsService} from "../../customizer-settings/customizer-settings.service";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {Agency, Performer} from "../../common/model/model";
import {PerformerService} from "../../services/performer.service";
import {AgencyService} from "../../services/agency.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {SearchService} from "../../services/search.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'performers-component',
    templateUrl: './performers.component.html',
    styleUrls: ['./performers.component.scss']
})
export class PerformersComponent implements OnInit, AfterViewInit, OnDestroy {

    public performers: Performer[] = [];
    public selectedAgency: Agency;
    dataSource = new MatTableDataSource<Performer>(this.performers);
    displayedColumns: string[] = ['name', 'price', 'cost', 'playtimes', 'actions'];

    private searchSubscription: Subscription

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public themeService: CustomizerSettingsService,
                private notificationService: NotificationService,
                private performersService: PerformerService,
                private agencyService: AgencyService,
                private searchService: SearchService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.agencyService.selectedAgency$.subscribe(agency => {
            if(agency) {
                this.selectedAgency = agency;
                this.getPerformers(agency);
            }
        });

        this.searchSubscription = this.searchService.searchObservable.subscribe(searchTerm => {
            this.applyFilter(searchTerm);
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy(): void {
        this.searchSubscription.unsubscribe();
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public addPerformer() {
        this.agencyService.selectedAgency$.subscribe(agency => {
           if(agency) {
               this.router.navigate(['/addperformer']);
           } else {
               this.notificationService.openFailureNotitication("Please add an Agency first.");
           }
        });
    }

    public openPerformerDetails(performer: Performer) {
        this.router.navigate(['/performer-details', performer.id]);
    }

    public getPerformers(agency: Agency) {
        this.performersService.getAllPerformers(agency).then(performers => {
            this.performers = performers;
            this.dataSource = new MatTableDataSource<Performer>(this.performers);
            if(this.paginator) {
                this.dataSource.paginator = this.paginator;
            }
        });
    }
}
