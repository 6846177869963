<mat-card
    class="mb-25 phobos-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <mat-card-header>
        <h5 class="mb-0">All Projects</h5>
        <button type="button" mat-button class="position-relative btn bg-transparent border-none" [matMenuTriggerFor]="monthMenu">
            This Week
        </button>
        <mat-menu #monthMenu="matMenu" class="monthMenu" xPosition="before">
            <button mat-menu-item type="button" class="bg-transparent border-none">This Month</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">This Year</button>
            <button mat-menu-item type="button" class="bg-transparent border-none">All Time</button>
        </mat-menu>
    </mat-card-header>
    <mat-card-content>
        <div class="all-projects-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="fw-semibold main-color">Project Name</th>
                        <th class="fw-semibold gray-color">Members</th>
                        <th class="fw-semibold gray-color">Budget</th>
                        <th class="fw-semibold gray-color">Status</th>
                        <th class="fw-semibold gray-color">Completion</th>
                        <th class="fw-semibold gray-color">Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/icon1.png" alt="icon1">
                                </div>
                                <h5 class="fw-semibold mb-0">Product UI/UX Design</h5>
                            </div>
                        </td>
                        <td class="members-list">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user16.jpg" class="rounded-circle" alt="user">
                        </td>
                        <td class="gray-color">$12,000</td>
                        <td class="gray-color">
                            <span class="badge fw-medium">Active</span>
                        </td>
                        <td class="gray-color progress-td">
                            50%
                            <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
                        </td>
                        <td class="gray-color">25 Feb 2023</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/icon2.png" alt="icon2">
                                </div>
                                <h5 class="fw-semibold mb-0">Public Beta Release</h5>
                            </div>
                        </td>
                        <td class="members-list">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        </td>
                        <td class="gray-color">$6,500</td>
                        <td class="gray-color">
                            <span class="badge fw-medium completed">Completed</span>
                        </td>
                        <td class="gray-color progress-td">
                            67%
                            <mat-progress-bar mode="determinate" value="67"></mat-progress-bar>
                        </td>
                        <td class="gray-color">14 Jan 2023</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/icon3.png" alt="icon3">
                                </div>
                                <h5 class="fw-semibold mb-0">Market and SEO</h5>
                            </div>
                        </td>
                        <td class="members-list">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                        </td>
                        <td class="gray-color">$12,000</td>
                        <td class="gray-color">
                            <span class="badge fw-medium completed">Completed</span>
                        </td>
                        <td class="gray-color progress-td">
                            77%
                            <mat-progress-bar mode="determinate" value="77"></mat-progress-bar>
                        </td>
                        <td class="gray-color">20 Feb 2023</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/icon4.png" alt="icon4">
                                </div>
                                <h5 class="fw-semibold mb-0">New Office Building</h5>
                            </div>
                        </td>
                        <td class="members-list">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user17.jpg" class="rounded-circle" alt="user">
                            <img src="assets/img/user/user16.jpg" class="rounded-circle" alt="user">
                        </td>
                        <td class="gray-color">$9,000</td>
                        <td class="gray-color">
                            <span class="badge fw-medium pending">Pending</span>
                        </td>
                        <td class="gray-color progress-td">
                            55%
                            <mat-progress-bar mode="determinate" value="55"></mat-progress-bar>
                        </td>
                        <td class="gray-color">20 Feb 2023</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="projects-name d-flex align-items-center">
                                <div class="img">
                                    <img src="assets/img/icon/icon5.png" alt="icon5">
                                </div>
                                <h5 class="fw-semibold mb-0">Product Development</h5>
                            </div>
                        </td>
                        <td class="members-list">
                            <img src="assets/img/user/user15.jpg" class="rounded-circle" alt="user">
                        </td>
                        <td class="gray-color">$14,500</td>
                        <td class="gray-color">
                            <span class="badge fw-medium">Active</span>
                        </td>
                        <td class="gray-color progress-td">
                            75%
                            <mat-progress-bar mode="determinate" value="75"></mat-progress-bar>
                        </td>
                        <td class="gray-color">20 Feb 2023</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>
