<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Performer</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/bookings">
                Performers
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Performer</li>
    </ol>
</div>

<ng-template #customLoadingTemplate>
    <div class="custom-class">
        <h3>Loading.......................</h3>
    </div>
</ng-template>

<div class="row">
    <ngx-loading
        [show]="isLoading"
        [config]="{ backdropBorderRadius: '4px' }"
        [template]="customLoadingTemplate"
    ></ngx-loading>
    <div class="col-lg-8 col-md-12">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Add Performer Details</h5>
            </mat-card-header>
            <mat-card-content *ngIf="selectedCurrency">
                <form [formGroup]="addPerformerFormGroup">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Name</label>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['name'].errors && addPerformerSubmitted}">
                            <i class="ri-edit-2-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFormGroup.get('name')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Name of the performer
                                </mat-label>
                                <input matInput formControlName="name" type="text" class="input-control" required/>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer E-Mail</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-edit-2-line"></i>
                                <mat-label>
                                    E-Mail of the performer
                                </mat-label>
                                <input matInput formControlName="email" type="text" class="input-control" required/>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Exclusivity</label>
                            <mat-slide-toggle [(ngModel)]="performerExclusivity" formControlName="exclusiveArtist">Exclusive Artist</mat-slide-toggle>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="!performerExclusivity" >
                            <label class="d-block mb-12 fw-semibold">External Agency</label>
                            <mat-form-field class="example-full-width" appearance="fill" [ngClass]="{'error-border': addPerformerFormGroup.hasError('externalAgencyRequired') && addPerformerSubmitted}">
                                <mat-label>External Agency</mat-label>
                                <i class="ri-home-office-line"></i>
                                <input matInput aria-label="State" [matAutocomplete]="auto" [formControl]="externalAgencyFormControl">
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onExternalAgencySelected($event)" [displayWith]="displayFn">
                                    <mat-option *ngFor="let externalAgency of filteredExternalAgencies | async" [value]="externalAgency">
                                        <span>{{externalAgency.name}} - {{externalAgency.city}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-3 col-md-3 col-sm-3 align-bottom" *ngIf="!performerExclusivity">
                            <button mat-stroked-button class="phobos full-height-button" (click)="openAddExternalAgencyDialog('300ms','100ms')">Add External Agency</button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="d-block mb-12 fw-semibold -color">Sale Price ({{selectedCurrency.currency_code}})</label>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['price'].errors && addPerformerSubmitted}">
                                <i class="ri-price-tag-3-line"></i>
                                <mat-label [ngStyle]="{'color': (addPerformerFormGroup.get('price')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Enter sale price</mat-label>
                                <input matInput formControlName="price" class="input-control" type="number" required/>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="d-block mb-12 fw-semibold">Purchase Price ({{selectedCurrency.currency_code}})</label>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['cost'].errors && addPerformerSubmitted}">
                                <i class="ri-price-tag-3-line"></i>
                                <mat-label [ngStyle]="{'color': (addPerformerFormGroup.get('cost')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Enter cost price</mat-label>
                                <input matInput formControlName="cost" class="input-control" type="number" required/>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Play Times</label>
                            <mat-form-field class="example-chip-list" appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['playtimes'].errors && addPerformerSubmitted}">
                                <i class="ri-price-tag-3-line"></i>
                                <mat-label
                                    [ngStyle]="{'color': (addPerformerFormGroup.get('playtimes')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Performer Play times
                                </mat-label>
                                <mat-chip-grid #chipGrid aria-label="Enter Play Times" formControlName="playtimes">
                                    <mat-chip-row *ngFor="let playtime of playTimes"
                                                  (removed)="removePlayTime(playtime)"
                                                  [editable]="true"
                                                  (edited)="editPlaytime(playtime, $event)"
                                                  [aria-description]="'press enter to edit ' + playtime"
                                    >
                                        {{playtime}}
                                        <button matChipRemove [attr.aria-label]="'remove ' + playtime">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip-row>
                                    <input matInput placeholder="New playtime..."
                                           [matChipInputFor]="chipGrid"
                                           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                           [matChipInputAddOnBlur]="addOnBlur"
                                           (matChipInputTokenEnd)="addPlayTime($event)"
                                    />
                                </mat-chip-grid>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Rider</label>
                            <input type="file" formControlName="performerrider" (change)="onRiderFileChange($event)"
                                   [ngClass]="{ 'is-invalid': addPerformerSubmitted && addPerformerFormGroupControls['performerrider'].errors}"
                                   [ngStyle]="{'border-color': addPerformerSubmitted && addPerformerFormGroupControls['performerrider'].errors?.['required'] ? 'red' : 'grey'}">
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Presskit</label>
                            <input type="file" formControlName="presskit" (change)="onPresskitFileChange($event)"
                                   [ngClass]="{ 'is-invalid': addPerformerSubmitted && addPerformerFormGroupControls['presskit'].errors}"
                                   [ngStyle]="{'border-color': addPerformerSubmitted && addPerformerFormGroupControls['presskit'].errors?.['required'] ? 'red' : 'grey'}">
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">VAR</label>
                            <input type="file" formControlName="var" (change)="onVarFileChange($event)"
                                   [ngClass]="{ 'is-invalid': addPerformerSubmitted && addPerformerFormGroupControls['var'].errors}"
                                   [ngStyle]="{'border-color': addPerformerSubmitted && addPerformerFormGroupControls['var'].errors?.['required'] ? 'red' : 'grey'}">
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Picture</label>
                            <mat-card class="mb-25 phobos-card">

                                <mat-card-content>
                                    <ngx-dropzone (change)="onSelect($event)" class="centered-dropzone">
                                        <ngx-dropzone-label class="fw-semibold" *ngIf="!picture.length && !existingPicture">Drop files here or click to upload.</ngx-dropzone-label>
                                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of picture" [file]="f" [removable]="true" (removed)="onRemove()">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                        </ngx-dropzone-image-preview>
                                        <ngx-dropzone-preview *ngIf="existingPicture" [removable]="true" (removed)="onRemove()">
                                            <ngx-dropzone-label>Existing Image</ngx-dropzone-label>
                                            <img [src]="existingPicture" style="max-width: 100%; max-height: 100%;" />
                                        </ngx-dropzone-preview>
                                    </ngx-dropzone>
                                </mat-card-content>
                                <div class="align-items-center" *ngIf="addPerformerSubmitted && addPerformerFormGroupControls['picture'].errors" style="color:red;font-size:small">
                                    <div *ngIf="addPerformerFormGroupControls['picture'].errors?.['required']">Please add your product picture</div>
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <label class="d-block mb-12 fw-semibold">Performer Description</label>
                            <mat-form-field appearance="fill" [ngClass]="{'error-border': addPerformerFormGroupControls['description'].errors && addPerformerSubmitted}">
                                <i class="ri-information-line"></i>
                                <mat-label
                                        [ngStyle]="{'color': (addPerformerFormGroup.get('description')?.hasError('required') && addPerformerSubmitted) ? '#d73a49' : 'inherit'}">
                                    Description and additional information of the performer...</mat-label>
                                <textarea matInput rows="4" formControlName="description" class="input-control" type="text" required>
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    <br>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-4 col-md-12">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Category</h5>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="addPerformerFormGroup">
                    <mat-form-field appearance="fill" [ngClass]="{'error-border': categoriesFormControl.hasError('required') && categoriesFormControl.touched}">
                        <i class="ri-price-tag-3-line"></i>
                        <mat-label
                                [ngStyle]="{'color': (categoriesFormControl.hasError('required') && categoriesFormControl.touched) ? '#d73a49' : 'inherit'}">
                            Categories
                        </mat-label>
                        <mat-select formControlName="categories" multiple
                                    [ngClass]="{ 'is-invalid': addPerformerSubmitted && addPerformerFormGroupControls['categories'].errors}"
                                    [ngStyle]="{'border-color': addPerformerSubmitted && addPerformerFormGroupControls['categories'].errors?.['required'] ? 'red' : 'grey'}">
                            <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="categoriesFormControl.hasError('minCategoriesRequired')">
                            At least one category is required.
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <button mat-stroked-button (click)="openAddCategoryDialog('300ms','100ms')">Add New Category</button>
        </mat-card>
        <mat-card class="mb-25 phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Members</h5>
            </mat-card-header>
            <mat-card-content>
                <mat-label *ngIf="members.length < 1">No members added yet</mat-label>
                <mat-list>
                    <mat-list-item *ngFor="let member of members">
                        <div class="list-item">
                            <mat-icon matListItemIcon>person</mat-icon>
                            <div class="item-details">
                                <div matListItemTitle class="fw-semibold">{{member.name}}</div>
                                <div matListItemLine>{{member.role}}</div>
                            </div>
                            <div></div>
                            <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-edit-2-line"></i> Edit
                                </button>
                                <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
            <br>
            <button mat-stroked-button (click)="openAddPerformerMemberDialog('300ms','100ms')">Add New Member</button>
        </mat-card>
    </div>

    <div class="col-lg-8 col-md-12">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-header class="border-bottom">
                <h5 class="mb-0">Add Products</h5>
                <button mat-raised-button color="primary" (click)="openAddProductDialog('300ms','100ms')">
                    <i class="ri-add-fill"></i> Add Product
                </button>
            </mat-card-header>
            <mat-card-content>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <mat-label *ngIf="performerProducts.length < 1">No products added yet</mat-label>
                    <div class="recent-files-table table-responsive">
                        <table class="table" *ngIf="performerProducts.length >= 1">
                            <thead >
                            <tr>
                                <th class="fw-semibold main-color">Name</th>
                                <th class="fw-semibold main-color">Price</th>
                                <th class="fw-semibold main-color">Cost</th>
                                <th class="fw-semibold main-color">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let performerProduct of performerProducts">
                                <td>
                                    <div class="name-info d-flex align-items-center">
                                        <img src="assets/img/icon/product.png" alt="folder">
                                        <h5 class="fw-semibold mb-0">{{performerProduct.name}}</h5>
                                    </div>
                                </td>
                                <td class="gray-color">{{performerProduct.price}} {{performerProduct?.currency_code}}</td>
                                <td class="gray-color">{{performerProduct.cost}} {{performerProduct?.currency_code}}</td>
                                <td>
                                    <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                                        <i class="ri-more-fill"></i>
                                    </button>
                                    <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-edit-2-line"></i> Edit
                                        </button>
                                        <button mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                            <i class="ri-delete-bin-line"></i> Remove
                                        </button>
                                    </mat-menu>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-lg-8 col-md-12">
        <mat-card class="mb-25 create-project-card phobos-card">
            <mat-card-content>
                <div class="btn-box">
                    <button mat-flat-button class="phobos" style="margin-right: 5px" (click)="addPerformer()">
                        Add Performer
                    </button>
                    <button mat-flat-button color="warn" (click)="cancel()">
                        Cancel
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>


