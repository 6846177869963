import {Injectable} from "@angular/core";
import {SupabaseService} from "./supabase.service";
import {Currency} from "../common/model/model";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    private selectedCurrencySubject = new BehaviorSubject<any>(null);
    selectedCurrency$ = this.selectedCurrencySubject.asObservable();

    constructor(private supabaseService: SupabaseService) {
    }

    public async getCurrencies(): Promise<Currency[]> {
        const {data, error} = await this.supabaseService.supabaseClient
            .from('currencies')
            .select('*');

        if(error) {
            throw new Error("Failed to get currencies, because: " + error.message);
        }

        return data as Currency[];
    }

    public setCurrentCurrency(currency: Currency): void {
        this.selectedCurrencySubject.next(currency);
    }

    public getSelectedCurrency(): Currency {
        return this.selectedCurrencySubject.value;
    }
}
